<template>
    <ul v-if="isShow" class="settings_wrap flex_column gap_20">
        <!-- 기본설정 -->
        <div>
            <div class="large_txt mgb_5" style="font-size: 18px;">기본설정</div>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    전능 연동
                </div>
                <div class="settings_list_option flex_aic">
                    <label class="flex_aic">
                        <input type="radio" name="전능 연동" :value="true" v-model="isRemoved">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="전능 연동" :value="false" v-model="isRemoved">사용
                    </label>
                </div>
            </li>
            
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    고객번호
                </div>
                <div class="settings_list_option flex_column">
                    <input type="text" class="w_full" name="" id="" placeholder="고객번호 입력" :disabled="isRemoved" v-model="sdHospitalID">
                    <small class="red_txt" v-if="!isValid_sdHospitalID">내용을 입력해 주세요.</small>
                </div>
            </li>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    연동 토큰
                </div>
                <div class="settings_list_option flex_column">
                    <input type="text" class="w_full" name="" id="" placeholder="토큰 값 입력" :disabled="isRemoved" v-model="clientToken">
                    <small class="red_txt" v-if="!isValid_clientToken">내용을 입력해 주세요.</small>
                </div>
            </li>
            <li class="settings_list flex_aic_fle">
                <button class="btn_l btn_blue" @click="action_SaveToken" :disabled="!auth.v2HospitalIntegration_w">저장</button>
            </li>
        </div>
        
        <!-- 기능설정 -->
        <div class="pdt_15" style="border-top:1px solid #b2b2b2" v-if="isShowFunctionSetting">
            <div class="large_txt mgb_5" style="font-size: 18px;">기능설정</div>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    전능 문자 발송
                </div>
                <div class="settings_list_option flex_aic">
                    <label class="flex_aic">
                        <input type="radio" name="전능 문자 발송" :value="false" v-model="usingRegist">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="전능 문자 발송" :value="true" v-model="usingRegist">사용
                    </label>
                </div>
            </li>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    예약시트 노출
                </div>
                <div class="settings_list_option flex_aic">
                    <label class="flex_aic">
                        <input type="radio" name="예약시트 노출" :value="false" v-model="usingReservation">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="예약시트 노출" :value="true" v-model="usingReservation">사용
                    </label>
                </div>
            </li>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    예약시트 기본값
                </div>
                <div class="settings_list_option flex_aic">
                    <SmartDoctorIntegrationStatusList
                        :list="statusList"
                        v-model="defaultLabelObject"
                    />
                </div>
            </li>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    예약취소 필터값
                </div>
                <div class="settings_list_option flex_aic gap_10">
                    <SmartDoctorIntegrationStatusMultipleList
                        :list="statusList"
                        v-model="defaultCancelArray"
                    />
                    <div class="v2_custom_select label_list_wrap"
                        v-for="(item, index) in defaultCancelArray"
                        :key=index
                    >
                        <div class="flex_aic_spb gap_5">
                            <div class="flex_aic gap_5">
                                <span class="color_circle" :style="'background-color: ' + item.color + ';'"></span>
                                <span class="txt">{{item.name}}</span>
                            </div>
                            <span class="chevron" @click="action_RemoveDefaultCancelItem(index)"><v-icon>mdi-close</v-icon></span>
                        </div>
                    </div>
                </div>
            </li>
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    대시보드 노출
                </div>
                <div class="settings_list_option flex_aic">
                    <label class="flex_aic">
                        <input type="radio" name="대시보드 노출" checked disabled>사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="대시보드 노출" disabled>사용
                    </label>
                </div>
            </li>
            
            <li class="settings_list flex_aic_fle">
                <button class="btn_l btn_blue" @click="action_SaveFunction" :disabled="!auth.v2HospitalIntegration_w">저장</button>
            </li>
        </div>
    </ul>
</template>


<script>
import { mapActions } from 'vuex'
import SmartDoctorIntegrationStatusList from './SmartDoctorIntegrationStatusList.vue'
import SmartDoctorIntegrationStatusMultipleList from './SmartDoctorIntegrationStatusMultipleList.vue'

export default {
    components: {
        SmartDoctorIntegrationStatusList,
        SmartDoctorIntegrationStatusMultipleList,
    },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            integrationSetting: undefined,
            hospitalMap: undefined,
            
            isRemoved: true,
            clientToken: undefined,
            integrationType: 'ALM',
            usingRegist: false,
            usingReservation: false,
            defaultLabelID: undefined,
            defaultCancelID: [],

            defaultLabelObject: undefined,
            defaultCancelArray: [],
            
            sdHospitalID: undefined,

            statusList: [],
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (!newValue) { return }
                this.reloadData()
            }
        },
        isShowFunctionSetting: {
            immediate: true,
            handler: function(newValue) {
                if (newValue != true) { return }
                this.loadReservationStatus()
            }
        }
    },
    computed: {
        hospitalID: function() {
            return this.hospital?._id ?? undefined
        },
        isValid_sdHospitalID: function() {
            if (this.isRemoved == false) {
                return this.sdHospitalID != undefined && this.sdHospitalID != ''
            }
            return true
        },
        isValid_clientToken: function() {
            if (this.isRemoved == false) {
                return this.clientToken != undefined && this.clientToken != ''
            }
            return true
        },
        hasToken: function() {
            if (this.integrationSetting?.clientToken != undefined && this.integrationSetting?.clientToken != '') { return true }
            return false
        },

        isShowFunctionSetting: function() {
            if (this.isRemoved == true) { return false }
            if (this.integrationSetting?.isRemoved == true) { return false }
            if (this.integrationSetting?.clientToken == undefined || this.integrationSetting?.clientToken == '') { return false }
            return true
        },
    },
    methods: {
        ...mapActions(['setAlFeatureConfigPopupState']),
        // MARK: - Network
        async reloadData() {
            await this.loadIntegrationSetting()
            await this.loadHospitalMap()
        },
        async loadIntegrationSetting() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.IntegrationSetting.findOne({ hospitalID: this.hospitalID })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.integrationSetting = result.data.data
            this.isRemoved = this.integrationSetting?.isRemoved ?? true
            this.clientToken = this.integrationSetting?.clientToken
            this.integrationType = this.integrationSetting?.integrationType
            this.usingRegist = this.integrationSetting?.usingRegist ?? false
            this.usingReservation = this.integrationSetting?.usingReservation ?? false
            if (this.integrationSetting?.customObject?.defaultLabelID != undefined && this.integrationSetting?.customObject?.defaultLabelID.length > 0) {
                this.defaultLabelID = this.integrationSetting?.customObject?.defaultLabelID[0]
            }
            this.defaultCancelID = this.integrationSetting?.customObject?.defaultCancelID ?? []

            if (this.isShowFunctionSetting) {
                await this.setAlFeatureConfigPopupState({
                    isShow: true,
                    token: this.clientToken,
                    selectedHospital: this.hospital
                })
            }
            else {
                this.setAlFeatureConfigPopupState({
                    isShow: false,
                    token: undefined,
                    selectedHospital: undefined
                })
            }
        },
        async loadHospitalMap() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API.AlService.getHospital({hospitalID: this.hospitalID})
            
            this.hospitalMap = result?.data
            this.sdHospitalID = this.hospitalMap?.sdHospitalID
        },
        async loadReservationStatus() {
            if (this.hasToken == false) { return }
            let result = await this.API.AlService.getAlLabels()
            this.statusList = result.data?.labels ?? []

            let label = this.statusList.find( (l) => l.id == this.defaultLabelID )
            this.defaultLabelObject = label

            this.defaultCancelArray = []
            for (let i=0 ; i<this.defaultCancelID.length ; i++) {
                let id = this.defaultCancelID[i]
                let label = this.statusList.find( (l) => l.id == id )
                this.defaultCancelArray.push(label)
            }
        },

        async upsertIntegrationSetting(model) {
            let result = await this.API_V3.IntegrationSetting.upsert(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                console.log(result)
                return
            }
        },
        async createHospitalMap(model) {
            await this.API.AlService.createHospitalID(model)
        },
        async updateHospitalMap(model) {
            await this.API.AlService.updateHospitalID(model)
        },

        // MARK: - Actions
        async action_SaveToken() {
            let integrationSettingModel = {
                _id: this.integrationSetting?._id,
                hospitalID: this.hospitalID,
                clientToken: this.clientToken,
                integrationType: this.integrationType,
                isRemoved: this.isRemoved,
                usingDashboard: false,
            }
            if (this.integrationSetting?.integrationType == undefined) { integrationSettingModel.integrationType = 'ALM' }
            await this.upsertIntegrationSetting(integrationSettingModel)

            let hospitalMapModel = {
                _id: this.hospitalMap?._id,
                hospitalID: this.hospitalID,
                sdHospitalID: this.sdHospitalID,
                isRemoved: this.isRemoved,
            }
            if (hospitalMapModel._id == undefined) {
                await this.createHospitalMap(hospitalMapModel)
            }
            else {
                await this.updateHospitalMap(hospitalMapModel)
            }

            this.reloadData()
        },
        async action_SaveFunction() {
            let integrationSettingModel = {
                _id: this.integrationSetting?._id,
                hospitalID: this.hospitalID,
                usingRegist: this.usingRegist,
                usingReservation: this.usingReservation,
                customObject: {
                    defaultLabelID: [this.defaultLabelObject.id],
                    defaultCancelID: this.defaultCancelArray.map( (c) => c.id )
                },
            }
            await this.upsertIntegrationSetting(integrationSettingModel)
            this.reloadData()
        },
        action_RemoveDefaultCancelItem(index) {
            this.defaultCancelArray.splice(index, 1)
        }
    },
}
</script>