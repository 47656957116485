<template>
    <div v-show="false">
        <input ref="oneimageinput" type="file" @change="handleFileChange" :accept="computedAccept" />
        <input ref="imageinput" type="file" @change="handleFileChange" multiple :accept="computedAccept" />
        <input ref="fileinput" type="file" @change="handleFileChange" multiple :accept="computedAccept" />
    </div>
</template>

<script>
export default {
    props: {
        value: [],
        isMultiple: Boolean,
        isOpen: Boolean,
        onlyImage: Boolean,
        acceptExt: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            imageExt: 'image/*',
            allExt: '*'
        }
    },
    watch: {
        isOpen: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue == true) {
                    let input
                    if (this.onlyImage == true) {
                        input = this.isMultiple
                            ? this.$refs['imageinput']
                            : this.$refs['oneimageinput'];
                    } else {
                        input = this.$refs['fileinput'];
                    }
                    input.value = ''
                    input.click()
                    this.$emit('input', [])
                }
            }
        },
    },
    computed: {
        computedAccept() {
            if (this.acceptExt) {
                return this.acceptExt
            }
            if (this.onlyImage) {
                return this.imageExt
            }
            return this.allExt
        }
    },
    methods: {
        handleFileChange(e) {
            let files = e.currentTarget.files
            let fileArray = []
            for (let i = 0; i < files.length; i++) {
                fileArray.push(files[i])
            }
            this.$forceUpdate()

            this.$emit('input', fileArray)
        }
    }
}
</script>

<style scoped></style>
