<template>
    <div class="cc">
        <IntegrationSubNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>


<script>
import IntegrationSubNavigation from './IntegrationSubNavigation.vue'
export default {
    components: { IntegrationSubNavigation }
}
</script>