import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Global Store
import hospitalpopup from './popup/hospitalpopup'
import hospitalsuspendpopup from './popup/hospitalsuspendpopup'
import hospitaltreatmentcategorypopup from './popup/hospitaltreatmentcategorypopup'
import accountsettingpopup from './popup/accountsettingpopup'
import accountqrcodepopup from './popup/accountqrcodepopup'
import anurseaccountsettingpopup from './popup/anurseaccountsettingpopup'
import categorysettingchildtypenamepopup from './popup/categorysettingchildtypenamepopup'
import categorysettingparenttypenamepopup from './popup/categorysettingparenttypenamepopup'
import chattingalertpopup from './popup/chattingalertpopup'
import alfeatureconfigpopup from './popup/alfeatureconfigpopup'
import alhospitalinfopopup from './popup/alhospitalinfopopup'
import almessagelogviewer from './popup/almessagelogviewer'
import answeringchatpopup from './popup/answeringchatpopup'

// API
import user from './api/user'

// Authorizations
import auth from './authorization/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        hospitalpopup: hospitalpopup,
        hospitalsuspendpopup: hospitalsuspendpopup,
        hospitaltreatmentcategorypopup: hospitaltreatmentcategorypopup,
        accountsettingpopup: accountsettingpopup,
        accountqrcodepopup: accountqrcodepopup,
        anurseaccountsettingpopup: anurseaccountsettingpopup,
        categorysettingchildtypenamepopup: categorysettingchildtypenamepopup,
        categorysettingparenttypenamepopup: categorysettingparenttypenamepopup,
        chattingalertpopup: chattingalertpopup,
        alfeatureconfigpopup: alfeatureconfigpopup,
        alhospitalinfopopup: alhospitalinfopopup,
        almessagelogviewer: almessagelogviewer,
        answeringchatpopup: answeringchatpopup,
        // APIs
        user: user,
        auth
    },
    plugins: [
        createPersistedState({
            paths: ['user', 'auth']
        })
    ],
    strict: process.env.NODE_ENV !== 'production'
})
