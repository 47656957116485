<template>
    <div class="msg_item" @click="action_SelectMessage">
        <span class="selected" v-if="isSelected"> </span>
        <b class="date">{{ daysAfter }}일차</b>
        <span class="ellipsis">{{ message?.name == '' ? '-' : message.name}}</span>
        <!-- 카톡 승인된 경우 표시 -->
        <img src="@/assets/img/badge_k.png" alt="" v-if="isKakaoTemplate">
        <button class="btn_delete mgl_auto" @click.stop="action_RemoveMessage(index)"><v-icon>mdi-trash-can</v-icon>삭제</button>
    </div>
</template>


<script>
export default {
    props: {
        message: Object,
        index: Number,
        selectedMessage: Object
    },
    computed: {
        isSelected: function() { return this.selectedMessage == this.message },
        daysAfter: function() { return this.message.daysAfter },
        isKakaoTemplate: function() { return this.message?.kakaoTemplateHistoryId != undefined },
    },
    methods: {
        action_SelectMessage() {
            this.$emit('setSelectMessage', this.index)
        },
        action_RemoveMessage() {
            this.$emit('removeMessage', this.index)
        }
    }
}
</script>