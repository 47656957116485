<template>
    <div class="gnb">
        <div class="logo">
            <img :src="require(`@/assets/img/BaseLogo.png`)" alt="afterdoc_logo" />
        </div>
        <a
            v-if="auth.hospitalManagement_v"
            @click="action_HospitalManagement"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.name == 'HospitalSetting' || $router.currentRoute.name == 'AccountSetting'
            }"
        >V1 병원 관리</a>
        <a
            v-if="auth.v2HospitalManagement_v"
            @click="action_V2HospitalManagement"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.path.includes('v2hospitalsetting')
            }"
        >V2 병원 관리</a>
        <a
            v-if="auth"
            @click="action_MessageManagement"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.path.includes('messagemanagement') }"
        >번호/메시지 관리</a>
        <a
            v-if="auth.serviceManager_v"
            @click="action_ANurseManagement"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.name == 'ANurseAccountSetting' }"
        >서비스 매니저 관리</a>
        <a
            v-if="auth.videoContent_v"
            @click="action_VideoContentManagement"
            class="gnb_menu"
            :class="{
                on:
                    $router.currentRoute.name == 'VideoUpload' ||
                    $router.currentRoute.name == 'VideoContentSetting' ||
                    $router.currentRoute.name == 'CategorySetting' ||
                    $router.currentRoute.name == 'ApplicationSetting' ||
                    $router.currentRoute.name == 'DefaultSetting'
            }"
        >홈케어콘텐츠 관리</a>
        <!-- <a
            @click="action_HealthcareNewsManagement"
            class="gnb_menu"
            :class="{
                on: $router.currentRoute.name == 'NewsSetting' || $router.currentRoute.name == 'PushHistory'
            }"
            >건강관리뉴스 관리</a
        > -->
        <a
            v-if="auth.onboarding_v"
            @click="action_OnboardingSetting"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.path.includes('/hospitaltype') }"
        >온보딩 설정</a>
        <a
            v-if="auth.app_v"
            @click="action_AfterdocAppSetting"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.name == 'AppVersionSetting' }"
        >앱 관리</a>
        <a
            v-if="auth.electron_v"
            @click="action_AfterdocElectronSetting"
            class="gnb_menu"
            :class="{
                on: 
                    $router.currentRoute.name.includes('NotificationSoundSetting') ||
                    $router.currentRoute.name.includes('NationalSMSSetting') ||
                    $router.currentRoute.name.includes('ServiceAlrimTalkSetting')
            }"
            >일렉트론 설정</a
        >
        <a
            v-if="auth.integration_v"
            @click="action_ALManagement"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.path.includes('syncmanagement') }"
        >연동 관리</a>
        <a 
            v-if="auth.monitoring_v"
            @click="action_Monitoring" 
            class="gnb_menu" 
            :class="{ 
                on: 
                    $router.currentRoute.name == 'ALManagementMonitoring'  ||
                    $router.currentRoute.name == 'AutoReservedSMS' 
            }"
        >모니터링</a>
        <a
            v-if="auth.eventManagement_v"
            @click="action_EventManagement"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.name == 'EventState' ||  $router.currentRoute.name == 'EventPushAlarmState'}"
        >이벤트 관리</a>
        <a
            v-if="auth.reservedChat_v"
            @click="action_CAReservedChatManagement"
            class="gnb_menu"
            :class="{ on: $router.currentRoute.name == 'CAReservedChat' }"
        >예약채팅 관리</a>
        <a
            v-if="auth.treatmentCategory_v || auth.algorithm_v || auth.alrimtalk_v"
            @click="action_BasicTemplate" class="gnb_menu" 
            :class="{ on: $router.currentRoute.path.includes('basictemplate') }"
        >기본 템플릿</a>
        <a
            v-if="auth.adminAccount_v != false"
            @click="action_AdminAccount" class="gnb_menu" 
            :class="{ on: $router.currentRoute.path.includes('admin') }"
        >어드민 계정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_HospitalManagement() {
            this.$router.push({ name: 'HospitalSetting' }).catch(() => {})
        },
        action_V2HospitalManagement() {
            this.$router.push({ name: 'V2HospitalList' }).catch(() => {})
        },
        action_MessageManagement() {
            this.$router.push({ name: 'MessageManagementHospitalList' }).catch(() => {})
        },
        action_ANurseManagement() {
            this.$router.push({ name: 'ANurseAccountSetting' }).catch(() => {})
        },
        action_VideoContentManagement() {
            this.$router.push({ name: 'VideoUpload' }).catch(() => {})
        },
        // action_HealthcareNewsManagement() {
        //     this.$router.push({ name: 'NewsSetting' }).catch(() => {})
        // },
        action_OnboardingSetting() {
            this.$router.push({ name: 'HospitalTypeSetting' }).catch(() => {})
        },
        action_AfterdocAppSetting() {
            this.$router.push({ name: 'AppVersionSetting' }).catch(() => {})
        },
        action_AfterdocElectronSetting() {
            this.$router.push({ name: 'NotificationSoundSetting' }).catch(() => {})
        },
        action_ALManagement() {
            this.$router.push({ name: 'ALManagement' }).catch(() => {})
        },
        action_Monitoring() {
            this.$router.push({ name: 'ALManagementMonitoring' }).catch(() => {})
        },
        action_EventManagement() {
            this.$router.push({ name: 'EventState'}).catch(() => {})
        },
        action_CAReservedChatManagement() {
            this.$router.push({ name: 'CAReservedChat'}).catch(() => {})
        },
        action_BasicTemplate() {
            this.$router.push({ name: 'TreatmentTagManual'}).catch(() => {})
        },
        action_AdminAccount() {
            this.$router.push({ name: 'AccountManagement'}).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
