<template>
    <div class="v2_custom_select" v-click-outside="closeList">
        <div class="flex_aic_spb" @click="action_ClickTitle">
            <div class="flex_aic gap_5">
                <span class="color_circle" :style="styleString"></span>
                <span class="txt">{{ selectedItemTitle }}</span>
            </div>
            <span class=""><v-icon>mdi-chevron-down</v-icon></span>
        </div>
        <div class="list" v-show="isShowList">
            <SmartDoctorIntegrationStatusListItem
                v-for="(item, index) in printList"
                :key="index"
                :item="item"
                :selectedValue="value"
                @selectItem="selectItem"
            />
        </div>  
    </div>
</template>


<script>
import SmartDoctorIntegrationStatusListItem from './SmartDoctorIntegrationStatusListItem.vue'
export default {
    components: { SmartDoctorIntegrationStatusListItem },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        value: Array
    },
    data() {
        return {
            isShowList: false,
            printList: [],
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                if (this.list == undefined || newValue == undefined) { return }
                this.printList = this.list.filter(a => !newValue.some(b => b.id === a.id))
            }
        },
        list: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == undefined || this.value == undefined) { return }
                this.printList = newValue.filter(a => !this.value.some(b => b.id === a.id))
            }
        },
    },
    computed: {
        selectedItemTitle: function() {
            return '--선택--'
        },
        selectedItemColor: function() {
            return '#FFFFFF'
        },
        styleString: function() {
            return 'background-color: ' + this.selectedItemColor + ';'
        }
    },
    methods: {
        closeList() {
            this.isShowList = false
        },

        // MARK: - Actions
        action_ClickTitle() {
            this.isShowList = !this.isShowList
        },

        // MARK: - Emit
        selectItem(item) {
            let temp = JSON.parse(JSON.stringify(this.value))
            let index = temp.findIndex(t => t.id == item.id)
            if (index >= 0) { temp.splice(index, 1) }
            else { temp.push(item) }
            temp.sort( (l, r) => { return l.id - r.id })
            this.$emit('input', temp)
            this.closeList()
        }
    },
}
</script>