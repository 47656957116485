<template>
    <tr>
        <td>{{ userName }}</td>
        <td>{{ phone }}</td>
        <td>
            <p>{{ jobType }}</p>
            <small class="lgray_txt">({{ id }})</small>
        </td>
        <td>
            {{ authorizationTypeName }}
        </td>
        <td class="txt_center">
            <label class="flex_aic checkbox_wrap">
                <input type="checkbox" name="지정" :id="doctor?._id ?? '-'" v-model="isCounselor" @click="action_UpdateDefaultCounselor" :disabled="!auth.v2HospitalManagement_w">
                <span>지정</span>
            </label>
        </td>
        <td>
            <small class="img_preview_wrap lgray_txt" style="overflow: initial;">
                <span>File ID:</span>
                {{ fileID }}
                <div class="img_preview">
                    <img :src="fileURL" alt="">
                </div>
            </small>
        </td>
        <td>
            <div class="flex_aic">
                <button class="btn_s btn_blue_line w_full" @click="action_Modify" :disabled="!auth.v2HospitalManagement_w">수정</button>
                <button class="btn_s btn_gray w_full" @click="action_Remove" :disabled="!auth.v2HospitalManagement_w">삭제</button>
            </div>
        </td>
    </tr>
</template>


<script>
export default {
    props: {
        hospitalID: String,
        doctor: Object,
        serviceSetting: Object
    },
    data() {
        return {
            isCounselor: false,
        }
    },
    watch: {
        doctor: {
            immediate: true,
            deep: true,
            handler: function() {
                this.updateIsCounselor()
            }
        },
        serviceSetting: {
            immediate: true,
            deep: true,
            handler: function() {
                this.updateIsCounselor()
            }
        },
    },
    computed: {
        userName: function() {
            return this.doctor?.realName ?? '-'
        },
        phone: function() {
            let phone = this.doctor?.phone ?? '-'
            if (phone == '') { return '-' }
            return phone
        },
        jobType: function() {
            return this.doctor?.jobType ?? '-'
        },
        id: function() {
            return this.doctor?.id ?? '-'
        },
        authorizationTypeName: function() {
            return this.doctor?.authorizationTypeID?.name
        },
        photo: function() {
            return this.doctor?.photo
        },
        fileID: function() {
            return this.photo?._id ?? '-'
        },
        fileURL: function() {
            let paths = this.photo?.paths ?? []
            if (paths.length == 0) { return '-' }
            return paths[0]
        }
    },
    methods: {
        updateIsCounselor() {
            if (this.serviceSetting == undefined) { return }
            if (this.doctor == undefined) { return }
            let defaultCounselorIds = this.serviceSetting.defaultCounselorIds
            if (defaultCounselorIds.find( id => id == this.doctor._id)) { this.isCounselor = true }
            else { this.isCounselor = false }
        },

        // MARK: - Network
        async updateDefaultCounselor(model) {
            let result = await this.API_V3.ServiceSetting.updateDefaultCounselor(model)
            if (result.data.code != 0) {
                alert(result.data.message)
            }
            this.$emit('loadDatas')
        },

        // MARK: - Actions
        action_Modify() {
            this.$emit('showModifyPopup', this.doctor)
        },
        async action_Remove() {
            let model = {
                _id: this.doctor?._id,
                hospitalID: this.hospitalID
            }
            let result = await this.API_V3.Hospital.removeAccount(model)
            if (result.data.code != 0) {
                alert(result.data.message)
            }
            this.$emit('loadDatas')
        },
        action_UpdateDefaultCounselor() {
            if (this.serviceSetting == undefined || this.doctor == undefined) { return }
            let newArray = this.serviceSetting.defaultCounselorIds
            if (this.isCounselor) {
                let index = newArray.findIndex(id => id == this.doctor._id)
                if (index > -1) {
                    newArray.splice(index, 1)
                }
            }
            else {
                newArray.push(this.doctor._id)
            }
            let model = {
                _id: this.serviceSetting._id,
                defaultCounselorIds: newArray
            }
            this.updateDefaultCounselor(model)
        },
    },
}
</script>