<template>
    <!-- 기본 템플릿 불러오기 버튼 클릭 시 나타나는 팝업 -->
    <div class="basic_template_pop_wrap">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="pop_title">
            <span class="title">기본 템플릿 불러오기</span>
            <a class="mgl_auto" @click="action_ClosePopup">
                <v-icon :size="24">mdi-close</v-icon>
            </a>
        </div>
        <div class="pop_cont pd_0" style="overflow: hidden;">
            <div class="h_scroll">
                <div class="table_title_btn mgb_10">
                    <input type="text" class="input_s w_full" v-model="keyword" placeholder="템플릿명 검색"
                           @keyup.enter="action_Search" />
                    <button class="btn_s btn_blue_line mgl_5" @click="action_Search">검색</button>
                </div>
                <div class="table_header_fix">
                    <table class="table_default">
                        <thead>
                        <tr>
                            <th width="260" class="txt_left">기본 템플릿명</th>
                            <th width="480" class="txt_left">메모</th>
                            <th width="80" class="txt_left">메세지 개수</th>
                            <th width="140" class="txt_left">수정일시</th>
                            <th width="">선택</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!-- 데이터 없음 -->
                        <tr v-if="list.length === 0">
                            <td colspan="5">{{ noDataMessage }}</td>
                        </tr>
                        <!-- 데이터 있음 -->
                        <BasicTemplateListItem
                            v-for="(algorithm,index) in list" :key="index"
                            :algorithm="algorithm"
                            @setSelectedAlgorithm="setSelectedAlgorithm"
                        />
                        </tbody>
                    </table>
                </div>
                <PaginationComponent
                    v-bind:totalCount="totalCount"
                    v-bind:currentPage="pageIndex"
                    v-bind:countPerPage="countPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="loadPageIndex"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PaginationComponent from '@/views/main/ShareComponents/PagenationComponent.vue'
import PageController from '@/controller/PageController'
import BasicTemplateListItem
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BasicTemplateList/BasicTemplateListItem.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    name: 'BasicTemplateList',
    components: { LoadingIndicate, BasicTemplateListItem, PaginationComponent },
    props: {},
    data() {
        return {
            isLoading: false,
            pageController: new PageController(),

            totalCount: 0,
            countPerPage: 20,
            pageIndex: 0,

            keyword: '',
            list: []
        }
    },
    computed: {
        filter() {
            return {
                keyword: this.keyword ? this.keyword : undefined,
                keyType: this.keyword ? 'name' : undefined,
                isRemoved: false
            }
        },
        noDataMessage() {
            if (this.list.length > 0 || this.isLoading) return ''

            return this.pageController?.filter?.keyword
                ? '검색된 템플릿이 없습니다.'
                : '표시할 내용이 없습니다.'
        }
    },
    mounted() {
        this.loadPageIndex(0)
    },
    methods: {
        async loadTotalCount() {
            const result = await this.API_V3.Automation.count(this.filter)
            if (result.data.code !== 0) {
                throw Error(result.data.message)
            }
            const { data } = result.data
            this.totalCount = data
            this.pageController.totalCount = data
        },
        async loadPageIndex(index) {
            try {
                this.isLoading = true
                await this.loadTotalCount()
                this.setupPageController()
                await this.pageController.loadPageIndex(index)
                this.pageIndex = index
                this.list = this.pageController.data
            } catch (error) {
                console.error('Failed to load page index:', error)
                this.list = []
            } finally {
                this.isLoading = false
            }
        },
        setupPageController() {
            Object.assign(this.pageController, {
                api: this.API_V3.Automation.find,
                filter: this.filter,
                countPerPage: this.countPerPage
            })
        },
        reloadCurrentPage() {
            this.loadPageIndex(this.pageIndex)
        },

        // MARK: - Actions
        action_ClosePopup() {
            this.$emit('closePopup')
        },
        setSelectedAlgorithm(algorithm) {
            this.$emit('setSelectedAlgorithm', algorithm)
        },
        action_Search() {
            this.loadPageIndex(0)
        }
    }
}

</script>

