<template>
    <ul class="settings_wrap type_settings flex_column mg_0 pd_20" v-if="hasSetting">
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                유형 명칭<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <input type="text" maxlength="30" placeholder="최대 30자 입력" class="w_full" v-model="name">
                <small class="red_txt" v-if="!isValid_Name">명칭을 입력해 주세요.</small>
                <!-- <small class="red_txt">사용 중인 명칭 입니다.</small> -->
            </div>
        </li>
        <BasicOptionsInitialChatList
            v-model="initialChats"
        />
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                고객등록 가입문자<small class="red_txt">*</small>
                <div class="title_description">고객 휴대폰으로 발송되는 가입 문자 내용
                <br>
                <br>
                [치환문자]<br>
                <span v-pre>{{병원명}} 1회 이상</span><br>
                <span v-pre>{{고객명 1회}}</span><br>
                <span class="red_txt" v-pre>{{링크}} 1회</span><br>
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsBasicMessage"></textarea>
                <small class="red_txt" v-if="!isValid_BasicMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValid_BasicMessageLink">링크는 필수값 입니다.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                예약 가입문자<small class="red_txt">*</small>
                <div class="title_description">예약에서 환자 등록시 발송되는 가입 문자 내용
                <br>
                <br>
                [치환문자]<br>
                <span v-pre>{{병원명}} 1회 이상</span><br>
                <span v-pre>{{고객명}} 1회 이상</span><br>
                <span class="red_txt" v-pre>{{링크}} 1회</span>
            </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsReservation"></textarea>
                <small class="red_txt" v-if="!isValid_ReservationMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValid_ReservationMessageLink">링크는 필수값 입니다.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                재발송 가입문자<small class="red_txt">*</small>
                <div class="title_description">
                    이미 등록된 환자에게 문자 재발송 버튼을 클릭했을 때 발송되는 가입 문자 내용
                    <br>
                    <br>
                    [치환문자]<br>
                    <span v-pre>{{병원명}} 1회</span><br>
                    <span v-pre>{{고객명}} 1회</span><br>
                    <span class="red_txt"><span v-pre>{{링크}} 1회</span></span><br>
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsResendMessage"></textarea>
                <small class="red_txt" v-if="!isValid_ResendMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValid_ResendMessageLink">링크는 필수값 입니다.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                가입문자 자동 재발송<small class="red_txt">*</small>
                <div class="title_description">
                    환자 등록 후 미가입 상태인 경우 설정된 일시에 해당 내용으로 가입 문자를 자동 재발송함 (등록 당일: 0일)
                    <br>
                    <br>
                    [치환문자]<br>
                    <span v-pre>{{병원명}} 1회</span><br>
                    <span v-pre>{{고객명}} 1회</span><br>
                    <span class="red_txt"><span v-pre>{{링크}} 1회</span></span><br></div>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 자동 재발송" :value="false" v-model="usingAutoReservedSMS">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 자동 재발송" :value="true" v-model="usingAutoReservedSMS">사용
                    </label>
                </div>
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSMessage"></textarea>
                <small class="red_txt" v-if="!isValid_ReservedSMSMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValid_ReservedSMSMessageLink">링크는 필수값 입니다.</small>
                <div class="flex_aic gap_5">등록일로부터
                    <select name="day" id="" style="width: 60px;" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSDelayDay">
                        <option :value="0">0</option>
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="6">6</option>
                        <option :value="7">7</option>
                        <option :value="8">8</option>
                        <option :value="9">9</option>
                        <option :value="10">10</option>
                        <option :value="11">11</option>
                        <option :value="12">12</option>
                        <option :value="13">13</option>
                        <option :value="14">14</option>
                    </select>
                    일 후
                    <select class="mg_0" name="time" id="" style="width: 60px;" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSHour">
                        <option :value="1">1</option>
                        <option :value="2">2</option>
                        <option :value="3">3</option>
                        <option :value="4">4</option>
                        <option :value="5">5</option>
                        <option :value="6">6</option>
                        <option :value="7">7</option>
                        <option :value="8">8</option>
                        <option :value="9">9</option>
                        <option :value="10">10</option>
                        <option :value="11">11</option>
                        <option :value="12">12</option>
                        <option :value="13">13</option>
                        <option :value="14">14</option>
                        <option :value="15">15</option>
                        <option :value="16">16</option>
                        <option :value="17">17</option>
                        <option :value="18">18</option>
                        <option :value="19">19</option>
                        <option :value="20">20</option>
                        <option :value="21">21</option>
                        <option :value="22">22</option>
                        <option :value="23">23</option>
                    </select>
                    시
                </div>
            </div>
        </li>
        
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" @click="action_Save">저장</button>
        </li>
    </ul>
</template>


<script>
import BasicOptionsInitialChatList from '../../../SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/BasicOptionsInitialChatList.vue'

export default {
    components: { BasicOptionsInitialChatList },
    props: {
        setting: {
            type: Object,
            default: () => undefined
        }
    },
    data() {
        return {
            name: '',
            initialChats: [],
            smsBasicMessage: '',
            smsReservation: '',
            smsResendMessage: '',
            usingAutoReservedSMS: false,
            autoReservedSMSMessage: '',
            autoReservedSMSDelayDay: 0,
            autoReservedSMSHour: 1,
        }
    },
    watch: {
        setting: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.name = newValue.name ?? ''
                this.initialChats = newValue.initialChats ?? []
                this.smsBasicMessage = newValue.smsBasicMessage ?? ''
                this.smsReservation = newValue.smsReservation ?? ''
                this.smsResendMessage = newValue.smsResendMessage ?? ''
                this.usingAutoReservedSMS = newValue.usingAutoReservedSMS ?? false
                this.autoReservedSMSMessage = newValue.autoReservedSMSMessage ?? ''
                this.autoReservedSMSDelayDay = newValue.autoReservedSMSDelayDay ?? 0
                this.autoReservedSMSHour = newValue.autoReservedSMSHour ?? 1
            }
        }
    },
    computed: {
        hasSetting: function() { return this.setting != undefined },
        isValid_Name: function() { return this.name != undefined && this.name != '' },
        isValid_BasicMessage: function() { return (this.smsBasicMessage != undefined && this.smsBasicMessage != '') },
        isValid_BasicMessageLink: function() {
            if (this.isValid_BasicMessage == false) { return true }
            return this.smsBasicMessage.includes('{{링크}}')
        },
        isValid_ReservationMessage: function() { return this.smsReservation != undefined && this.smsReservation != '' },
        isValid_ReservationMessageLink: function() {
            if (this.isValid_ReservationMessage == false) { return true }
            return this.smsReservation.includes('{{링크}}')
        },
        isValid_ResendMessage: function() { return this.smsResendMessage != undefined && this.smsResendMessage != '' },
        isValid_ResendMessageLink: function() {
            if (this.isValid_ResendMessage == false) { return true }
            return this.smsResendMessage.includes('{{링크}}')
        },
        isValid_ReservedSMSMessage: function() {
            if (this.usingAutoReservedSMS == false) { return true }
            return this.autoReservedSMSMessage != undefined && this.autoReservedSMSMessage != ''
        },
        isValid_ReservedSMSMessageLink: function() {
            if (this.usingAutoReservedSMS == false) { return true }
            if (this.isValid_ReservedSMSMessage == false) { return true }
            return this.isValid_ReservedSMSMessage == true && this.autoReservedSMSMessage.includes('{{링크}}')
        },
        canSave: function() {
            return this.isValid_Name 
                && this.isValid_BasicMessage && this.isValid_BasicMessageLink
                && this.isValid_ReservationMessage && this.isValid_ReservationMessageLink
                && this.isValid_ResendMessage && this.isValid_ResendMessageLink
                && this.isValid_ReservedSMSMessage && this.isValid_ReservedSMSMessageLink
        }
    },
    methods: {
        // MARK: Network
        async updateSetting() {
            if(this.setting._id == undefined) { return }
            let model = {
                _id: this.setting._id,
                name: this.name,
                initialChats: this.initialChats,
                smsBasicMessage: this.smsBasicMessage,
                smsReservation: this.smsReservation,
                smsResendMessage: this.smsResendMessage,
                usingAutoReservedSMS: this.usingAutoReservedSMS,
                autoReservedSMSMessage: this.autoReservedSMSMessage,
                autoReservedSMSDelayDay: this.autoReservedSMSDelayDay,
                autoReservedSMSHour: this.autoReservedSMSHour,
            }

            let result = await this.API_V3.ServiceSetting.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('reloadDatas')
        },

        // MARK: Actions
        action_Save() {
            if (!this.canSave) { return }
            this.updateSetting()
        }
    }
}
</script>