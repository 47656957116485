<template>
    <div class="v2_content_wrap">
        <LoadingIndicate :isLoading="isLoading"/>
        <div class="page_title">
            <span>상담톡 검색용 아이디 관리</span>
        </div>

        <div class="h_scroll" style="max-width: 1220px;">
            <div class="table_title_btn flex_aic_spb mgb_20">
                <p class="large_txt">
                    등록된 거래처 리스트
                </p>
                <div>
                    <input type="text" class="input_s" placeholder="병원명 검색" @keydown.enter="action_Enter" v-model="keyword"/>
                    <button class="btn_s btn_blue_line mgl_5" @click="action_Enter">검색</button>
                </div>
            </div>
            <div class="table_header_fix">
                <!-- 검색 데이터 없을 때 -->
                <KakaoConsultEmptyResultsVue v-if="isEmpty"/>
                <!-- hospital data table -->
                <table class="table_default consult_talk" v-if="!isEmpty">
                    <thead>
                        <tr>
                            <th width="300" class="txt_left">병원명</th>
                            <th width="250" class="txt_left">검색용 아이디</th>
                            <th width="" class="txt_left">상담 가능 일시</th>
                            <th width="130" class="txt_left">신청일</th>
                            <th width="70">상태</th>
                        </tr>
                        
                    </thead>
                    <KakaoConsultCell
                        v-for="(history, index) in linkages" :key="index"
                        :history="history"
                    />
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="totalCount"
                v-bind:currentPage="currentPage"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="changePage"
            />
        </div>
    </div>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'
import KakaoConsultEmptyResultsVue from './KakaoConsultEmptyResults.vue'
import KakaoConsultCell from './KakaoConsultCell.vue'

import PageController from '@/controller/PageController'
export default {
    components: {
        LoadingIndicate,
        Pagenation,
        KakaoConsultEmptyResultsVue,
        KakaoConsultCell
    },
    data() {
        return {
            isLoading: false,
            linkages: [],
            
            keyword: '',

            pageController: new PageController(),

            totalCount: 0,
            countPerPage: 10,
            currentPage: 0,
        }
    },
    computed: {
        isEmpty: function() {
            return this.linkages.length == 0
        },
        filter: function() {
            return {
                isRequestForAlimAndFriendTalkLinkage: false,
                isRequestForConsultationTalkLinkage: true,
                keyword: this.keyword,
                key: 'createdAt',
                way: -1
            }
        }
    },
    mounted() {
        this.reload()
    },
    methods: {
        async reload() {
            this.loadPageIndex(0)
        },
        async loadCount() {
            let result = await this.API_V3.KakaoLinkageHistory.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadPageIndex(index) {
            this.isLoading = true
            await this.loadCount()
            this.pageController.api = this.API_V3.KakaoLinkageHistory.find
            this.pageController.filter = this.filter
            this.pageController.countPerPage = this.countPerPage
            this.currentPage = index
            await this.pageController.loadPageIndex(index)
            this.isLoading = false
            this.linkages = this.pageController.data
        },

        changePage(index) {
            this.loadPageIndex(index)
        },

        // MARK: - Actions
        action_Enter() {
            this.changePage(0)
        }
    }
}
</script>