<template>
    <div class="v2_content_wrap">
        <div class="page_title">
            <span>국가별 문자 메시지 설정</span>
        </div>
        
        <ul class="settings_wrap national_sms_settings flex_column gap_20 pd_20">
            <li class="add_info">
                한국을 제외한, DB에 등록되어 있는 번호를 기준으로 작성 가능. 추가의 경우 개발팀 문의 필요
            </li>
            <li>
                <select name="" id="" class="input_s">
                    <option value="">한글명</option>
                    <option value="">영문명</option>
                    <option value="">ISO코드</option>
                </select>
                <input type="text" class="input_s" placeholder="검색어 입력">
                <button class="btn_s btn_blue_line">검색</button>
            </li>
            <li class="grid_settings gap_15">
                <NationalSMSSettingItem
                    v-for="(country, index) in countries"
                    :key="index"
                    :item="country"
                    @loadCountries="loadCountries"
                />
            </li>
        </ul>
    </div>
</template>


<script>
import NationalSMSSettingItem from './NationalSMSSettingItem.vue'

export default {
    components: { NationalSMSSettingItem },
    data() {
        return {
            countries: []
        }
    },
    mounted() {
        this.loadCountries()
    },
    methods: {
        async loadCountries() {
            let result = await this.API_V3.Country.find({
                hasDefaultMessage: true,
            })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.countries = result.data.data
        },
    },
}
</script>