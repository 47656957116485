<template>
    <div class="v2_content_wrap">
        <div class="page_title">
            <span>V2 병원유형 설정</span>
        </div>

        <!-- 그룹 추가 버튼 클릭 시 나타나는 팝업 -->
        <V2HospitalTypeSettingCreatePopup
            :isShow="isShowCreatePopup"
            @close="closeCreatePopup"
            @loadDatas="loadSampleServiceSetting"
        />
        <RemoveConfirm
            title="병원유형 삭제"
            body="병원유형을 삭제 하시겠습니까?"
            :isShow="isShowRemoveConfirmPopup"
            @alertCallback="alertCallback"
        />

        <div class="flex gap_0" style="height: 100%; overflow: hidden;">
            <div class="h_scroll" style="border-right: 1px solid #D7DBE1;" >
                <div class="table_title_btn flex_aic_spb mgb_20">
                    <p class="large_txt" style="line-height: 34px;">
                        유형 목록
                    </p>
                </div>
                <div class="table_header_fix">
                    <!-- 생성된 유형 데이터 없을 떄 -->
                    <table class="table_default friend_talk" v-if="isEmpty">
                        <tr>
                            <th width="360" class="txt_left" colspan="2">
                                <span class="flex_spb">
                                    <span>병원유형</span>
                                    <button class="add_group_btn" @click="action_ShowCreatePopup" :disabled="!auth.onboarding_w">유형 추가 +</button>
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="2">병원유형을 추가해 주세요.</td>
                        </tr>
                    </table>
                    
                    <!-- hospital data table -->
                    <table class="table_default friend_talk" v-if="!isEmpty">
                        <tr>
                            <th width="360" class="txt_left" colspan="2">
                                <span class="flex_spb">
                                    <span>병원유형</span>
                                    <button class="add_group_btn" @click="action_ShowCreatePopup" :disabled="!auth.onboarding_w">유형 추가 +</button>
                                </span>
                            </th>
                        </tr>
                        <V2HospitalTypeSettingListItem
                            v-for="(item, index) in list"
                            :key="index"
                            :index="index"
                            :item="item"
                            @setSelectedSetting="setSelectedSetting"
                            @removeItem="removeItem"
                        />
                    </table>
                </div>
            </div>
            <V2HospitalTypeSettingDetail
                :setting="selectedSetting"
                @reloadDatas="reloadDatas"
            />
        </div>
    </div>
</template>


<script>
import RemoveConfirm from '../../../../ShareComponents/RemoveConfirm.vue'
import V2HospitalTypeSettingCreatePopup from './V2HospitalTypeSettingCreatePopup.vue'
import V2HospitalTypeSettingListItem from './V2HospitalTypeSettingListItem.vue'
import V2HospitalTypeSettingDetail from './V2HospitalTypeSettingDetail.vue'

export default {
    components: {
        RemoveConfirm,
        V2HospitalTypeSettingCreatePopup,
        V2HospitalTypeSettingListItem,
        V2HospitalTypeSettingDetail
    },
    props: {},
    data() {
        return {
            isShowRemoveConfirmPopup: false,
            removalItemIndex: -1,
            isShowCreatePopup: false,

            list: [],
            selectedSetting: undefined
        }
    },
    watch: {},
    computed: {
        isEmpty: function() {
            return this.list.length == 0
        },
        hasSelectedSetting: function() {
            return this.selectedSetting == undefined
        },
    },
    mounted() {
        this.loadSampleServiceSetting()
    },
    methods: {
        // MARK: - Network
        async loadSampleServiceSetting() {
            let result = await this.API_V3.ServiceSetting.find({
                isSample: true,
                isEnabledSaaS: true,
                isRemoved: false
            })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.list = result.data.data
        },
        async removeSampleServiceSetting(item) {
            let result = await this.API_V3.ServiceSetting.remove({_id: item._id})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadSampleServiceSetting()
        },

        // MARK: - Actions
        action_ShowCreatePopup() {
            this.isShowCreatePopup = true
        },

        // MARK: - Emit
        setSelectedSetting(item) {
            this.selectedSetting = item
        },
        closeCreatePopup() {
            this.isShowCreatePopup = false
        },
        removeItem(index) {
            this.removalItemIndex = index
            this.isShowRemoveConfirmPopup = true
        },
        reloadDatas() {
            this.loadSampleServiceSetting()
        },
        alertCallback(isConfirm) {
            this.isShowRemoveConfirmPopup = false
            if (!isConfirm) { return }

            let item = this.list[this.removalItemIndex]
            this.removeSampleServiceSetting(item)
        },
    }
}
</script>