<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>앱 관련 설정</span>
        </div>
        <div class="h_scroll">
            <div class="w_940">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">홈케어 공유하기 배너</span>
                        <span class="info mgl_auto">홈케어 콘텐츠 상세화면과 홈케어 완료 화면에서 보여지는 공유하기 배너이미지를 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <div class="form_list">
                            <div class="title">배너 이미지</div>
                            <div class="cont">
                                <input type="file" accept="image/*" name="" id="" class="input_s" @change="action_uploadBannerImage($event)" />
                            </div>
                        </div>
                        <small @mouseenter="bannerImageHover('on')" @mouseleave="bannerImageHover('off')" class="lgray_txt mgt_10 img_preview_wrap">
                            현재 저장된 FileID : {{ bannerImageID }}
                            <span class="img_preview">
                                <img v-if="bannerImageHoverStatus == true && bannerImageURL != ''" :src="bannerImageURL" alt="" />
                            </span>
                        </small>
                        <div class="txt_right">
                            <small class="blue_txt mgt_10" v-if="bannerImageResultMessageShow == true">
                                {{ bannerImageResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap right pd_0 mgt_15">
                            <button class="btn btn_l btn_blue" @click="action_updateBannerImage" :disabled="!auth.videoContent_w">저장</button>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">홈케어 3회차 완료시 노출 팝업</span>
                        <span class="info mgl_auto">환자가 홈케어 3회차를 완료했을 때 표시되는 팝업 이미지를 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <div class="form_list">
                            <div class="title">팝업 이미지</div>
                            <div class="cont">
                                <input type="file" accept="image/*" name="" id="" class="input_s" @change="action_uploadPopupImageCV($event)"  :disabled="!auth.videoContent_w"/>
                            </div>
                        </div>
                        <small @mouseenter="popupImageCVIDHover('on')" @mouseleave="popupImageCVIDHover('off')" class="lgray_txt mgt_10 img_preview_wrap">
                            현재 저장된 FileID : {{ popupImageCVID }}
                            <span class="img_preview">
                                <img v-if="popupImageCVHoverStatus == true && popupImageCVURL != ''" :src="popupImageCVURL" alt="" />
                            </span>
                        </small>
                        <div class="txt_right">
                            <small class="blue_txt mgt_10" v-if="popupImageCVResultMessageShow == true">
                                {{ popupImageCVResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap right pd_0 mgt_15">
                            <button class="btn btn_l btn_blue" @click="action_updatePopupImageCV" :disabled="!auth.videoContent_w">저장</button>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">홈케어 콘텐츠 전송시 기본 코멘트</span>
                        <span class="mgl_auto info">병원에서 홈케어 콘텐츠를 발송할 때 표시되는 기본 코멘트를 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <textarea v-model="defaultVCMessage" name="" id="" rows="4" class="input_s w_full" placeholder="코멘트 입력"></textarea>
                        <div class="txt_right">
                            <small class="blue_txt mgt_10" v-if="defaultVCMessageResultMessageShow == true">
                                {{ defaultVCMessageResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap right pd_0 mgt_15">
                            <button class="btn btn_l btn_blue" @click="action_updateDefaultVCMessage" :disabled="!auth.videoContent_w">저장</button>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">홈케어 콘텐츠 전송시 채팅 내용</span>
                        <span class="mgl_auto info">병원에서 전송한 홈케어 콘텐츠가 채팅으로 전달될 때 표시되는 내용입니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <textarea v-model="defaultVCChatMessage" name="" id="" rows="4" class="input_s w_full" placeholder="채팅 내용 입력"></textarea>
                        <div class="txt_right">
                            <small class="blue_txt mgt_10" v-if="defaultVCChatMessageResultMessageShow == true">
                                {{ defaultVCChatMessageResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap right pd_0 mgt_15">
                            <button class="btn btn_l btn_blue" @click="action_updateDefaultVCChatMessage" :disabled="!auth.videoContent_w">저장</button>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">홈케어 콘텐츠 전달 푸시 알림</span>
                        <span class="mgl_auto info">홈케어 콘텐츠가 환자에게 전송될 때 표시되는 푸시 알림입니다.(default 콘텐츠 푸시 제외)</span>
                    </div>
                    <div class="form_flex_cont">
                        <div class="form_list">
                            <div class="title">푸시 제목</div>
                            <div class="cont">
                                <input v-model="defaultVCPushMessageTitle" type="text" class="w_full input_s" placeholder="제목" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">푸시 내용</div>
                            <div class="cont">
                                <input v-model="defaultVCPushMessageContent" type="text" class="w_full input_s" placeholder="내용" />
                            </div>
                        </div>
                        <div class="txt_right">
                            <small class="blue_txt mgt_10" v-if="defaultVCPushMessageResultMessageShow == true">
                                {{ defaultVCPushMessageResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap right pd_0 mgt_15">
                            <button class="btn btn_l btn_blue" @click="action_updateDefaultVCPushMessage" :disabled="!auth.videoContent_w">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            settingID: '', // current VideoContentGlobalSetting ID
            bannerImageID: '',
            bannerImageURL: '',
            bannerImageHoverStatus: false,
            bannerImageResultMessage: '',
            bannerImageResultMessageShow: false,
            popupImageCVID: '',
            popupImageCVURL: '',
            popupImageCVHoverStatus: false,
            popupImageCVResultMessage: '',
            popupImageCVResultMessageShow: false,
            defaultVCMessage: '',
            defaultVCMessageResultMessage: '',
            defaultVCMessageResultMessageShow: false,
            defaultVCChatMessage: '',
            defaultVCChatMessageResultMessage: '',
            defaultVCChatMessageResultMessageShow: false,
            defaultVCPushMessageTitle: '',
            defaultVCPushMessageContent: '',
            defaultVCPushMessageResultMessage: '',
            defaultVCPushMessageResultMessageShow: false
        }
    },
    mounted() {
        this.findVideoContentGlobalSetting()
    },
    watch: {
        defaultVCMessage: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.defaultVCMessageResultMessage = '홈케어 콘텐츠 전송시 기본 코멘트 내용을 입력해주세요.'
                    this.defaultVCMessageResultMessageShow = true
                } else {
                    this.defaultVCMessageResultMessage = ''
                    this.defaultVCMessageResultMessageShow = false
                }
            }
        },
        defaultVCChatMessage: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.defaultVCChatMessageResultMessage = '홈케어 콘텐츠 전송시 채팅 내용을 입력해주세요.'
                    this.defaultVCChatMessageResultMessageShow = true
                } else {
                    this.defaultVCChatMessageResultMessage = ''
                    this.defaultVCChatMessageResultMessageShow = false
                }
            }
        },
        defaultVCPushMessageTitle: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.defaultVCPushMessageResultMessage = '홈케어 콘텐츠 전달 푸시 알림 제목 및 내용을 모두 입력해주세요.'
                    this.defaultVCPushMessageResultMessageShow = true
                } else {
                    this.defaultVCPushMessageResultMessage = ''
                    this.defaultVCPushMessageResultMessageShow = false
                }
            }
        },
        defaultVCPushMessageContent: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.defaultVCPushMessageResultMessage = '홈케어 콘텐츠 전달 푸시 알림 제목 및 내용을 모두 입력해주세요.'
                    this.defaultVCPushMessageResultMessageShow = true
                } else {
                    this.defaultVCPushMessageResultMessage = ''
                    this.defaultVCPushMessageResultMessageShow = false
                }
            }
        }
    },
    methods: {
        bannerImageHover(status) {
            switch (status) {
                case 'on':
                    this.bannerImageHoverStatus = true
                    break
                case 'off':
                    this.bannerImageHoverStatus = false
                    break
            }
        },
        popupImageCVIDHover(status) {
            switch (status) {
                case 'on':
                    this.popupImageCVHoverStatus = true
                    break
                case 'off':
                    this.popupImageCVHoverStatus = false
                    break
            }
        },
        async findVideoContentGlobalSetting() {
            let result = await this.API.VideoContentGlobalSetting.find()
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            if (result.data.data != undefined && result.data.data.length > 0) {
                let globalsetting = result.data.data[0]
                this.settingID = globalsetting._id
                this.bannerImageID = globalsetting.bannerImage != undefined ? globalsetting.bannerImage._id : ''
                this.bannerImageURL = globalsetting.bannerImage != undefined ? globalsetting.bannerImage.paths[0] : ''
                this.popupImageCVID = globalsetting.popupImageCV != undefined ? globalsetting.popupImageCV._id : ''
                this.popupImageCVURL = globalsetting.popupImageCV != undefined ? globalsetting.popupImageCV.paths[0] : ''
                this.defaultVCMessage = globalsetting.defaultVCMessage
                this.defaultVCChatMessage = globalsetting.defaultVCChatMessage
                this.defaultVCPushMessageTitle = globalsetting.defaultVCPushMessageTitle
                this.defaultVCPushMessageContent = globalsetting.defaultVCPushMessageContent
            }
        },

        async action_uploadBannerImage(event) {
            let result = await this.action_UploadImageFile(event)
            if (result != undefined && result != '') {
                this.bannerImageID = result._id
                this.bannerImageURL = result.paths[0]
            } else {
                this.bannerImageResultMessage = '파일 업로드 실패. 다시 업로드해주세요.'
                this.bannerImageResultMessageShow = true
            }
        },

        async action_uploadPopupImageCV(event) {
            let result = await this.action_UploadImageFile(event)
            if (result != undefined && result != '') {
                this.popupImageCVID = result._id
                this.popupImageCVURL = result.paths[0]
            } else {
                this.popupImageCVResultMessage = '파일 업로드 실패. 다시 업로드해주세요.'
                this.popupImageCVResultMessageShow = true
            }
        },

        // base upload image method
        async action_UploadImageFile(event) {
            let files = event.currentTarget.files
            let sizes = []

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                var imageReader = new FileReader()
                imageReader.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result

                    img.onload = function () {
                        var w = this.width
                        var h = this.height
                        let size = {
                            width: w,
                            height: h
                        }
                        sizes.push(size)
                    }
                }
                // console.log(file)
                imageReader.readAsDataURL(file)
            }
            let mimeType = 'image/*'
            let type = 610 // TODO : banner, popup image type?
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, sizes, mimeType, type, userID)

            if (result.data.code != 0) {
                // TODO: Error Handle
                console.log(result.data.message)
                return
            }
            console.log(result.data.data)
            // return result.data.data._id
            return result.data.data
        },

        async action_updateBannerImage() {
            let model = {
                _id: this.settingID,
                bannerImage: this.bannerImageID
            }
            let result = await this.action_updateVideoContentGlobalSetting(model)
            if (result.data.code == 0) {
                this.bannerImageResultMessage = '저장되었습니다.'
                this.bannerImageResultMessageShow = true
            } else {
                this.bannerImageResultMessage = '에러:' + result.data.data
                this.bannerImageResultMessageShow = true
            }
        },

        async action_updatePopupImageCV() {
            let model = {
                _id: this.settingID,
                popupImageCV: this.popupImageCVID
            }
            let result = await this.action_updateVideoContentGlobalSetting(model)
            if (result.data.code == 0) {
                this.popupImageCVResultMessage = '저장되었습니다.'
                this.popupImageCVResultMessageShow = true
            } else {
                this.popupImageCVResultMessage = '에러:' + result.data.data
                this.popupImageCVResultMessageShow = true
            }
        },

        async action_updateDefaultVCMessage() {
            if (this.defaultVCMessage == '') {
                this.defaultVCMessageResultMessage = '홈케어 콘텐츠 전송시 기본 코멘트 내용을 입력해주세요.'
                this.defaultVCMessageResultMessageShow = true
                return
            }
            let model = {
                _id: this.settingID,
                defaultVCMessage: this.defaultVCMessage
            }
            let result = await this.action_updateVideoContentGlobalSetting(model)
            if (result.data.code == 0) {
                this.defaultVCMessageResultMessage = '저장되었습니다.'
                this.defaultVCMessageResultMessageShow = true
            } else {
                this.defaultVCMessageResultMessage = '에러:' + result.data.data
                this.defaultVCMessageResultMessageShow = true
            }
        },

        async action_updateDefaultVCChatMessage() {
            if (this.defaultVCChatMessage == '') {
                this.defaultVCChatMessageResultMessage = '홈케어 콘텐츠 전송시 채팅 내용을 입력해주세요.'
                this.defaultVCChatMessageResultMessageShow = true
                return
            }
            let model = {
                _id: this.settingID,
                defaultVCChatMessage: this.defaultVCChatMessage
            }
            let result = await this.action_updateVideoContentGlobalSetting(model)
            if (result.data.code == 0) {
                this.defaultVCChatMessageResultMessage = '저장되었습니다.'
                this.defaultVCChatMessageResultMessageShow = true
            } else {
                this.defaultVCChatMessageResultMessage = '에러:' + result.data.data
                this.defaultVCChatMessageResultMessageShow = true
            }
        },

        async action_updateDefaultVCPushMessage() {
            if (this.defaultVCPushMessageTitle == '' || this.defaultVCPushMessageContent == '') {
                this.defaultVCPushMessageResultMessage = '홈케어 콘텐츠 전달 푸시 알림 제목 및 내용을 모두 입력해주세요.'
                this.defaultVCPushMessageResultMessageShow = true
                return
            }
            let model = {
                _id: this.settingID,
                defaultVCPushMessageTitle: this.defaultVCPushMessageTitle,
                defaultVCPushMessageContent: this.defaultVCPushMessageContent
            }
            let result = await this.action_updateVideoContentGlobalSetting(model)
            if (result.data.code == 0) {
                this.defaultVCPushMessageResultMessage = '저장되었습니다.'
                this.defaultVCPushMessageResultMessageShow = true
            } else {
                this.defaultVCPushMessageResultMessage = '에러:' + result.data.data
                this.defaultVCPushMessageResultMessageShow = true
            }
        },

        // base update method
        async action_updateVideoContentGlobalSetting(model) {
            let result = await this.API.VideoContentGlobalSetting.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            return result
        }
    }
}
</script>
