import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (query) => {
    return axios({
        method: 'GET',
        url: URL.ACCOUNT_SETTING_HISTORY_COUNT,
        params: query,
        headers: Util.getHeaders()
    })
}

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.ACCOUNT_SETTING_HISTORY_FIND,
        params: query,
        headers: Util.getHeaders()
    })
}
