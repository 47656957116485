<template>
    <li class="settings_list flex_column gap_20">
        <div class="item_list_title flex_aic_spb">
            <span class="large_txt">{{ title }}</span>
            <small class="lgray_txt">{{ desc }}</small>
        </div>
        <div class="item_wrap flex_column gap_20">
            <div class="flex gap_20">
                <b class="dgray_txt">발송시간</b>
                <div class="flex_aic gap_5">
                    <select name="발송시간" id="" class="input_s">
                        <option value="0">00</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10" selected>10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                    </select>
                    시
                    <select name="발송분" id="" class="input_s">
                        <option value="0">00</option>
                        <option value="5">05</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                        <option value="35">35</option>
                        <option value="40">40</option>
                        <option value="45">45</option>
                        <option value="50">50</option>
                        <option value="55">55</option>
                    </select>
                    분
                </div>
            </div>
            <div class="flex gap_20">
                <b class="dgray_txt">발송내용</b>
                <div class="flex_aic gap_5">
                    <button class="btn_s btn_blue_line">알림톡 선택</button>
                </div>
            </div>
            <div>
                <table class="table_default">
                    <colgroup>
                        <col width="129">
                        <col width="170">
                        <col width="129">
                        <col width="170">
                        <col width="129">
                        <col width="170">
                    </colgroup>
                    <tr>
                        <th>발신프로필/그룹</th>
                        <td>
                            <p style="max-width: 149px;">
                                [그룹] 메디팔[그룹] 메디팔
                            </p>
                        </td>
                        <th>템플릿 명</th>
                        <td>
                            <p style="max-width: 149px;">
                                환자vip2환자vip2환자vip2
                            </p>
                        </td>
                        <th>템플릿코드</th>
                        <td>
                            <p style="max-width: 149px;">
                                patientviptest2patientviptest2
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>검수상태</th>
                        <td>
                            <small class="service_status lgreen">
                                승인
                            </small>
                        </td>
                        <th>등록일시</th>
                        <td>2024-08-02 11:54</td>
                        <th>수정일시</th>
                        <td>2024-08-30 13:18</td>
                    </tr>
                </table>
            </div>
            <div>
                <textarea name="" id="" rows="10" class="w_full"  disabled>선택한 알림톡 내용이 들어가 있음</textarea>
            </div>
        </div>
        <div class="flex_aic_fle">
            <button class="btn_s btn_blue" :disabled="!auth.electron_w">저장</button>
        </div>
    </li>
</template>


<script>
export default {
    props: {
        title: String,
        desc: String,
        type: String
    },
    data() {
        return {

        }
    },
    watch: {},
    computed: {},
    methods: {},
}
</script>