<template>
    <div class="snb">
        <div class="snb_title">온보딩 설정</div>
        <a @click="action_HospitalType" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('/hospitaltype/v1') }">V1 병원유형 설정</a>
        <a @click="action_V2HospitalType" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('/hospitaltype/v2') }" >V2 병원유형 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_HospitalType() {
            this.$router.push({ name: 'HospitalTypeSetting' }).catch(() => {})
        },
        action_V2HospitalType() {
            this.$router.push({ name: 'v2HospitalTypeSetting' }).catch(() => {})
        }
    }
}
</script>

<style scoped></style>
