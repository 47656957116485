<template>
    <div class="form_list flex_column gap_15">
        <div class="title">
            국적(선택)
        </div>
        <div class="h_scroll cont custom_tag_wrap pd_15 flex_column gap_20 relative" style="max-height: 220px;">
            <div style="height:220px; position: relative; flex-shrink: 0" v-if="isLoading">
                <LoadingIndicate :is-loading="true" />
            </div>

            <template v-if="countries.length > 0">
                <div class="custom_tag_group flex_column gap_15 ">
                    <NationalitySettingItem
                        v-for="(country, index) in countries"
                        :key="index"
                        :country="country"
                        :selectedCountryIDs="selectedCountryIDs"
                        @setSelectedCountryIDs="updateSelectedCountryIDs"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import NationalitySettingItem
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BaseConfiguration/NationalitySettingItem.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    name: 'NationalitySetting',
    components: { LoadingIndicate, NationalitySettingItem },
    props: {
        selectedCountryIDs: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            countries: [],

            isLoading: false
        }
    },
    watch: {},
    computed: {},
    mounted() {
        this.loadCountries()
    },
    methods: {
        async loadCountries() {
            try {
                this.isLoading = true
                const { data } = await this.API_V3.Country.find()
                if (data.code !== 0) {
                    throw new Error(data.message)
                }
                this.countries = data.data
            } catch (error) {
                console.error('Error fetching countries:', error.message)
            } finally {
                this.isLoading = false
            }
        },
        updateSelectedCountryIDs(ids) {
            this.$emit('setSelectedCountryIDs', ids)
        },
    }
}

</script>

