<template>
    <div class="v2_content_wrap">
        <CustomToast
            :message="toastMessage"
            @clear="setToastMessage"
        />

        <RemoveConfirm
            title="상담자동화 삭제"
            body="상담자동화를 삭제 하시겠습니까?"
            :isShow="isShowRemoveConfirmPopup"
            @alertCallback="alertCallback"
        />


        <div class="page_title">
            <span>상담자동화</span>
        </div>
        <div class="h_scroll">

            <div class="table_title mgb_20">
                <p>
                    총 <span class="black_txt">{{ totalCount }}</span>개
                </p>
                <div class="table_title_btn">
                    <select class="input_s" v-model="keyType">
                        <option
                            v-for="(option, index) in keyTypes" :key="index"
                            :value="option.value">{{ option.title }}
                        </option>
                    </select>
                    <input type="text" class="input_s" placeholder="검색어 입력" v-model="keyword"
                           @keyup.enter="action_Search">
                    <button class="btn_s btn_blue_line mgl_5" @click="action_Search">검색</button>
                    <button class="btn_s btn_blue" @click="action_ShowEditPopup()">추가</button>
                </div>
            </div>
            <div class="table_header_fix" v-if="isEmpty">
                <table class="table_default">
                    <thead>
                    <tr>
                        <th class="txt_left" width="300px">명칭</th>
                        <th class="txt_left" width="">메모</th>
                        <th class="txt_left" width="80px">메시지 개수</th>
                        <th class="txt_left" width="140px">최종수정일시</th>
                        <th class="txt_left" width="80px">삭제</th>
                        <th class="txt_left" width="80xp">수정</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="6">검색된 내용이 없습니다.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_header_fix" v-if="!isEmpty">
                <table class="table_default">
                    <thead>
                    <tr>
                        <th class="txt_left" width="300px">명칭</th>
                        <th class="txt_left" width="">메모</th>
                        <th class="txt_left" width="80px">메시지 개수</th>
                        <th class="txt_left" width="140px">최종수정일시</th>
                        <th class="txt_left" width="80px">삭제</th>
                        <th class="txt_left" width="80xp">수정</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ConsultAutomationItem
                        v-for="(algorithm, index) in list" :key="index"
                        :algorithm="algorithm"
                        @modify="modifyAlgorithm"
                        @remove="removeAlgorithm"
                    />
                    </tbody>
                </table>
            </div>
            <PagenationComponent
                v-bind:totalCount="totalCount"
                v-bind:currentPage="pageIndex"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="loadPageIndex"
            />
        </div>
        <!-- 여기 상담 자동화 생성 or 수정하는 팝업 -->
        <ConsultAutomationEditPopup
            :isShow="isShowEditPopup"
            :selectedAlgorithm="selectedAlgorithm"
            @setToastMessage="setToastMessage"
            @reloadCurrentPage="reloadCurrentPage"
            @close="closeEditPopup"
        />
    </div>
</template>


<script>
import PageController from '@/controller/PageController'
import ConsultAutomationEditPopup from './EditPopup/ConsultAutomationEditPopup.vue'
import ConsultAutomationItem from './ConsultAutomationItem.vue'
import CustomToast from '@/views/main/ShareComponents/CustomToast.vue'
import PagenationComponent from '@/views/main/ShareComponents/PagenationComponent.vue'
import RemoveConfirm from '@/views/main/ShareComponents/RemoveConfirm.vue'

export default {
    components: {
        RemoveConfirm,
        CustomToast,
        ConsultAutomationEditPopup,
        ConsultAutomationItem,
        PagenationComponent
    },
    props: {},
    data() {
        return {
            isLoading: false,
            isShowRemoveConfirmPopup: false,
            removeAlgorithmItem: undefined,

            toastMessage: undefined,

            isShowEditPopup: false,
            selectedAlgorithm: undefined,

            list: [],

            keyType: 'name',
            keyword: '',

            pageController: new PageController(),

            totalCount: 0,
            countPerPage: 20,
            pageIndex: 0
        }
    },
    watch: {},
    computed: {
        keyTypes: function() {
            return [
                { value: 'name', title: '명칭' },
                { value: 'memo', title: '메모' },
                { value: 'updatedAt', title: '최종수정일' }
            ]
        },
        isEmpty: function() {
            return this.list.length == 0
        },
        filter: function() {
            const { keyType, keyword } = this

            return (!keyword || keyword.trim() === '')
                ? { isRemoved: false }
                : { keyType, keyword, isRemoved: false }
        }
    },
    mounted() {
        this.loadPageIndex(0)
    },
    methods: {
        async loadTotalCount() {
            let result = await this.API_V3.Automation.count(this.filter)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.totalCount = result.data.data
            this.pageController.totalCount = this.totalCount
        },
        async loadPageIndex(index) {
            await this.loadTotalCount()
            this.pageController.api = this.API_V3.Automation.find
            this.pageController.filter = this.filter
            this.pageController.countPerPage = this.countPerPage
            await this.pageController.loadPageIndex(index)
            this.pageIndex = index
            this.list = this.pageController.data
        },
        async delete(algorithm) {
            let result = await this.API_V3.Automation.remove({ _id: algorithm._id })
            if (result.data.code != 0) {
                alert(result.data.message)
            } else {
                this.setToastMessage('삭제되었습니다..')
                this.reloadCurrentPage()
            }
        },

        // MARK: - Actions
        action_Search() {
            this.loadPageIndex(0)
        },
        action_ShowEditPopup(algorithm) {
            this.selectedAlgorithm = algorithm
            this.isShowEditPopup = true
        },

        // MARK: - Emit
        setToastMessage(message) {
            this.toastMessage = message
        },
        closeEditPopup() {
            this.selectedAlgorithm = undefined
            this.isShowEditPopup = false
        },
        modifyAlgorithm(algorithm) {
            this.action_ShowEditPopup(algorithm)
        },
        removeAlgorithm(algorithm) {
            this.removeAlgorithmItem = algorithm
            this.isShowRemoveConfirmPopup = true
        },
        alertCallback(isConfirm) {
            this.isShowRemoveConfirmPopup = false
            if (isConfirm && this.removeAlgorithmItem) {
                this.delete(this.removeAlgorithmItem)
            }
            this.removeAlgorithmItem = undefined
        },
        reloadCurrentPage() {
            this.loadPageIndex(this.pageIndex)
        }
    }
}
</script>