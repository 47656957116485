<template>
    <ul v-if="isShow" class="settings_wrap flex_column">
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                채팅 예약 상담 시 자동 응대
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="채팅 예약 상담 시 자동 응대" :value="false" v-model="usingAnsweringChat">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="채팅 예약 상담 시 자동 응대" :value="true" v-model="usingAnsweringChat">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                일반 텍스트 상담 시 자동 응대
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="일반 텍스트 상담 시 자동 응대" :value="false" v-model="usingGeneralChat">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="일반 텍스트 상담 시 자동 응대" :value="true" v-model="usingGeneralChat">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원 운영시간 내 메시지
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="병원 운영시간 내 메시지" :disabled="!usingAutomation" :value="false" v-model="usingBusinessHourMessage">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="병원 운영시간 내 메시지" :disabled="!usingAutomation" :value="true" v-model="usingBusinessHourMessage">사용
                    </label>
                </div>
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" :disabled="!usingBusinessHourMessage" v-model="businessHourMessage"></textarea>
                <small class="red_txt" v-if="!isValidBusinessHourMessage">내용을 입력해 주세요.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원 운영시간 외 메시지
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="병원 운영시간 외 메시지" :disabled="!usingAutomation" :value="false" v-model="usingNonBusinessHourMessage">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="병원 운영시간 외 메시지" :disabled="!usingAutomation" :value="true" v-model="usingNonBusinessHourMessage">사용
                    </label>
                </div>
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" :disabled="!usingNonBusinessHourMessage" v-model=nonBusinessHourMessage></textarea>
                <small class="red_txt" v-if="!isValidNonBusinessHourMessage">내용을 입력해 주세요.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                자동 응대 대기시간
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_5">고객이 채팅을 보낸 후
                    <select name="timer" id="" :disabled="!usingAutomation" v-model="interval" style="width: 60px;">
                        <option :value="1">1</option>
                        <option :value="5">5</option>
                        <option :value="10">10</option>
                        <option :value="15">15</option>
                        <option :value="20">20</option>
                        <option :value="25">25</option>
                        <option :value="30">30</option>
                        <option :value="45">45</option>
                        <option :value="60">60</option>
                    </select>
                    분이 경과했을 때
                </div>
            </div>
        </li>
        
        <ResponseAutomationList
            v-model="careSchedule"
        />
        <!-- </li> -->
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" :disabled="!canSave || !auth.v2HospitalManagement_w" @click="action_Save">저장</button>
        </li>
    </ul>
</template>


<script>
import ResponseAutomationList from './ResponseAutomationList.vue'

export default {
    components: { ResponseAutomationList },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            settingID: undefined,
            usingAnsweringChat: false,
            usingGeneralChat: false,
            usingBusinessHourMessage: false,
            usingNonBusinessHourMessage: false,
            businessHourMessage: undefined,
            nonBusinessHourMessage: undefined,
            interval: undefined,
            careSchedule: [],
        }
    },
    watch: {
        isShow: function(newValue) {
            if (newValue == false) { return }
            this.loadAnsweringChatSetting()
        },
        usingAutomation: function(newValue) {
            if (newValue == false) {
                this.interval = '-'
                this.usingBusinessHourMessage = false
                this.usingNonBusinessHourMessage = false
            }
        }
    },
    computed: {
        hospitalID: function() {
            return this.hospital?._id ?? undefined
        },

        isValidBusinessHourMessage: function() {
            if (this.usingBusinessHourMessage == true) {
                if (this.businessHourMessage == undefined || this.businessHourMessage == '') { return false }
            }
            return true
        },
        isValidNonBusinessHourMessage: function() {
            if (this.usingNonBusinessHourMessage == true) {
                if (this.nonBusinessHourMessage == undefined || this.nonBusinessHourMessage == '') { return false }
            }
            return true
        },
        usingAutomation: function() {
            return this.usingGeneralChat || this.usingAnsweringChat
        },

        canSave: function() {
            if (!this.isValidBusinessHourMessage || !this.isValidNonBusinessHourMessage) { return false }
            if (this.usingAutomation && this.careSchedule.length == 0) { return false }
            return true
        }
    },
    methods: {
        // MARK: - Network
        async loadAnsweringChatSetting() {
            if (this.hospitalID == undefined) { return }

            let result = await this.API_V3.AnsweringChatSetting.findOne({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let setting = result.data.data
            console.log(setting)
            
            this.settingID = setting._id ?? undefined
            this.usingAnsweringChat = setting.usingAnsweringChat ?? false
            this.usingGeneralChat = setting.usingGeneralChat ?? false
            this.usingBusinessHourMessage = setting.usingBusinessHourMessage ?? false
            this.usingNonBusinessHourMessage = setting.usingNonBusinessHourMessage ?? false
            this.businessHourMessage = setting.businessHourMessage ?? undefined
            this.nonBusinessHourMessage = setting.nonBusinessHourMessage ?? undefined
            this.interval = setting.interval ?? undefined
            console.log(this.interval)
            this.careSchedule = setting.careSchedule ?? []
        },
        async updateAnsweringChatSetting() {
            let model = {
                usingAnsweringChat: this.usingAnsweringChat,
                usingGeneralChat: this.usingGeneralChat,
                usingBusinessHourMessage: this.usingBusinessHourMessage,
                usingNonBusinessHourMessage: this.usingNonBusinessHourMessage,
                businessHourMessage: this.businessHourMessage,
                nonBusinessHourMessage: this.nonBusinessHourMessage,
                interval: this.interval,
                careSchedule: this.careSchedule,
            }
            let result = undefined
            if (this.settingID == undefined) {
                result = await this.API_V3.AnsweringChatSetting.create(model)
            }
            else {
                model._id = this.settingID
                result = await this.API_V3.AnsweringChatSetting.update(model)
            }
            if (result?.data?.code != 0) {
                alert(result?.data?.message)
                return
            }
            this.loadAnsweringChatSetting()
        },

        // MARK: - Actions
        action_Save() {
            if (!this.canSave) { return }
            // TODO: Save
            this.updateAnsweringChatSetting()
        }
    }
}
</script>