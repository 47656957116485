<template>
    <tr>
        <td>
            {{ userName }}
        </td>
        <td>
            {{ createdAtDate }}<span class="mgl_5">{{ createdAtTime }}</span>
        </td>
        <td>
            <small class="service_status" :class="statusClass">{{ statusTitle }}</small>
        </td>
        <td>
            {{ content }}
        </td>
        <td>
            <a
                v-for="(file, index) in files"
                :key="index"
                :href="filePath" target="_blank"
            >{{ originalFileName }}</a>
        </td>  
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        comment: {
            type: Object,
            default: () => {return {}}
        }
    },
    computed: {
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG', class: 'lblue'},
                {name: '검수요청',  key: 'REQ', class: 'lgray'},
                {name: '반려',      key: 'REJ', class: 'lred'},
                {name: '차단',      key: 'STP', class: 'lred'},
                {name: '발송전',    key: 'RDY', class: 'lgreen'},
                {name: '정상',      key: 'ACT', class: 'lgreen'},
                {name: '휴면',      key: 'DMT', class: 'lgray'},
                {name: '차단',      key: 'BLK', class: 'lred'},
                {name: '승인',      key: 'APR', class: 'lgreen'},
                {name: '-',        key: undefined, class: 'lgray'}
            ]
        },
        userName: function() { return this.comment?.userName ?? '-' },
        createdAtDate: function() {
            if (this.comment?.createdAt == undefined) { return '-' }
            return moment(this.comment?.createdAt).format('YYYY-MM-DD')
        },
        createdAtTime: function() {
            if (this.comment?.createdAt == undefined) { return '-' }
            return moment(this.comment?.createdAt).format('HH:mm')
        },
        content: function() { return this.comment?.content ?? '-' },
        status: function() {
            let status = this.comment?.status ?? '-'
            return this.statusKind.find( (s) => s.key == status )
        },
        statusTitle: function() { return this.status?.name },
        statusClass: function() { return this.status?.class },
        files: function() { return this.comment?.attachment ?? [] },
    }
}
</script>