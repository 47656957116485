<template>
    <div class="flex_aic_spb gap_20">
        <div class="flex_aic gap_10">
            <select name="요일" id="" :disabled="!isEnable" style="width: 60px;" v-model="dayIndex">
                <option value="1">월</option>
                <option value="2">화</option>
                <option value="3">수</option>
                <option value="4">목</option>
                <option value="5">금</option>
                <option value="6">토</option>
                <option value="0">일</option>
            </select>
            <div class="flex_aic">
                <select name="시작 시" id="" :disabled="!isEnable" style="width: 70px;" v-model="startHour">
                    <option value="0">00시</option>
                    <option value="1">01시</option>
                    <option value="2">02시</option>
                    <option value="3">03시</option>
                    <option value="4">04시</option>
                    <option value="5">05시</option>
                    <option value="6">06시</option>
                    <option value="7">07시</option>
                    <option value="8">08시</option>
                    <option value="9">09시</option>
                    <option value="10">10시</option>
                    <option value="11">11시</option>
                    <option value="12">12시</option>
                    <option value="13">13시</option>
                    <option value="14">14시</option>
                    <option value="15">15시</option>
                    <option value="16">16시</option>
                    <option value="17">17시</option>
                    <option value="18">18시</option>
                    <option value="19">19시</option>
                    <option value="20">20시</option>
                    <option value="21">21시</option>
                    <option value="22">22시</option>
                    <option value="23">23시</option>
                </select>
                <select name="시작 분" id="" :disabled="!isEnable" style="width: 70px;" v-model="startMinute">
                    <option value="0">00분</option><option value="1">01분</option>
                    <option value="2">02분</option><option value="3">03분</option>
                    <option value="4">04분</option><option value="5">05분</option>
                    <option value="6">06분</option><option value="7">07분</option>
                    <option value="8">08분</option><option value="9">09분</option>
                    <option value="10">10분</option><option value="11">11분</option>
                    <option value="12">12분</option><option value="13">13분</option>
                    <option value="14">14분</option><option value="15">15분</option>
                    <option value="16">16분</option><option value="17">17분</option>
                    <option value="18">18분</option><option value="19">19분</option>
                    <option value="20">20분</option><option value="21">21분</option>
                    <option value="22">22분</option><option value="23">23분</option>
                    <option value="24">24분</option><option value="25">25분</option>
                    <option value="26">26분</option><option value="27">27분</option>
                    <option value="28">28분</option><option value="29">29분</option>
                    <option value="30">30분</option><option value="31">31분</option>
                    <option value="32">32분</option><option value="33">33분</option>
                    <option value="34">34분</option><option value="35">35분</option>
                    <option value="36">36분</option><option value="37">37분</option>
                    <option value="38">38분</option><option value="39">39분</option>
                    <option value="40">40분</option><option value="41">41분</option>
                    <option value="42">42분</option><option value="43">43분</option>
                    <option value="44">44분</option><option value="45">45분</option>
                    <option value="46">46분</option><option value="47">47분</option>
                    <option value="48">48분</option><option value="49">49분</option>
                    <option value="50">50분</option><option value="51">51분</option>
                    <option value="52">52분</option><option value="53">53분</option>
                    <option value="54">54분</option><option value="55">55분</option>
                    <option value="56">56분</option><option value="57">57분</option>
                    <option value="58">58분</option><option value="59">59분</option>
                </select>
            </div>
            ~
            <div class="flex_aic">
                <select name="종료 시" id="" :disabled="!isEnable" style="width: 70px;" v-model="endHour">
                    <option value="0">00시</option>
                    <option value="1">01시</option>
                    <option value="2">02시</option>
                    <option value="3">03시</option>
                    <option value="4">04시</option>
                    <option value="5">05시</option>
                    <option value="6">06시</option>
                    <option value="7">07시</option>
                    <option value="8">08시</option>
                    <option value="9">09시</option>
                    <option value="10">10시</option>
                    <option value="11">11시</option>
                    <option value="12">12시</option>
                    <option value="13">13시</option>
                    <option value="14">14시</option>
                    <option value="15">15시</option>
                    <option value="16">16시</option>
                    <option value="17">17시</option>
                    <option value="18">18시</option>
                    <option value="19">19시</option>
                    <option value="20">20시</option>
                    <option value="21">21시</option>
                    <option value="22">22시</option>
                    <option value="23">23시</option>
                </select>
                <select name="종료 분" id="" :disabled="!isEnable" style="width: 70px;" v-model="endMinute">
                    <option value="0">00분</option><option value="1">01분</option>
                    <option value="2">02분</option><option value="3">03분</option>
                    <option value="4">04분</option><option value="5">05분</option>
                    <option value="6">06분</option><option value="7">07분</option>
                    <option value="8">08분</option><option value="9">09분</option>
                    <option value="10">10분</option><option value="11">11분</option>
                    <option value="12">12분</option><option value="13">13분</option>
                    <option value="14">14분</option><option value="15">15분</option>
                    <option value="16">16분</option><option value="17">17분</option>
                    <option value="18">18분</option><option value="19">19분</option>
                    <option value="20">20분</option><option value="21">21분</option>
                    <option value="22">22분</option><option value="23">23분</option>
                    <option value="24">24분</option><option value="25">25분</option>
                    <option value="26">26분</option><option value="27">27분</option>
                    <option value="28">28분</option><option value="29">29분</option>
                    <option value="30">30분</option><option value="31">31분</option>
                    <option value="32">32분</option><option value="33">33분</option>
                    <option value="34">34분</option><option value="35">35분</option>
                    <option value="36">36분</option><option value="37">37분</option>
                    <option value="38">38분</option><option value="39">39분</option>
                    <option value="40">40분</option><option value="41">41분</option>
                    <option value="42">42분</option><option value="43">43분</option>
                    <option value="44">44분</option><option value="45">45분</option>
                    <option value="46">46분</option><option value="47">47분</option>
                    <option value="48">48분</option><option value="49">49분</option>
                    <option value="50">50분</option><option value="51">51분</option>
                    <option value="52">52분</option><option value="53">53분</option>
                    <option value="54">54분</option><option value="55">55분</option>
                    <option value="56">56분</option><option value="57">57분</option>
                    <option value="58">58분</option><option value="59">59분</option>
                </select>
            </div>
        </div>
        <div class="flex_aic">
            <button class="btn_s btn_blue_line" @click="action_Add">+ 추가</button>
            <button class="btn_s btn_gray_line" @click="action_Remove" :disabled=!isEnable>- 삭제</button>
        </div>
    </div>
</template>


<script>
const moment = require('../../../../../../../../util/MPMoment')

export default {
    props: {
        index: Number,
        schedule: Object,
        isEnable: {
            type: Boolean,
            default: () => true,
        }
    },
    data() {
        return {
            dayIndex: '1',
            startedAt: undefined,
            endedAt: undefined,

            startHour: '0',
            startMinute: '0',
            endHour: '1',
            endMinute: '0',
        }
    },
    watch: {
        schedule: {
            immediate: true,
            handler: function(newValue) {
                if (this.isEnable == false) {
                    this.dayIndex = '-'
                    this.startHour = '-'
                    this.startMinute = '-'
                    this.endHour = '-'
                    this.endMinute = '-'
                    return
                }
                let s = newValue?.startedAt ? moment(newValue.startedAt) : moment(0).startOf('days').add(10, 'hours')
                let e = newValue?.endedAt ? moment(newValue.endedAt) : moment(0).startOf('days').add(20, 'hours')
                this.startHour = s.format('H')
                this.startMinute = s.format('m')
                this.endHour = e.format('H')
                this.endMinute = e.format('m')

                this.dayIndex = newValue?.dayIndex ?? '1'
            }
        },
        dayIndex: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateSchedule()
            }
        },
        startedAt: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateSchedule()
            }
        },
        endedAt: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateSchedule()
            }
        },
        startHour: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.startedAt = moment(0).startOf('days').add(newValue, 'hours').add(this.startMinute, 'minutes').format()
                this.updateSchedule()
            }
        },
        startMinute: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.startedAt = moment(0).startOf('days').add(this.startHour, 'hours').add(newValue, 'minutes').format()
                this.updateSchedule()
            }
        },
        endHour: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.endedAt = moment(0).startOf('days').add(newValue, 'hours').add(this.endMinute, 'minute').format()
                this.updateSchedule()
            }
        },
        endMinute: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.endedAt = moment(0).startOf('days').add(this.endHour, 'hours').add(newValue, 'minute').format()
                this.updateSchedule()
            }
        }
    },
    computed: {},
    methods: {
        action_Add() {
            this.$emit('addSchedule', this.index)
        },
        action_Remove() {
            this.$emit('removeSchedule', this.index)
        },
        // MARK: - Emit
        updateSchedule() {
            this.$emit('updateSchedule', {
                dayIndex: this.dayIndex,
                startedAt: this.startedAt,
                endedAt: this.endedAt,
                index: this.index
            })
        }
    }
}
</script>