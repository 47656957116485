<template>
    <li class="settings_list flex_spb">
        <div class="settings_list_title">
            앱 가입 시 채팅(신규)<small class="red_txt">*</small>
            <div class="title_description">환자 가입 시 채팅방에 자동 전송되는 채팅 내용으로 고객이 사용하는 소프트웨어 [병원설정] > [프로필설정]에 노출되는 첫채팅 문구와 동일하며, 해당 값을 변경할 경우 소프트웨어에서도 변경되어 보임.
            <br>
            <br>
            [치환문자]<br>
            <span v-pre>{{병원명}} 1회 이상</span><br>
            <span v-pre>{{고객명}} 1회 이상</span><br>
            </div>
        </div>
        <div class="settings_list_option flex_aic">
            <table class="table_default">
                <thead>
                    <tr>
                        <th width="90">순서</th>
                        <th width="110">Type</th>
                        <th width="">Content</th>
                        <th width="70">삭제</th>
                    </tr>
                </thead>
                <BasicOptionsInitialChatListItem
                    v-for="(chat, index) in chats"
                    :key="index"
                    :index="index"
                    :chat="chat"
                    :chatCount="chats.length"
                    @deleteChat="deleteChat"
                    @up="arrow_Up"
                    @down="arrow_Down"
                    @updateChat="updateChat"
                />
                <tr>
                    <td colspan="4">
                        <div class="flex_aic_fle"><button class="add_btn btn_gray_line" @click="action_Create">추가</button></div>
                    </td>
                </tr>
            </table>
        </div>
    </li>
</template>


<script>
import BasicOptionsInitialChatListItem from './BasicOptionsInitialChatListItem.vue'

export default {
    components: {
        BasicOptionsInitialChatListItem
    },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            chats: [],
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                this.chats = [...newValue]
            }
        },
    },
    computed: {},
    methods: {
        // MARK: - Actions
        action_Create() {
            this.chats.push({})
        },

        // MARK: - Emits
        arrow_Up(index) {
            let objects = this.chats.splice(index, 1)
            this.chats.splice(index-1, 0, ...objects)
            this.$emit('input', this.chats)
        },
        arrow_Down(index) {
            let objects = this.chats.splice(index, 1)
            this.chats.splice(index+1, 0, ...objects)
            this.$emit('input', this.chats)
        },
        deleteChat(index) {
            this.chats.splice(index, 1)
            this.$emit('input', this.chats)
        },
        updateChat(chat) {
            let index = chat.index
            delete chat.index
            this.chats.splice(index, 1, chat)

            this.$emit('input', this.chats)
        }
    },
}
</script>