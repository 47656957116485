<template>
    <tr>
        <td>{{ hospitalName }}</td>
        <td>{{ pf_id }}</td>
        <td>
            <span class="day_list">
                <span v-if="possibleDays[0]==true">월</span>
                <span v-if="possibleDays[0]==true">화</span>
                <span v-if="possibleDays[0]==true">수</span>
                <span v-if="possibleDays[0]==true">목</span>
                <span v-if="possibleDays[0]==true">금</span>
                <span v-if="possibleDays[0]==true">토</span>
                <span v-if="possibleDays[0]==true">일</span>
            </span> 
            <span class="day_time">{{ possibleTimes }}</span>
        </td>
        <td class="txt_center">
            <small>{{ isLinked }}</small>
        </td>
        <td>
            <button class="btn_s btn_blue_line w_full" @click="action_Select">선택</button>
        </td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        history: {
            type: Object,
            default: () => { return {} }
        }
    },
    computed: {
        hospitalName: function() {
            if (this.history.hospitalName) { return this.history.hospitalName }
            if (this.history.tempHospitalName) { return this.history.tempHospitalName }
            return '-'
        },
        pf_id: function() {
            return this.history.searchableChannelId ?? '-'
        },
        consultationInformation: function() {
            return this.history.consultationInformation
        },
        possibleDays: function() {
            let {mondayAvailable, tuesdayAvailable, wednesdayAvailable, thursdayAvailable, fridayAvailable, saturdayAvailable, sundayAvailable} = this.consultationInformation
            return [mondayAvailable, tuesdayAvailable, wednesdayAvailable, thursdayAvailable, fridayAvailable, saturdayAvailable, sundayAvailable]
        },
        possibleTimes: function() {
            let {consultationStartTime, consultationEndTime} = this.consultationInformation
            let s = consultationStartTime ? moment(consultationStartTime).format('HH:mm') : '-'
            let e = consultationEndTime ? moment(consultationEndTime).format('HH:mm') : '-'
            return s + '~' + e
        },
        createdAt: function() {
            return moment(this.history.createdAt).format('YYYY-MM-DD')
        },
        isLinked: function() { return this.history.isLinked },
    },
    methods: {
        action_Select() {
            this.$emit('selectConsultHistory', this.history)
        }
    }
}
</script>