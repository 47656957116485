<template>
    <div class="snb">
        <div class="snb_title">연동 관리</div>
        <a @click="action_ALManagement" class="snb_menu" :class="{ on: $router.currentRoute.name == 'ALManagement' }">전능 연동 정보 관리</a>
        <a @click="action_FriendTalk" class="snb_menu" :class="{ on: $router.currentRoute.name == 'KakaoFriendTalk' }">알림톡/친구톡 프로필 관리</a>
        <a @click="action_Consult" class="snb_menu" :class="{ on: $router.currentRoute.name == 'KakaoConsult' }">상담톡 프로필 관리</a>
    </div>
</template>


<script>
export default {
    methods: {
        action_ALManagement: function() {
            this.$router.push({name: 'ALManagement'}).catch()
        },
        action_FriendTalk: function() {
            this.$router.push({name: 'KakaoFriendTalk'}).catch()
        },
        action_Consult: function() {
            this.$router.push({name: 'KakaoConsult'}).catch()
        }
    }
}
</script>

<style scoped></style>