<template>
    <li class="tag_template_list flex_aic_spb">
        <div class="flex_aic gap_5">
            <label class="flex_aic checkbox_wrap">
                <input type="checkbox" name="치료태그" id="" v-model="isSelected" @change="changeIsSelected">
                <b class="tag_name ellipsis">
                    {{ categoryName }}
                </b>
            </label>
        </div>
        <small class="tag_memo ellipsis">{{ description }}</small>
        <div class="flex_aic gap_5">
            <small class="lgray_txt">{{ date }}</small>
            <small class="lgray_txt">{{ time }}</small>
            <small class="lgray_txt">{{ status }}</small>
        </div>
    </li>
</template>


<script>
const moment = require('../../../../../../../../util/MPMoment')

export default {
    props: {
        index: Number,
        item: Object
    },
    data() {
        return {
            isSelected: false,
        }
    },
    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.isSelected = newValue.isSelected ?? false
            }
        }
    },
    computed: {
        categoryName: function() {
            return this.item?.categoryName ?? '-'
        },
        description: function() {
            return this.item.description ?? '-'
        },
        dateTime: function() {
            if (this.item?.modifierID == undefined) { return moment(this.item?.createdAt) }
            else { return moment(this.item?.updatedAt) }
        },
        date: function() {
            return this.dateTime.format('YYYY-MM-DD')
        },
        time: function() {
            return this.dateTime.format('HH:mm')
        },
        status: function() {
            if (this.item?.modifierID == undefined) { return '생성' }
            else { return '수정' }
        }
    },
    methods: {
        changeIsSelected() {
            this.$emit('updateSelected', {
                ...this.item,
                isSelected: this.isSelected
            })
        }
    },
}
</script>