import MPAWS from '@/util/MPAWS'
import store from '@/store/index'

class MPFileUploadController {
    constructor() {
        this.isUpload = false
        this.files = []
    }

    getFileExt(file) {
        let fileName = file.name
        let array = fileName.split('.')
        return array[array.length - 1]
    }

    async getImageFileWidthHeight(file) {
        return new Promise(function (resolve, reject) {
            let f = file
            let reader = new FileReader()
            reader.onload = function (e) {
                var img = new Image()
                img.src = e.target.result
                console.log(e)
                img.onload = function () {
                    var w = this.width
                    var h = this.height
                    let size = {
                        width: w,
                        height: h
                    }
                    resolve(size)
                }
                img.onerror = function (e) {
                    reject(e)
                }
            }
            reader.readAsDataURL(f)
        })
    }

    async uploadFiles(files, flags, callback, onlyAWS=false) {
        try {
            this.files = files
            this.isUpload = true

            let limitSize = 30 * 1024 * 1024
            for (let i = 0; i < this.files.length; i++) {
                let file = this.files[i]
                if (file.size > limitSize && callback != undefined) {
                    this.isUpload = false
                    callback({
                        code: -1,
                        data: '파일 용량이 30MB를 초과하여 파일을 전송할 수 없습니다.',
                        message: '파일 용량이 30MB를 초과하여 파일을 전송할 수 없습니다.'
                    })
                    store.dispatch('closeUploadPopup')
                    return
                }
            }

            let imageFiles = []
            let otherFiles = []

            let imageExtensions = ['JPEG', 'jpeg', 'JFIF', 'JPG', 'jpg', 'Exif', 'gif', 'GIF', 'BMP', 'png', 'PNG', 'PPM', 'PGM', 'PBM', 'PNM', 'tif', 'tiff']

            for (let i = 0; i < this.files.length; i++) {
                let isImage = false

                let file = this.files[i]
                let ext = this.getFileExt(file)
                for (let j = 0; j < imageExtensions.length; j++) {
                    if (ext.toLowerCase() == imageExtensions[j].toLowerCase()) {
                        isImage = true
                    }
                }

                if (isImage == true) {
                    imageFiles.push(file)
                } else {
                    otherFiles.push(file)
                }
            }

            let sizes = []
            for (let i = 0; i < imageFiles.length; i++) {
                let file = imageFiles[i]
                let size = await this.getImageFileWidthHeight(file)
                sizes.push(size)
            }

            let results = []

            if (imageFiles.length > 0) {
                if (onlyAWS == false) {
                    let imageResult = await MPAWS.uploadFiles(imageFiles, sizes, 'image/*', 300, store.state.user._id, flags)
                    results.push(imageResult)
                }
                else {
                    let imageResult = await MPAWS.uploadToAWS(imageFiles, sizes, 'image/*', 300, store.state.user._id, flags)
                    results.push(imageResult)
                }
            }

            for (let i = 0; i < otherFiles.length; i++) {
                let file = otherFiles[i]
                if (onlyAWS == false) {
                    let result = await MPAWS.uploadFiles([file], [{}], '*', 500, store.state.user._id, flags)
                    results.push(result)
                }
                else {
                    let result = await MPAWS.uploadToAWS([file], [{}], '*', 500, store.state.user._id, flags)
                    results.push(result)
                }
            }
            this.isUpload = false
            callback({
                code: 0,
                data: results,
                message: 'done'
            })
        } catch (err) {
            console.log(err, 'image upload failed')
            this.isUpload = false
        }
    }
}

export const FileUploadController = new MPFileUploadController()
