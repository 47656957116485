<template>
    <div class="cc">
        <SubNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>


<script>
import SubNavigation from './AdminAccountSubNavigation.vue'
export default {
    components: { SubNavigation }
}
</script>