<template>
    <ul v-if="isShow" class="settings_wrap">
        <LoadingIndicate :isLoading="isLoading"/>
        <div class="flex_column gap_5 m">
            <span class="large_txt" style="font-size: 18px;">
                서비스 상태 변경
            </span>
            <small class="dgray_txt">
                대기: 서비스는 시작하지 않았으나 병원이 생성된 상태<br>
                진행: 서비스를 시작하였음<br>
                종료: 서비스를 종료하였음<br>
            </small>
        </div>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                상태<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="상태" :value="'대기'" v-model="status">대기
                </label>
                <label class="flex_aic">
                    <input type="radio" name="상태" :value="'진행'" v-model="status">진행
                </label>
                <label class="flex_aic">
                    <input type="radio" name="상태" :disabled="true">종료
                </label>
            </div>
        </li>
        <!-- 상태 종료 선택했을 때 나타남 -->
        <!-- <li class="settings_list flex_spb">
            <div class="settings_list_title">
                이용중단 옵션<small class="red_txt">*</small>
                <small class="dgray_txt mgt_10" style="font-weight: 400;">모든 환자의 서비스를 중단합니다.
                    해당 옵션을 사용하여 중단한 경우, 수정이 불가합니다.</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic checkbox_wrap">
                    <input type="checkbox" name="중단" id="">
                    <span>모든 환자의 서비스를 중단합니다.</span>
                </label>
            </div>
        </li> -->
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원 ID<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <input type="text" placeholder="병원 ID를 입력해 주세요." v-model="hospitalIDString">
                <small class="red_txt" v-if="isError">올바르지 않은 병원 ID 입니다.</small>
            </div>
        </li>
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" :disabled="!auth.v2HospitalState_w" @click="updateStatus">저장</button>
        </li>
    </ul>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue';
export default {
    components: { LoadingIndicate },
    props: {
        isShow: Boolean,
        hospital: { type: Object, default: () => { return {} }},
    },
    data() {
        return {
            isLoading: true,

            serviceSetting: undefined,
            status: undefined,

            hospitalIDString: undefined,
            needCheckError: false,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                this.needCheckError = false
                if (newValue == false) { return }
                this.loadServiceSetting()
            }
        }
    },
    computed: {
        isError: function() {
            return this.needCheckError == true ? this.hospitalID?._id == this.hospitalIDString : false
        }
    },
    methods: {
        // MARK: - Network
        async loadServiceSetting() {
            let hospitalID = this.hospital._id
            if (hospitalID == undefined) { return }
            this.isLoading = true
            let result = await this.API_V3.ServiceSetting.findOne({hospitalID: hospitalID})
            this.isLoading = false
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.serviceSetting = result.data.data
            this.status = this.serviceSetting?.status
        },
        async updateStatus() {
            this.needCheckError = true
            if (this.isError == true) { return }
            let model = {
                _id: this.serviceSetting._id,
                status: this.status
            }
            this.isLoading = true
            let result = await this.API_V3.ServiceSetting.update(model)
            this.isLoading = false
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadServiceSetting()
        }
    }
}
</script>