<!-- 기본 템플릿 불러오기 팝업에서 선택 클릭 시 나타나는 팝업 -->
<template>
    <div class="basic_template_pop_wrap">
        <div class="pop_title">
            <span class="title">템플릿 등록</span>
            <a class="mgl_auto" @click="action_ClosePopup">
                <v-icon :size="24">mdi-close</v-icon>
            </a>
        </div>
        <div class="pop_cont">
            <div class="template_tag_wrap">
                <div class="form_list">
                    <div class="title" style="width: 120px">
                        자동화명<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <input type="text" class="input_s w_full" v-model="name" maxlength="50">
                    </div>
                </div>
                <!-- 치료태그 설정 -->
                <TreatmentTagSetting
                    :hospital="hospital"
                    :selectedTagIDs="selectedTagIDs"
                    @setSelectedTagIDs="action_SelectTagIDs"
                />

                <!-- 국적 설정 -->
                <NationalitySetting
                    :selectedCountryIDs="selectedCountryIDs"
                    @setSelectedCountryIDs="action_SelectCountryIDs"
                />
                <div class="flex_aic_jcc mgt_15">
                    <button class="btn_gray_line btn_s" @click="action_PreviousStep">이전</button>
                    <button class="btn_blue_line btn_s" @click="action_NextStep" :disabled="isDisabled">다음</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NationalitySetting
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BaseConfiguration/NationalitySetting.vue'
import TreatmentTagSetting
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BaseConfiguration/TreatmentTagSetting.vue'

export default {
    name: 'BaseConfiguration',
    components: { TreatmentTagSetting, NationalitySetting },
    props: {
        algorithmName: String,
        hospital: Object,
        selectedTagIDs: Array,
        selectedCountryIDs: Array,
    },
    data() {
        return {
            name: ''
        }
    },
    watch: {
        algorithmName: {
            immediate: true,
            handler: function(newValue) {
                this.name = newValue
            }
        },
        name(newValue) {
            this.$emit('update:algorithmName', newValue)
        }
    },
    computed: {
        isDisabled: function() {
            return !(this.selectedTagIDs && this.selectedTagIDs.length > 0)

        }
    },
    methods: {
        action_ClosePopup() {
            this.$emit('closePopup')
        },
        action_PreviousStep() {
            this.$emit('goToPreviousDept')
        },
        action_NextStep() {
            this.$emit('goToNextDept')
        },
        action_SelectTagIDs(ids) {
            this.$emit('setSelectedTagIDs', ids)
        },
        action_SelectCountryIDs(ids) {
            this.$emit('setSelectedCountryIDs', ids)
        }
    }
}

</script>

