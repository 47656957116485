<template>
    <div class="cc">
        <V2HospitalSettingSubNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>


<script>
import V2HospitalSettingSubNavigation from './V2HospitalSettingSubNavigation.vue'

export default {
    components: { V2HospitalSettingSubNavigation }
}
</script>