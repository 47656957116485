<template>
    <div class="mgt_15">
        <b class="dgray_txt">알림톡 기본 설정</b>
        <table class="table_default small">
            <colgroup>
                <col width="15%">
                <col width="25%">
                <col width="15%">
                <col width="">
            </colgroup>
            <tr>
                <th>발신그룹</th>
                <td>
                    <select class="input_s" v-model="senderKey">
                        <option :value="undefined">--선택--</option>
                        <option
                            v-for="(group, index) in kakaoGroups" :key="index"
                            :value="group.groupKey ?? group.senderKey"
                        >{{ group.name }}</option>
                    </select>
                </td>
                <th>템플릿코드</th>
                <td>
                    <input type="text" class="input_s" placeholder="1~25자. 예) alimtalk_001" maxlength="25" v-model="templateCode">
                </td>
            </tr>
            <tr>
                <th>템플릿명</th>
                <td><input type="text" class="input_s" placeholder="1~100자" maxlength="100" v-model="templateName"></td>
                <th>카테고리</th>
                <td>
                    <select class="input_s" v-model="selectedParentCategory">
                        <option :value="undefined">--선택--</option>
                        <option
                            v-for="(parent, index) in kakaoParentCategories" :key="index"
                            :value="parent.groupName"
                        >{{ parent.groupName }}
                        </option>
                    </select>
                    <select class="input_s" v-model="selectedCategoryCode">
                        <option :value="undefined">--선택--</option>
                        <option
                            v-for="(category, index) in kakaoChildCategory" :key="index"
                            :value="category.code"
                        >{{ category.name }}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th>강조유형</th>
                <td>
                    <!-- 이건 disabled 상태로 못 바꿈-->
                    <select class="input_s" name="강조유형" id="" disabled>
                        <option value="선택 안 함" selected>선택 안 함</option>
                        <option value="강조표기형">강조표기형</option>
                        <option value="이미지형">이미지형</option>
                        <option value="아이템리스트형">아이템리스트형</option>
                    </select>
                </td>
                <th>메시지 유형</th>
                <td>
                    <select class="input_s" name="메시지 유형" id="" disabled>
                        <option value="기본형" selected>기본형</option>
                        <option value="부가정보형">부가정보형</option>
                        <option value="채널추가형">채널추가형</option>
                        <option value="복합형">복합형</option>
                    </select>
                </td>
            </tr>
        </table>
        <!-- 새로만들땐 무조건 표시, 수정으로 들어온 경우 메시지 내용을 변경했을 때 표시 -->
        <label class="check_wrap mgt_10">
            <input type="checkbox" v-model="registKakaoTemplate">저장시 알림톡 검수 신청하기
            <span class="checkmark"></span>
        </label>
    </div>
</template>


<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        kakaoGroups: {
            type: Array,
            default: () => []
        },
        kakaoCategories: {
            type: Array,
            default: () => []
        },
        kakaoProfiles: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            senderKey: undefined,
            templateName: undefined,
            templateCode: undefined,
            selectedParentCategory: undefined,
            selectedCategoryCode: undefined,
            registKakaoTemplate: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler: function(newValue, oldValue) {
                console.log(newValue)
                if (newValue == oldValue) { return }
                this.senderKey = newValue.senderKey
                this.templateName = newValue.templateName
                this.templateCode = newValue.templateCode
                this.selectedCategoryCode = newValue.categoryCode
                if (this.selectedCategoryCode != undefined) {
                    let category = this.kakaoCategories.find( (c) => c.code == this.selectedCategoryCode)
                    this.selectedParentCategory = category.groupName
                }
                if (newValue.senderKey != undefined && newValue.templateName != undefined) { this.registKakaoTemplate = true }
            }
        },
        registKakaoTemplate: function(newValue) {
            if (newValue == false) {
                this.senderKey = undefined
                this.templateName = undefined
                this.templateCode = undefined
                this.selectedCategoryCode = undefined
                this.selectedParentCategory = undefined
                this.udpateValue()
            }
        },
        senderKey: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.udpateValue()
        },
        templateName: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.udpateValue()
        },
        templateCode: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.udpateValue()
        },
        selectedCategoryCode: function(newValue, oldValue) {
            if (newValue == oldValue) { return }
            this.udpateValue()
        }
    },
    computed: {
        kakaoParentCategories: function() {
            return Array.from(
                this.kakaoCategories.reduce((map, c) => map.set(c.groupName, c), new Map()).values()
            )
        },
        kakaoChildCategory: function() { return this.kakaoCategories.filter((c) => c.groupName == this.selectedParentCategory) },
        kakaoGroupProfiles: function() { return this.kakaoGroups.concat(this.kakaoProfiles) },
        senderKeyType: function() {
            let group = this.kakaoGroups.find( (g) => g.groupKey == this.senderKey)
            let profile = this.kakaoProfiles.find( (p) => p.senderKey == this.senderKey )
            if (group != undefined) { return 'G' }
            if (profile != undefined) { return 'S' }
            return undefined
        }
    },
    methods: {
        // MARK: - Actions
        action_ChangeRegistKakaoTemplate() {
            this.registKakaoTemplate = !this.registKakaoTemplate
        },

        // MARK: - Emit
        udpateValue() {
            this.$emit('input', {
                ...this.value,
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateName: this.templateName,
                templateCode: this.templateCode,
                categoryCode: this.selectedCategoryCode,
                registKakaoTemplate: this.registKakaoTemplate,
            })
        }
    }
}
</script>