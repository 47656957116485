<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>영상 업로드</span>
        </div>
        <div class="h_scroll pd_15">
            <div class="table_title">
                <p class="dgray_txt">
                    총 <span class="black_txt">{{ hTotalCount }}</span
                    >개
                </p>
                <div class="table_title_btn">
                    <input type="text" class="input_s" v-model="keyword" @keyup.enter="loadPageIndex(0)" placeholder="파일제목 검색" />
                    <button class="btn_s btn_blue_line mgl_5" @click="loadPageIndex(0)" @keyup.enter="loadPageIndex(0)">검색</button>
                    <button class="btn btn_s btn_blue" @click="action_ShowCreateHLSVideoPopup" :disabled="!auth.videoContent_w">업로드</button>
                </div>
            </div>
            <div class="table_header_fix">
                <table class="table_default">
                    <thead>
                        <tr>
                            <th width="60px">No.</th>
                            <th>파일 제목</th>
                            <th width="150px">파일 용량</th>
                            <th width="150px">최초 업로드</th>
                            <th width="150px">최종 수정</th>
                            <th width="80px">삭제</th>
                        </tr>
                    </thead>
                    <thead v-if="hData.length == 0">
                        <tr>
                            <td colspan="6">등록된 영상 또는 검색 내용이 없습니다.</td>
                        </tr>
                    </thead>
                    <thead v-if="hData.length > 0">
                        <tr v-for="v in hData" :key="v._id" :class="{ lgray: v.isRemove }">
                            <td>{{ v.seq }}</td>
                            <td>{{ v.title }}</td>
                            <td>{{ getFileSize(v.totalVideoSize) }}MB</td>
                            <td>{{ ISODatetoDate(v.createdAt) }}</td>
                            <td>{{ ISODatetoDate(v.updatedAt) }}</td>
                            <td><button class="btn btn_s btn_gray" @click="action_ShowDeleteHLSVideoPopup(v)" :disabled="!auth.videoContent_w">삭제</button></td>
                        </tr>
                    </thead>
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="hTotalCount"
                v-bind:currentPage="hCurrentPage"
                v-bind:countPerPage="hCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="hChangePage"
            />
        </div>
        <!-- add upload popup -->
        <div v-if="uploadPopup_isShow" class="pop_overlay_bg">
            <div class="video_upload_pop_wrap">
                <!-- loading screen -->
                <div class="loading" v-show="newHLSVideoPrescription.isUploading">
                    <div class="send_progress" ref="progressbar">
                        <div class="send_progress_bar" :style="{ width: newHLSVideoPrescription.uploadPercentage + '%' }"></div>
                        <div>
                            <span class="percentage">{{ newHLSVideoPrescription.uploadCount }} / {{ newHLSVideoPrescription.totalUploadCount }}</span>
                        </div>
                    </div>
                </div>
                <div class="pop_title">
                    <span class="title">영상 업로드</span>
                    <a class="mgl_auto" @click="action_CloseUploadPopup"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont">
                    <div class="form_list">
                        <div class="title">영상 업로드</div>
                        <div class="cont">
                            <input ref="fileInput" type="file" name="선택" webkitdirectory @change="changeFiles" id="" class="input_s w_full" />
                            <button class="btn btn_l btn_gray" v-show="newHLSVideoPrescription.p1080.length" @click="action_Init">초기화</button>
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">동영상 파일명</div>
                        <div class="cont">
                            <input type="text" class="input_s" placeholder="동영상 파일명" v-model="newHLSVideoPrescription.title" />
                        </div>
                    </div>
                    <br />
                    <!-- video folder details -->
                    <div class="" v-if="newHLSVideoPrescription.p1080.length > 0">
                        <div>
                            <br />
                            <p>Total Files</p>
                            <table class="table_default">
                                <tr>
                                    <th width="150px">총 영상 파일 크기 :</th>
                                    <td>{{ getFileSize(newHLSVideoPrescription.totalSize) }} MB</td>
                                    <th width="150px">총 영상 갯수 :</th>
                                    <td>
                                        {{ newHLSVideoPrescription.p1080.length + newHLSVideoPrescription.p720.length + newHLSVideoPrescription.p360.length }}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="150px">1080p 파일 크기 :</th>
                                    <td>{{ getFileSize(newHLSVideoPrescription.p1080_totalSize) }} MB</td>
                                    <th width="150px">영상 갯수 :</th>
                                    <td>{{ newHLSVideoPrescription.p1080.length }}</td>
                                </tr>
                                <tr>
                                    <th width="150px">1080p 영상 길이 :</th>
                                    <td>
                                        약 {{ parseInt(newHLSVideoPrescription.p1080_duration / 60) }}분
                                        {{ parseInt(newHLSVideoPrescription.p1080_duration % 60) }}초
                                    </td>
                                </tr>
                                <tr>
                                    <th width="150px">720p 파일 크기 :</th>
                                    <td>{{ getFileSize(newHLSVideoPrescription.p720_totalSize) }} MB</td>
                                    <th width="150px">영상 갯수 :</th>
                                    <td>{{ newHLSVideoPrescription.p720.length }}</td>
                                </tr>
                                <tr>
                                    <th width="150px">720p 영상 길이 :</th>
                                    <td>
                                        약 {{ parseInt(newHLSVideoPrescription.p720_duration / 60) }}분
                                        {{ parseInt(newHLSVideoPrescription.p720_duration % 60) }}초
                                    </td>
                                </tr>
                                <tr>
                                    <th width="150px">360p 파일 크기 :</th>
                                    <td>{{ getFileSize(newHLSVideoPrescription.p360_totalSize) }} MB</td>
                                    <th width="150px">영상 갯수 :</th>
                                    <td>{{ newHLSVideoPrescription.p360.length }}</td>
                                </tr>
                                <tr>
                                    <th width="150px">360p 영상 길이 :</th>
                                    <td>
                                        약 {{ parseInt(newHLSVideoPrescription.p360_duration / 60) }}분
                                        {{ parseInt(newHLSVideoPrescription.p360_duration % 60) }}초
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <button class="btn btn_l btn_blue" @click="action_Upload">등록</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- edit upload popup -->
    </div>
</template>

<script>
import MPAWS from '@/util/MPAWS'
import moment from '@/util/MPMoment'
import PageController from '@/controller/PageController'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'

const MPM3U = require('@/util/MPM3U')

export default {
    components: { Pagenation },
    data() {
        return {
            //selectedHLSVideo: '',

            keyword: '',
            isSearch: false,
            filter: {},

            // filter: {
            //     keyword: '',
            // },

            hCurrentPage: 0,
            hCountPerPage: 10,
            hTotalCount: 0,
            // totalHLSVideoCount: 0,
            hData: [],
            // hlsvideos: [],

            // HLSVideo upload popup components.
            uploadPopup_isShow: false,
            newHLSVideoPrescription: {
                title: '',
                prescriptionName: '',
                uploadFiles: [],
                p1080: [],
                p720: [],
                p360: [],
                p1080_totalSize: 0,
                p720_totalSize: 0,
                p360_totalSize: 0,
                totalSize: 0,
                totalVideoLength: 0,
                p1080_m3u8: {},
                p720_m3u8: {},
                p360_m3u8: {},

                totalUploadCount: 0,
                uploadCount: 0,
                uploadPercentage: 0,

                isUploading: false
            },
            // uploadPopup_title: '',

            isUploading: false,

            // HLSVideo delete popup components.
            deletePopup_isShow: false
        }
    },
    computed: {},
    watch: {
        hTotalCount: {
            handler: function () {
                // do nothing
            }
        }
    },
    mounted() {
        // this.findHLSVideos()
        // this.countHLSVideos()
        // this.loadHLSVideos(0)
        this.hlsvideoPageController = new PageController(this.API.HLSVideo.find, this.filter)
        this.hlsvideoPageController.countPerPage = this.hCountPerPage
        this.loadPageIndex(this.hCurrentPage)
    },
    methods: {
        // reload data
        initData() {
            this.loadPageIndex(this.hCurrentPage)
        },

        async loadPageIndex(page) {
            this.hCurrentPage = page
            if (this.keyword != '') {
                this.filter.keyword = this.keyword
                this.isSearch = true
            } else {
                delete this.filter.keyword
                this.isSearch = false
            }

            if (!this.isSearch) {
                delete this.hlsvideoPageController.filter.keyword
            } else {
                this.hlsvideoPageController.filter.keyword = this.filter.keyword
            }

            await this.hlsvideoPageController.loadPageIndex(page)
            this.loadTotalHLSVideos()
            this.hData = this.hlsvideoPageController.data
        },

        async loadTotalHLSVideos() {
            if (this.keyword != '') {
                this.filter.keyword = this.keyword
                this.isSearch = true
            } else {
                delete this.filter.keyword
                this.isSearch = false
            }
            let result = await this.API.HLSVideo.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.hTotalCount = result.data.data
        },

        hChangePage(page) {
            this.hCurrentPage = page
            this.loadPageIndex(this.hCurrentPage)
        },

        action_ShowCreateHLSVideoPopup() {
            this.action_Init()
            this.uploadPopup_isShow = true
        },

        // TODO : no edit button for HLSVideo?
        action_ShowEditHLSVideoPopup(video) {
            // set upload popup data
            this.uploadPopup_isShow = true
            this.newHLSVideoPrescription.title = video.title

            console.log(video)
        },

        // TODO : 삭제 팝업
        action_ShowDeleteHLSVideoPopup(video) {
            this.deletePopup_isShow = true
            console.log(video)
        },

        action_CloseUploadPopup() {
            this.action_Init()
            this.uploadPopup_isShow = false
        },

        action_CloseDeletePopup() {
            this.deletePopup_isShow = false
        },

        ////////////////////////////////////////////////////////////////////////////////////
        // related to file upload, copied from nurse_dev

        initParams() {
            this.newHLSVideoPrescription = {
                title: '',
                prescriptionName: '',
                uploadFiles: [],
                p1080: [],
                p720: [],
                p360: [],
                p1080_totalSize: 0,
                p1080_duration: 0,
                p720_totalSize: 0,
                p720_duration: 0,
                p360_totalSize: 0,
                p360_duration: 0,
                totalSize: 0,
                totalVideoLength: 0,
                p1080_m3u8: {},
                p720_m3u8: {},
                p360_m3u8: {},

                totalUploadCount: 0,
                uploadCount: 0,
                uploadPercentage: 0,

                isUploading: false
            }

            let i = this.$refs['fileInput']
            i.value = ''
        },

        changeFiles(event) {
            let files = event.currentTarget.files
            this.setFiles(files)
        },

        async setFiles(files) {
            let p1080 = []
            let p720 = []
            let p360 = []
            let videoTitle = ''
            for (var i = 0; i < files.length; i++) {
                let f = files[i]
                let name = f.name
                let temp_separated_name = name.split('.')
                let temp_separated_path = f.webkitRelativePath.split('/')

                videoTitle = temp_separated_path[0]
                let ext = temp_separated_name[temp_separated_name.length - 1]

                if (ext == 'm3u8') {
                    if (temp_separated_path[1] == 1080) {
                        this.newHLSVideoPrescription.p1080_m3u8.blob = f
                    }
                    if (temp_separated_path[1] == 720) {
                        this.newHLSVideoPrescription.p720_m3u8.blob = f
                    }
                    if (temp_separated_path[1] == 360) {
                        this.newHLSVideoPrescription.p360_m3u8.blob = f
                    }
                }
                if (ext != 'ts') {
                    continue
                }
                if (temp_separated_path[1] == 1080) {
                    p1080.push(f)
                    this.newHLSVideoPrescription.p1080_totalSize += f.size
                } else if (temp_separated_path[1] == 720) {
                    p720.push(f)
                    this.newHLSVideoPrescription.p720_totalSize += f.size
                } else if (temp_separated_path[1] == 360) {
                    p360.push(f)
                    this.newHLSVideoPrescription.p360_totalSize += f.size
                }
            }
            var sortFunc = (l, r) => {
                return l.name.localeCompare(r.name)
            }
            p1080.sort(sortFunc)
            p720.sort(sortFunc)
            p360.sort(sortFunc)

            this.newHLSVideoPrescription.p1080_duration = await this.getVideoDuration(this.newHLSVideoPrescription.p1080_m3u8.blob)
            this.newHLSVideoPrescription.p720_duration = await this.getVideoDuration(this.newHLSVideoPrescription.p720_m3u8.blob)
            this.newHLSVideoPrescription.p360_duration = await this.getVideoDuration(this.newHLSVideoPrescription.p360_m3u8.blob)

            this.newHLSVideoPrescription.totalVideoLength = this.newHLSVideoPrescription.p1080_duration

            this.newHLSVideoPrescription.title = videoTitle
            this.newHLSVideoPrescription.p1080 = p1080
            this.newHLSVideoPrescription.p720 = p720
            this.newHLSVideoPrescription.p360 = p360
            this.newHLSVideoPrescription.totalSize =
                this.newHLSVideoPrescription.p1080_totalSize + this.newHLSVideoPrescription.p720_totalSize + this.newHLSVideoPrescription.p360_totalSize

            this.newHLSVideoPrescription.totalUploadCount = p1080.length + p720.length + p360.length
            this.newHLSVideoPrescription.uploadCount = 0
        },

        async progressFunction(count) {
            this.newHLSVideoPrescription.uploadCount = count
            this.newHLSVideoPrescription.uploadPercentage = (count / this.newHLSVideoPrescription.totalUploadCount) * 100
            this.$forceUpdate()
        },

        async action_Init() {
            this.initParams()
        },

        async readFile(file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader()
                reader.addEventListener('load', (e) => {
                    console.log(e)
                    resolve(reader.result)
                })
                reader.addEventListener('loadend', (e) => {
                    console.log(e)
                    resolve(reader.result)
                })
                reader.addEventListener('error', (e) => {
                    reject(e)
                })
                reader.addEventListener('abort', (e) => {
                    reject(e)
                })
                reader.readAsBinaryString(file)
            })
        },

        async getVideoDuration(m3u8File) {
            let fileString = await this.getM3U8FileString(m3u8File)
            let m3u = new MPM3U(fileString)
            let duration = m3u.getVideoDuration()
            return duration
        },

        async getM3U8FileString(m3u8File) {
            return await this.readFile(m3u8File)
        },

        async parseM3U8(m3u8File, videoFileArray) {
            let fileString = await this.readFile(m3u8File)
            for (let i = 0; i < videoFileArray.length; i++) {
                let f = videoFileArray[i]
                console.log(f)
                fileString = fileString.replaceAll(f.fileName, f.Location)
            }

            return fileString
        },

        async action_Upload() {
            let getAWSArray = function (fileArray) {
                let path_AWS = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/'
                let array_AWS = []
                for (let i = 0; i < fileArray.length; i++) {
                    let f = fileArray[i]
                    let p = path_AWS + f.bucketParam.Key
                    let aws = {
                        fileName: f.name,
                        fileSize: f.size,
                        Key: f.bucketParam.Key,
                        ETag: f.res.ETag,
                        Location: p
                    }
                    array_AWS.push(aws)
                }
                return array_AWS
            }
            let getPathsArray = function (awsArray) {
                let paths = []
                for (let i = 0; i < awsArray.length; i++) {
                    let aws = awsArray[i]
                    paths.push(aws.Key)
                }
                return paths
            }

            this.newHLSVideoPrescription.isUploading = true
            try {
                let result = await MPAWS.uploadHLSVideo(this.newHLSVideoPrescription, this.progressFunction)
                console.log(result)
                let aws_p1080 = getAWSArray(result.p1080)
                let aws_p720 = getAWSArray(result.p720)
                let aws_p360 = getAWSArray(result.p360)

                let model = {}
                model.writerID = this.user._id
                model.p1080_aws = aws_p1080
                model.p720_aws = aws_p720
                model.p360_aws = aws_p360
                model.p1080_paths = getPathsArray(aws_p1080)
                model.p720_paths = getPathsArray(aws_p720)
                model.p360_paths = getPathsArray(aws_p360)
                model.aws = aws_p1080.concat(aws_p720.concat(aws_p360))
                model.paths = model.p1080_paths.concat(model.p720_paths.concat(model.p360_paths))

                model.p1080_totalSize = this.newHLSVideoPrescription.p1080_totalSize
                model.p720_totalSize = this.newHLSVideoPrescription.p720_totalSize
                model.p360_totalSize = this.newHLSVideoPrescription.p360_totalSize
                model.totalVideoSize = this.newHLSVideoPrescription.totalSize
                // convert seconds to miliseconds
                model.totalVideoLength = parseInt(this.newHLSVideoPrescription.totalVideoLength * 1000)
                model.type = 401
                model.title = this.newHLSVideoPrescription.title
                model.isReady = false

                // parse m3u8 file
                try {
                    model.p1080_m3u8_fileString = await this.parseM3U8(this.newHLSVideoPrescription.p1080_m3u8.blob, model.p1080_aws)
                    model.p720_m3u8_fileString = await this.parseM3U8(this.newHLSVideoPrescription.p720_m3u8.blob, model.p720_aws)
                    model.p360_m3u8_fileString = await this.parseM3U8(this.newHLSVideoPrescription.p360_m3u8.blob, model.p360_aws)

                    model = await MPAWS.uploadHLSm3u8(model)
                } catch (err) {
                    console.log(err)
                }

                console.log(model)

                let hlsVideo = await this.API.HLSVideo.create(model)
                console.log(hlsVideo)
                this.initData()
                this.action_CloseUploadPopup()
            } catch (err) {
                console.log(err)
            }
            this.newHLSVideoPrescription.isUploading = false
        },

        // related to file upload END
        ////////////////////////////////////////////////////////////////////////////////////

        // TODO : make these methods below to common function..? + Vue.mixin?
        ISODatetoDate(date) {
            let modifiedDate = moment(date).format('YYYY-MM-DD HH:mm')
            return modifiedDate
        },

        getFileSize(filesize) {
            let convertedFilesize = filesize / 1000 / 1000
            return convertedFilesize.toFixed(2)
        }
    }
}
</script>
