import axios from "axios"
import * as URL from '../../../url'
const headers = {
    'content-type': 'application/json'
}

export const categories = () => {
    return axios({
        method: 'get',
        url: URL.THIRDPARTY_REGIST_KAKAO_CATEGORIES,
        headers: headers
    })
}