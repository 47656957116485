<template>
    <div class="h_scroll acccount_list pd_0 mgt_15" >
        <div class="table_header_fix" >
            <table class="table_default">
                <thead>
                    <tr>
                        <th width="" class="txt_left">이름</th>
                        <th width="120" class="txt_left">휴대폰 번호</th>
                        <th width="" class="txt_left">직책(ID)</th>
                        <th width="65" class="txt_left">권한</th>
                        <th width="75" class="txt_left">기본수신</th>
                        <th width="225" class="txt_left">프로필 사진</th>
                        <th width="145" class="txt_left">수정/삭제</th>
                    </tr>
                </thead>
                <AccountSettingAccountListItem
                    v-for="(doctor, index) in doctors"
                    :key="index"
                    :doctor="doctor"
                    :hospitalID="hospitalID"
                    :serviceSetting="serviceSetting"
                    @loadDatas="loadDatas"
                    @showModifyPopup="showModifyPopup"
                />
            </table>
        </div>
    </div>
</template>


<script>
import AccountSettingAccountListItem from './AccountSettingAccountListItem.vue'

export default {
    components: { AccountSettingAccountListItem },
    props: {
        hospitalID: String,
        doctors: Array,
        authorizationType: Array,
        serviceSetting: Object,
    },
    methods: {
        loadDatas() {
            this.$emit('loadDatas')
        },
        showModifyPopup(doctor) {
            this.$emit('showModifyPopup', doctor)
        }
    }
}
</script>