<template>
    <!-- 추가,수정 버튼 클릭 시 나타나는 페이지 -->
    <div class="alrimtalk_edit_page" v-show="isShow">
        <LoadingIndicate
            :isLoading="isLoading"
        />
        <AlrimtalkMessageProfilePopup
            :isShowSelectProfilePopup="isShowSelectProfilePopup"
            :isShowSelectGroupPopup="isShowSelectGroupPopup"
            @updateSelectedSenderKey="updateSelectedSenderKey"
            @close="closeProfilePopup"
        />
        <!-- 확인 팝업 -->
        <RemoveConfirm
            :isShow="isShowAlertPopup"
            :title="alertTitle"
            :body="alertBody"
            :alertCallback="alertCallback"
        />
        <!-- 수정버튼 클릭 시 나타는 팝업 -->
        <div class="page_title flex_aic_spb">
            <span>알림톡 메시지 수정</span>
            <button class="close_btn large_txt lgray_txt" @click="action_Close">
                <span class="flex_aic"><img src="https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3/20241007113664/Group%201876.png">닫기</span>
            </button>
        </div>
        <div class="alrimtalk_edit_wrap">
            <div class="h_scroll alrimtalk_inspection_history">
                <div class="mgb_15">
                    <span class="inspection_status lgray">심사요청</span>
                </div>
                <table class="table_default">
                    <colgroup>
                        <col width="120">
                        <col width="240">
                        <col width="120">
                        <col width="240">
                        <col width="120">
                        <col width="240">
                    </colgroup>
                    <tr>
                        <th>발신프로필/그룹</th>
                        <td>
                            <span>{{ profileTitle }}</span><br>
                            <button class="btn_xs btn_blue_line" @click="action_ShowGroupPopup">그룹 선택</button>
                            <button class="btn_xs btn_blue_line" @click="action_ShowProfilePopup">프로필선택</button>
                        </td>
                        <th>템플릿명</th>
                        <td>
                            <input type="text" value="기존 템플릿 명" placeholder="최대 100자" class="input_s w_full" v-model="templateName">
                        </td>
                        <th>템플릿코드</th>
                        <td>
                            <input type="text" value="기존 템플릿 코드"  placeholder="최대 25자" class="input_s w_full" v-model="templateCode">
                        </td>
                    </tr>
                    <tr>
                        <th>카테고리</th>
                        <td>
                            <div class="flex_aic gap_5">
                                <select name="카테고리그룹" id="" class="input_s w_full" v-model="selectedCategoryGroup">
                                    <option :value="undefined">카테고리</option>
                                    <option
                                        v-for="(group, index) in categoryGroups" :key="index"
                                        :value="group"
                                    >{{ group }}</option>
                                </select>
                                <select name="카테고리" id="" class="input_s w_full" v-model="selectedCategory">
                                    <option :value="undefined">선택</option>
                                    <option 
                                        v-for="(category, index) in detailCategories" :key="index"
                                        :value="category.code"
                                    >{{ category.name }}</option>
                                </select>
                            </div>
                        </td>
                        <th>메시지유형</th>
                        <td>
                            <div class="flex_aic gap_5">
                                <select name="메시지유형" id="" class="input_s w_full" disabled>
                                    <option value="" selected>기본형</option>
                                    <option value="">부가정보형</option>
                                </select>
                            </div>
                        </td>
                        <th>강조유형</th>
                        <td>
                            <div class="flex_aic gap_5">
                                <select name="강조유형" id="" class="input_s w_full" disabled>
                                    <option value="" selected>강조 안함</option>
                                    <option value="">강조 표기형</option>
                                    <option value="">이미지형</option>
                                    <option value="">아이템 리스트형</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>보안템플릿</th>
                        <td>
                            <div class="flex_aic gap_5">
                                <select name="보안템플릿" id="" class="input_s w_full" disabled>
                                    <option value="" selected>사용 안함</option>
                                    <option value="">사용함</option>
                                </select>
                            </div>
                        </td>
                        <th>등록일시</th>
                        <td>{{ createdAtDate }}<span class="mgl_5">{{ createdAtTime }}</span></td>
                        <th>수정일시</th>
                        <td>{{ modifiedAtDate }}<span class="mgl_5">{{ modifiedAtHour }}</span></td>
                    </tr>
                </table>
                <div class="mgt_15 pdb_20" style="border-bottom: 1px solid #b2b2b2;">
                    <textarea rows="12" class="w_full" placeholder="내용 입력" maxlength="1000" v-model="templateContent"></textarea>
                    <div class="flex_aic_spb mgt_10">
                        <div class="flex_aic gap_10">
                            <b class="dgray_txt">치환 가능 문자</b>
                            <span v-pre class="variable_txt lgray">#{고객명}</span>
                            <span v-pre class="variable_txt lgray">#{병원명}</span>
                        </div>
                        <div class="flex_aic gap_10">
                            <button class="btn_s btn_gray" v-if="isShowDeleteButton" @click="action_Delete">삭제</button>
                            <button class="btn_s btn_gray" v-if="isShowStopButton" @click="action_Stop">사용중지</button>
                            <button class="btn_s btn_blue" :disabled="!isShowUpdateButton" @click="action_Save">{{ saveButtonTitle }}</button>
                            <button class="btn_s btn_white" v-if="isShowRequestButton" @click="action_Request">검수요청</button>
                            <button class="btn_s btn_white" v-if="isShowRequestCancelButton" @click="action_RequestCancel">검수요청취소</button>
                            <button class="btn_s btn_white" v-if="isShowApprovalCancelButton" @click="action_ApprovalCancel">승인취소</button>
                            <button class="btn_s btn_white" v-if="isShowResumeButton" @click="action_Reuse">사용중지해제</button>
                            <button class="btn_s btn_white" v-if="isShowResumeDormantButton" @click="action_ResumeDormant">휴면해제</button>
                        </div>
                    </div>
                </div>
                <AlrimtalkMessageEditComments
                    :comments="comments"
                />
            </div>
        </div>
    </div>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import AlrimtalkMessageProfilePopup from './AlrimtalkMessageProfilePopup.vue'
import RemoveConfirm from '@/views/main/ShareComponents/RemoveConfirm.vue'
import AlrimtalkMessageEditComments from './AlrimtalkMessageEditComments.vue'

const moment = require('@/util/MPMoment')
export default {
    components: {
        LoadingIndicate,
        AlrimtalkMessageProfilePopup,
        RemoveConfirm,
        AlrimtalkMessageEditComments,
    },
    props: {
        isShow: Boolean,
        template: {
            type: Object,
            default: () => { return {} }
        },
        groups: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isLoading: false,

            isShowSelectGroupPopup: false,
            isShowSelectProfilePopup: false,

            selectedProfile: undefined,
            selectedGroup: undefined,

            selectedCategoryGroup: undefined,
            selectedCategory: undefined,

            categories: [],

            // senderKey: undefined,
            templateCode: undefined,
            templateName: undefined,
            // templateMessageType: 'BA',
            // templateEmphasizeType: 'NONE',
            templateContent: undefined,
            // templateHeader: undefined,
            // categoryCode: selectedCategory

            detail: undefined, // 상세조회를 통해 가져온 값

            createdAt: undefined,
            modifiedAt: undefined,

            isShowAlertPopup: false,
            alertTitle: undefined,
            alertBody: undefined,
            alertCallback: undefined
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) {
                    this.isShowSelectGroupPopup = false
                    this.isShowSelectProfilePopup = false
                    this.initVariables()
                    this.loadCategory()
                }
            }
        },
        selectedCategoryGroup: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                
            }
        },
        template: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue.templateCode == undefined) { return }
                this.initVariables()
                this.loadDetail()
            }
        }
    },
    computed: {
        senderKey: function() {
            if (this.senderKeyType == 'G') { return this.selectedGroup?.groupKey }
            else { return this.selectedProfile.senderKey }
        },
        senderKeyType: function() {
            if (this.selectedGroup != undefined) { return 'G' }
            return 'S'
        },
        profileTitle: function() {
            if (this.senderKeyType == 'G') { return this.selectedGroup?.name ?? '-' }
            return this.selectedProfile?.name ?? '-'
        },
        categoryGroups: function() {
            return Array.from(new Set(this.categories.map( (c) => c.groupName )))
        },
        detailCategories: function() {
            if (this.selectedCategoryGroup == undefined) { return [] }
            return this.categories.filter( g => g.groupName == this.selectedCategoryGroup )
        },
        createdAtDate: function() {
            if (this.createdAt == undefined) { return '-' }
            return moment(this.createdAt).format('YYYY-MM-DD')
        },
        createdAtTime: function() {
            if (this.createdAt == undefined) { return '' }
            return moment(this.createdAt).format('HH:mm')
        },
        modifiedAtDate: function() {
            if (this.modifiedAt == undefined) { return '-' }
            return moment(this.modifiedAt).format('YYYY-MM-DD')
        },
        modifiedAtHour: function() {
            if (this.modifiedAt == undefined) { return '' }
            return moment(this.modifiedAt).format('HH:mm')
        },
        comments: function() {
            return this.detail?.comments ?? []
        },

        // Button Status
        /*
            버튼 상태 정리 - 문서 기준
            status, inspectionStatus 값 참조
            , = and
            / = or
            생성        : -
            수정        : R, REG/REJ
            삭제        : R, REG/REJ
            검수요청    : R, REG
            검수요청쉬소: R, REQ
            승인해제    : R
            사용중지    : R/A, APR
            사용중지해제: S, APR
            휴면해제    : dormant == true
         */
        showSaveButton: function() {
            return true
        },
        isUpdate: function() { return this.detail?.templateCode != undefined },
        saveButtonTitle: function() { return this.isUpdate == true ? '수정사항 저장' : '생성'},
        isShowUpdateButton: function() {
            let detail = this.detail
            if (detail == undefined) { return true }
            if (detail.status == 'R' && (detail.inspectionStatus == 'REG' || detail.inspectionStatus == 'REJ')) { return true }
            return false
        },
        isShowDeleteButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.status == 'R' && (detail.inspectionStatus == 'REG' || detail.inspectionStatus == 'REJ')) { return true }
            return false
        },
        isShowStopButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if ((detail.status == 'R' || detail.status == 'A') && detail.inspectionStatus == 'APR') { return true }
            return false
        },
        isShowRequestButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.status == 'R' && detail.inspectionStatus == 'REG') { return true }
            return false
        },
        isShowRequestCancelButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.status == 'R' && detail.inspectionStatus == 'REQ') { return true }
            return false
        },
        isShowApprovalCancelButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.status == 'R' && detail.inspectionStatus == 'APR') { return true }
            return false
        },
        isShowResumeButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.status == 'S' && detail.inspectionStatus == 'APR') { return true }
            return false
        },
        isShowResumeDormantButton: function() {
            let detail = this.detail
            if (detail == undefined) { return false }
            if (detail.dormant == true) { return true }
            return false
        },
    },
    methods: {
        initVariables() {
            this.selectedProfile = undefined
            this.selectedGroup = undefined
            this.detail = undefined
            this.templateCode = undefined
            this.templateName = undefined
            this.templateContent = undefined
            this.createdAt = undefined
            this.modifiedAt = undefined
        },
        parseDetail() {
            if (this.detail == undefined) { return }
            this.templateCode = this.detail.templateCode
            this.templateName = this.detail.templateName
            this.templateContent = this.detail.templateContent
            this.createdAt = this.detail.createdAt
            this.modifiedAt = this.detail.modifiedAt
            
            this.parseCategory()
            this.loadSenderKey()
        },
        parseCategory() {
            if (this.categories?.length > 0) {
                let categoryCode = this.detail?.categoryCode
                let category = this.categories?.find( (c) => c.code == categoryCode )
                this.selectedCategoryGroup = category?.groupName
                this.selectedCategory = category?.code
            }
        },

        // MARK: - Network
        async loadSenderKey() {
            let model = {
                senderKey: this.detail.senderKey
            }
            if (this.detail.senderKeyType == 'G') {
                let group = this.groups.find( (g) => g.groupKey == this.detail.senderKey)
                this.selectedGroup = group
            }
            else {
                let result = await this.API_V3.KakaoTemplate.findProfile(model)
                if (result.data.code != 0) {
                    alert(result.data.message)
                    return
                }
                this.selectedProfile = result.data.data
            }
        },
        async loadCategory() {
            let result = await this.API_V3.KakaoTemplate.findCategory()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.categories = result.data.data
            this.parseCategory()
        },
        async loadDetail() {
            this.isLoading = true
            let model = {
                senderKey: this.template.senderKey,
                senderKeyType: this.template.senderKeyType,
                templateCode: this.template.templateCode,
            }
            let result = await this.API_V3.KakaoTemplate.findDetail(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.detail = result.data.data
            this.parseDetail()
            this.isLoading = false
        },
        async create() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode,
                templateName: this.templateName,
                templateMessageType: 'BA',
                templateEmphasizeType: 'NONE',
                templateContent: this.templateContent,
                categoryCode: this.selectedCategory,
            }
            let result = await this.API_V3.KakaoTemplate.createTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '템플릿 생성에 성공하였습니다.')
            this.$emit('setSelectedEditTemplate', result.data.data)
        },
        async update() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode,
                templateName: this.templateName,
                templateContent: this.templateContent,
                templateMessageType: 'BA',
                templateEmphasizeType: 'NONE',
                categoryCode: this.selectedCategory,
            }
            if (this.detail.templateCode != this.templateCode) {
                model.newTemplateCode = this.templateCode
            }

            let result = await this.API_V3.KakaoTemplate.updateTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '템플릿 수정에 성공하였습니다.')
            this.$emit('setSelectedEditTemplate', result.data.data)
        },
        async delete() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.removeTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }

            this.$emit('setToastMessage', '템플릿 수정에 성공하였습니다.')
            this.$emit('setSelectedEditEmplate', undefined)
            this.$emit('close')
        },
        async stop() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.stopTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '사용 중지에 성공하였습니다.')
            this.loadDetail()
        },
        async request() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.requestTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '검수요청에 성공하였습니다.')
            this.loadDetail()
        },
        async requestCancel() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.requestCancelTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '검수요청 취소에 성공하였습니다.')
            this.loadDetail()
        },
        async reuse() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.reuseTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '사용중지 해제에 성공하였습니다.')
            this.loadDetail()
        },
        async approvalCancel() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.cancelApprovalTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '승인 취소에 성공하였습니다.')
            this.loadDetail()
        },
        async releaseTemplate() {
            let model = {
                senderKey: this.senderKey,
                senderKeyType: this.senderKeyType,
                templateCode: this.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.releaseTemplate(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('setToastMessage', '승인 취소에 성공하였습니다.')
            this.loadDetail()
        },


        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        action_ShowProfilePopup() {
            this.isShowSelectProfilePopup = true
        },
        action_ShowGroupPopup() {
            this.isShowSelectGroupPopup = true
        },
        action_Save() {
            if (this.isUpdate) { this.update() }
            else { this.create() }
        },
        action_Delete() {
            this.alertTitle = '템플릿을 삭제하시겠습니까?'
            this.alertBody = '삭제된 템플릿은 복구가 불가능합니다.'
            this.isShowAlertPopup = true
            this.alertCallback = (isConfirm) => {
                console.log(isConfirm)
                if (isConfirm) { this.delete() }
                this.isShowAlertPopup = false
                this.alertCallback = undefined
            }
        },
        action_Stop() {
            this.stop()
        },
        action_Request() {
            this.request()
        },
        action_RequestCancel() {
            this.requestCancel()
        },
        action_ApprovalCancel() {
            this.approvalCancel()
        },
        action_Reuse() {
            this.reuse()
        },
        action_ResumeDormant() {
            this.releaseTemplate()
        },

        // MARK: - Emit
        updateSelectedSenderKey(data) {
            this.selectedGroup = data.group
            this.selectedProfile = data.profile
        },
        closeProfilePopup() {
            this.isShowSelectProfilePopup = false
            this.isShowSelectGroupPopup = false
        }
    }
}
</script>