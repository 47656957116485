<template>
    <div class="h_scroll">
        <div class="table_title_btn mgb_10">
            <input type="text" class="input_s w_full mgl_5" placeholder="검색어 입력" v-model="keyword" @keydown.enter="action_Search"/>
            <button class="btn_s btn_blue_line mgl_5" @click="action_Search">검색</button>
        </div>
        <div class="table_header_fix">
            <!-- 검색된 데이터 없을 떄 -->
            <table class="table_default consult_talk" v-if="isEmpty">
                <thead>
                    <tr>
                        <th width="230" class="txt_left">병원명</th>
                        <th width="" class="txt_left">병원 ID</th>
                        <th width="200" class="txt_left">상담톡 검색용 아이디</th>
                        <th width="80">선택</th>
                    </tr>
                </thead>
                <tr>
                    <td colspan="5" v-if="hasKeyword">검색된 병원이 없습니다.</td>
                </tr>
            </table>
            <!-- hospital data table -->
            <table class="table_default consult_talk" v-if="!isEmpty">
                <thead>
                    <tr>
                        <th width="230" class="txt_left">이름</th>
                        <th width="" class="txt_left">병원 ID</th>
                        <th width="200" class="txt_left">상담톡 검색용 아이디</th>
                        <th width="80">선택</th>
                    </tr>
                </thead>
                <tr v-for="(profile, index) in computedList" :key="index">
                    <td>{{ profile.name }}</td>
                    <td>
                        {{ profile.senderKey }}
                    </td>
                    <td>{{ profile.uuid }}</td>
                    <td>
                        <button class="btn_s btn_blue_line w_full" @click="action_Select(profile)">선택</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selectableKakaoProfiles: {type: Array, default: () => [] }
    },
    data() {
        return {
            filterKeyword: undefined,
            keyword: undefined,

            list: [],
        }
    },
    computed: {
        isEmpty: function() {
            return (this.computedList?.length ?? 0) == 0
        },
        hasKeyword: function() {
            return !(this.keyword == undefined || this.keyword == '')
        },
        computedList: function() {
            if (this.filterKeyword == undefined || this.filterKeyword == '') { return this.list }
            return this.list.filter( (l) => l.name.includes(this.filterKeyword) || l.uuid.includes(this.filterKeyword) )
        }
    },
    mounted() {
        this.loadProfiles()
    },
    methods: {
        async loadProfiles() {
            let result = await this.API_V3.KakaoTemplate.findAllProfile()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let profiles = result.data.data
            if (profiles.length > 0) {
                this.list = this.selectableKakaoProfiles
            }
            else {
                this.list = profiles
            }
        },

        // MARK: - Actions
        action_Search() {
            this.filterKeyword = this.keyword
        },
        action_Select(profile) {
            this.$emit('updateSelectedProfile', profile)
        }
    }
}
</script>