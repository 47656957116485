import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (query) => {
    return axios({
        method: 'GET',
        url: URL.SERVICE_SETTING_COUNT,
        params: query,
        headers: Util.getHeaders()
    })
}

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.SERVICE_SETTING_FIND,
        params: query,
        headers: Util.getHeaders()
    })
}

export const findOne = (query) => {
    return axios({
        method: 'GET',
        url: URL.SERVICE_SETTING_FIND_ONE,
        params: query,
        headers: Util.getHeaders()
    })
}

export const create = (data) => {
    return axios({
        method: 'POST',
        url: URL.SERVICE_SETTING_CREATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const update = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.SERVICE_SETTING_UPDATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const remove = (m) => {
    return axios({
        method: 'DELETE',
        url: URL.SERVICE_SETTING_REMOVE,
        params: m,
        headers: Util.getHeaders()
    })
}

export const changeAccountCount = (m) => {
    return axios({
        method: 'PATCH',
        url: URL.SERVICE_SETTING_ACCOUNT_COUNT_CHANGE,
        data: m,
        headers: Util.getHeaders()
    })
}

export const updateDefaultCounselor = (m) => {
    return axios({
        method: 'PATCH',
        url: URL.SERVICE_SETTING_DEFAULT_COUNSELOR_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}