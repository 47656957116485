import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'


export const find = (m) => {
    return axios({
        method: 'get',
        url: URL.Country,
        params: m,
        headers: Util.getHeaders()
    })
}

export const update = (m) => {
    return axios({
        method: 'patch',
        url: URL.Country,
        params: m,
        headers: Util.getHeaders()
    })
}