<template>
    <tr>
        <td>{{ realName }}</td>
        <td>{{ name }}</td>
        <td><small class="lgray_txt">{{ _id }}</small></td>
        <td>{{ id }}</td>
        <td>{{ createdAt }}</td>
        <td>{{ type }}</td>
        <td>
            <button class="btn_s btn_blue_line" @click="action_Modify" :disabled="!auth.adminAccount_w">수정</button>
            <button class="btn_s btn_gray" @click="action_Remove" :disabled="!auth.adminAccount_w">삭제</button>
        </td>
    </tr>
</template>


<script>
const moment = require('../../../../../util/MPMoment')
export default {
    props: {
        index: Number,
        adminUser: Object
    },
    data() {
        return {}
    },
    watch: {},
    computed: {
        realName: function() { return this.adminUser?.realName ?? '-' },
        name: function() { return this.adminUser?.name ?? '-' },
        _id: function() { return this.adminUser?._id ?? '-' },
        id: function() { return this.adminUser?.id ?? '-' },
        createdAt: function() {
            if (this.adminUser?.createdAt == undefined) { return '-' }
            return moment(this.adminUser?.createdAt).format('YYYY-MM-DD HH:mm')
        },
        type: function() { return this.adminUser?.type ?? '-' },
    },
    methods: {
        action_Modify() {
            this.$emit('modify', this.index)
        },
        action_Remove() {
            this.$emit('remove', this.index)
        },
    }
}
</script>