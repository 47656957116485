<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="kakao_friend_talk_pop_wrap">
            <div class="pop_title">
                <span class="title">알림톡/친구톡 연동 신청목록</span>
                <a class="mgl_auto">
                    <v-icon :size="24" @click="action_Close">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="h_scroll" >
                    <div class="table_title_btn mgb_10">
                            <input type="text" class="input_s w_full" placeholder="병원명 검색"/>
                            <button class="btn_s btn_blue_line mgl_5">검색</button>
                    </div>
                    <div class="table_header_fix">
                        <!-- 검색된 데이터 없을 떄 -->
                        <table class="table_default" v-if="isEmpty">
                            <thead>
                                <tr>
                                    <th width="250" class="txt_left">병원명</th>
                                    <th width="190" class="txt_left">알림톡 검색용 아이디</th>
                                    <th width="120" class="txt_left">관리자 휴대폰번호</th>
                                    <th width="160" class="txt_left">카테고리</th>
                                    <th width="65">상태</th>
                                    <th width="" class="txt_left">갱신</th>
                                    <th width="80">선택</th>
                                </tr>
                            </thead>
                            <tr>
                                <td colspan="7">
                                    검색된 병원이 없습니다.
                                </td>
                            </tr>
                        </table>
                        <!-- hospital data table -->
                        <table class="table_default" v-if="!isEmpty">
                            <thead>
                                <tr>
                                    <th width="250" class="txt_left">병원명</th>
                                    <th width="190" class="txt_left">알림톡 검색용 아이디</th>
                                    <th width="120" class="txt_left">관리자 휴대폰번호</th>
                                    <th width="160" class="txt_left">카테고리</th>
                                    <th width="65">상태</th>
                                    <th width="">갱신</th>
                                    <th width="80">선택</th>
                                </tr>
                            </thead>
                            <Cell
                                v-for="(history, index) in linkages" :key="index"
                                :index=index
                                :history=history
                                @selectFriendHistory="selectFriendHistory"
                                @detail="loadDetail"
                            />
                        </table>
                    </div>
                    <Pagenation
                        :totalCount="totalCount"
                        :currentPage="currentPage"
                        :countPerPage="countPerPage"
                        :countPerRow="5"
                        @changePage="loadPageIndex"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue';
import PageController from '@/controller/PageController'
import Cell from './KakaoIntegrationFriendLoadCell.vue'
export default {
    components: {
        Cell,
        Pagenation
    },
    props: {
        isShow: Boolean
    },
    data() {
        return {
            isLoading: false,
            linkages: [],
            
            keyword: '',

            pageController: new PageController(),

            totalCount: 0,
            countPerPage: 10,
            currentPage: 0,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == true) { this.reload() }
            }
        }
    },
    computed: {
        isEmpty: function() {
            return this.linkages.length == 0
        },
        filter: function() {
            return {
                isRequestForAlimAndFriendTalkLinkage: true,
                isRequestForConsultationTalkLinkage: false,
                keyword: this.keyword,
                key: 'createdAt',
                way: -1
            }
        }
    },
    methods: {
        async reload() {
            this.loadPageIndex(0)
        },
        async loadCount() {
            let result = await this.API_V3.KakaoLinkageHistory.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadPageIndex(index) {
            this.isLoading = true
            await this.loadCount()
            this.pageController.api = this.API_V3.KakaoLinkageHistory.find
            this.pageController.filter = this.filter
            this.pageController.countPerPage = this.countPerPage
            this.currentPage = index
            await this.pageController.loadPageIndex(index)
            this.isLoading = false
            this.linkages = this.pageController.data
        },
        changePage(index) {
            this.loadPageIndex(index)
        },
        async loadDetail(index) {
            let _id = this.linkages[index]._id
            let result = await this.API_V3.KakaoLinkageHistory.detail({_id: _id})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let detail = result.data.data
            this.linkages.splice(index, 1, detail)
        },
        selectFriendHistory(history) {
            this.$emit('selectFriendHistory', history)
            this.$emit('close')
        },

        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        action_Enter() {
            this.changePage(0)
        },
    }
}
</script>