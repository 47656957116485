<template>
    <div class="pop_overlay_bg" v-show="isShow">
        <div class="max_account_edit_pop_wrap">
            <div class="pop_title">
                <span class="title">계정 수정</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">
                        이름<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <input type="text" placeholder="최대 15자" maxlength="15" class="input_s w_full" v-model="realName">
                        <small class="red_txt" v-if="!isValid_Name">이름을 입력해 주세요.</small>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        아이디<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <input type="text" placeholder="영문, 숫자 포함 3~25자" maxlength="25" class="input_s w_full" v-model="id">
                        <small class="red_txt" v-if="!isValid_ID">아이디를 입력해 주세요.</small>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        비밀번호<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <input type="text" placeholder="(변경 시 입력)영문, 숫자 포함 3~25자" maxlength="25" class="input_s w_full" v-model="password">
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        직책/직급<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <select name="직책/직급" id="" class="w_full" v-model="jobType">
                            <option value="대표원장님">대표원장님</option>
                            <option value="원장님">원장님</option>
                            <option value="팀장">팀장</option>
                            <option value="상담실장">상담실장</option>
                            <option value="실장">실장</option>
                            <option value="치료사">치료사</option>
                            <option value="직원">직원</option>
                            <option value="(병원)">(병원)</option>
                            <option value="직접입력">직접입력</option>
                        </select>
                        <input class="input_s w_full mgt_5" type="text" placeholder="직접입력" :disabled="jobType!='직접입력'" v-model="additionalJobType">
                        <small class="red_txt" v-if="!isValid_JobType">직책/직급을 입력해 주세요.</small>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        권한<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <select name="권한" id="" class="w_full" v-model="authorizationTypeID">
                            <option
                                v-for="(type, index) in authorizationTypes"
                                :key="index"
                                :value="type._id"
                            >{{ type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        휴대폰 번호
                    </div>
                    <div class="cont">
                        <input type="text" placeholder="010-1234-5678" class="input_s w_full" v-model="phone">
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        isShow: Boolean,
        hospitalID: String,
        doctor: Object,
        authorizationTypes: Array,
    },
    data() {
        return {
            realName:  undefined,
            phone:  undefined,
            id:  undefined,
            password:  undefined,
            type:  undefined,
            authorizationTypeID: undefined,
            jobType:  undefined,
            additionalJobType: undefined,

            jobTypes: ["대표원장님", "원장님", "팀장", "상담실장", "실장", "치료사", "(병원)", "직원", "직접입력"]
        }
    },
    watch: {
        doctor: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue?._id == undefined) { return }
                this.realName = newValue.realName
                this.phone = newValue.phone
                this.id = newValue.id
                this.password = ""
                this.type = newValue.type
                this.authorizationTypeID = newValue.authorizationTypeID?._id ?? undefined
                if (this.jobTypes.includes(newValue.jobType)) {
                    this.jobType = newValue.jobType
                    this.additionalJobType = undefined
                }
                else {
                    this.jobType = '직접입력'
                    this.additionalJobType = newValue.jobType
                }
            }
        }
    },
    computed: {
        isValid_Name: function() {
            if (this.realName == undefined || this.realName == '') { return false }
            return true
        },
        isValid_ID: function() {
            if (this.id == undefined || this.id =='') { return false }
            if (this.id?.length < 5) { return false }
            return true
        },
        isValid_Password: function() {
            if ((this.password?.length ?? 0) < 5) { return false }
            let reg = /[^a-zA-Z0-9!@#$%^&+=]/gi
            if (reg.test(this.password) == true) { return false }
            return true
        },
        isValid_JobType: function() {
            if (this.jobType == undefined || this.jobType == '') { return false }
            if (this.jobType == '직접입력') {
                if (this.additionalJobType == undefined || this.additionalJobType == '') { return false }
            }
            return true
        },
        isValid_AuthorizationType: function() {
            if (this.authorizationTypeID == undefined || this.authorizationTypeID == '') { return false }
            return true
        },
    },
    methods: {
        initProperty() {
            this.realName =  undefined
            this.phone =  undefined
            this.id =  undefined
            this.password =  undefined
            this.type =  undefined
            this.jobType =  undefined
            this.additionalJobType =  undefined
        },

        // MARK: - Network
        async updateDoctor(model) {
            let result = await this.API_V3.Hospital.updateAccount(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('loadDatas')
            this.$emit('close')
        },

        // MARK: - Actions
        action_Close() {
            return this.$emit('close')
        },
        action_Save() {
            let jobType = this.jobType == '직접입력' ? this.additionalJobType : this.jobType
            let model = {
                _id: this.doctor._id,
                hospitalID: this.hospitalID,
                id: this.id,
                phone: this.phone,
                realName: this.realName,
                type: 2200,
                jobType: jobType,
                authorizationTypeID: this.authorizationTypeID,
                isDoctor: true,
            }
            if (this.password != undefined || this.password != '') { model.password = this.password }
            this.updateDoctor(model)
        },
    },
}
</script>