<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>앱 버전 설정</span>
        </div>
        <div class="h_scroll">
            <div class="w_940">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">일렉트론</span>
                        <span class="mgl_auto info">병원 직원 및 서비스 매니저가 사용하는 일렉트론의 버전을 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <div class="form_list">
                            <div class="title">일렉트론 버전</div>
                            <div class="cont">
                                <input type="text" name="electronVersion" v-model="electronVersion" class="w_full input_s" placeholder="version" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">필수 업데이트 여부</div>
                            <div class="cont">
                                <label class="check_wrap" style="display: flex">
                                    <input type="checkbox" name="electronIsNecessary" v-model="electronIsNecessary" />
                                    <span class="checkmark" style="display: inline"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">애프터닥 모바일 앱 (고객용)</span>
                        <span class="mgl_auto info">고객이 사용하는 애프터닥 앱의 버전을 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <span class="title"><b>Android</b></span>
                        <hr />
                        <div class="form_list">
                            <div class="title">버전</div>
                            <div class="cont">
                                <input type="text" name="appAndroidVersion" v-model="appAndroidVersion" class="w_full input_s" placeholder="version" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">최소 버전</div>
                            <div class="cont">
                                <input type="text" name="appAndroidMinVersion" v-model="appAndroidMinVersion" class="w_full input_s" placeholder="minVersion" />
                            </div>
                        </div>
                    </div>
                    <div class="form_flex_cont">
                        <span class="title"><b>iOS</b></span>
                        <hr />
                        <div class="form_list">
                            <div class="title">버전</div>
                            <div class="cont">
                                <input type="text" name="appiOSVersion" v-model="appiOSVersion" class="w_full input_s" placeholder="version" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">최소 버전</div>
                            <div class="cont">
                                <input type="text" name="appiOSMinVersion" v-model="appiOSMinVersion" class="w_full input_s" placeholder="minVersion" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">애프터닥 관리자 앱 (병원 직원용)</span>
                        <span class="mgl_auto info">병원 직원용 관리자 앱의 버전을 설정합니다.</span>
                    </div>
                    <div class="form_flex_cont">
                        <span class="title"><b>Android</b></span>
                        <hr />
                        <div class="form_list">
                            <div class="title">버전</div>
                            <div class="cont">
                                <input
                                    type="text"
                                    name="managerAppAndroidVersion"
                                    v-model="managerAppAndroidVersion"
                                    class="w_full input_s"
                                    placeholder="version"
                                />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">최소 버전</div>
                            <div class="cont">
                                <input
                                    type="text"
                                    name="managerAppAndroidMinVersion"
                                    v-model="managerAppAndroidMinVersion"
                                    class="w_full input_s"
                                    placeholder="minVersion"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form_flex_cont">
                        <span class="title"><b>iOS</b></span>
                        <hr />
                        <div class="form_list">
                            <div class="title">버전</div>
                            <div class="cont">
                                <input type="text" name="managerAppiOSVersion" v-model="managerAppiOSVersion" class="w_full input_s" placeholder="version" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">최소 버전</div>
                            <div class="cont">
                                <input
                                    type="text"
                                    name="managerAppiOSMinVersion"
                                    v-model="managerAppiOSMinVersion"
                                    class="w_full input_s"
                                    placeholder="minVersion"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <small class="red_txt mgt_10" v-show="isError == true">
                    {{ errorMessage }}
                </small>
                <div class="btn_wrap">
                    <button class="btn btn_blue btn_l" @click="action_Save" :disabled="!auth.app_w">저장</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // current setting
            serverSetting: {},
            serverSettingID: '',

            // electron
            electronVersion: '',
            electronIsNecessary: false,

            // mobile app for hservices
            appAndroidVersion: '',
            appAndroidMinVersion: '',
            appiOSVersion: '',
            appiOSMinVersion: '',

            // manager app
            managerAppAndroidVersion: '',
            managerAppAndroidMinVersion: '',
            managerAppiOSVersion: '',
            managerAppiOSMinVersion: '',

            // errors
            errorMessage: '',
            isError: false
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            let result = await this.API.VersionSetting.find({})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            if (result.data.data != undefined && result.data.data.length > 0) {
                this.serverSetting = result.data?.data[0] ? result.data.data[0].AppVersion : {}
                this.serverSettingID = result.data?.data[0] ? result.data.data[0]._id : ''
                this.electronVersion = this.serverSetting.Electron.version
                this.electronIsNecessary = this.serverSetting.Electron.isNecessary
                this.appAndroidVersion = this.serverSetting.Android
                this.appAndroidMinVersion = this.serverSetting.min_Android
                this.appiOSVersion = this.serverSetting.iOS
                this.appiOSMinVersion = this.serverSetting.min_iOS
                this.managerAppAndroidVersion = this.serverSetting.ManagerApp.Android
                this.managerAppAndroidMinVersion = this.serverSetting.ManagerApp.min_Android
                this.managerAppiOSVersion = this.serverSetting.ManagerApp.iOS
                this.managerAppiOSMinVersion = this.serverSetting.ManagerApp.min_iOS
            } else {
                this.isError = true
                this.errorMessage = 'appversion server setting load failed..'
            }
        },

        async action_Save() {
            this.isError = false
            this.errorMessage = ''
            if (
                this.electronVersion == '' ||
                this.appAndroidVersion == '' ||
                this.appAndroidMinVersion == '' ||
                this.appiOSVersion == '' ||
                this.appiOSMinVersion == '' ||
                this.managerAppAndroidVersion == '' ||
                this.managerAppAndroidMinVersion == '' ||
                this.managerAppiOSVersion == '' ||
                this.managerAppiOSMinVersion == ''
            ) {
                console.log('meh')
                this.isError = true
                this.errorMessage = '앱 버전을 모두 입력해주세요.'
                return
            }

            let model = {
                _id: this.serverSettingID
            }

            let Electron = {
                version: this.electronVersion,
                isNecessary: JSON.parse(this.electronIsNecessary)
            }
            let ManagerApp = {
                Android: this.managerAppAndroidVersion,
                min_Android: this.managerAppAndroidMinVersion,
                iOS: this.managerAppiOSVersion,
                min_iOS: this.managerAppiOSMinVersion
            }

            let AppVersion = {
                Electron: Electron,
                ManagerApp: ManagerApp,
                Android: this.appAndroidVersion,
                min_Android: this.appAndroidMinVersion,
                iOS: this.appiOSVersion,
                min_iOS: this.appiOSMinVersion
            }
            model.AppVersion = AppVersion

            // try update
            let result = await this.API.VersionSetting.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data)

            this.initData()
            // TODO : toast message : `version setting saved.` , et cetera...
        }
    }
}
</script>
