<template>
    <!-- 하다희 : 이곳 이쁘게 바꿔주세요. -->
    <div class="msg_noti">
        <div v-if="hasTemplate">
            <span class="label bd_r" :class="templateStatusClass">{{ templateStatusTitle }}</span>
            <span class="label pgray bd_r">{{ profileName }}</span>
            <span class="label pd_0">템플릿명:</span><span class="label pgray bd_r">{{ templateName }}</span>
            <span class="label pd_0">템플릿코드:</span><span class="label pgray">{{ templateCode }}</span>
        </div>
        <div v-if="!hasTemplate">
            <span>아직 알림톡 등록이 되지 않은 메시지입니다.</span>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        template: { type: Object, default: () => { return {} } },
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
    },
    data() {
        return {
            
        }
    },
    watch: {},
    computed: {
        hasTemplate: function() {
            if (this.template.templateName == undefined &&
                this.template.templateCode == undefined &&
                this.template.categoryCode == undefined &&
                this.template.templateContent == undefined) { return false }
            return true
        },
        statusKind: function() {
            return [
                {name: '미등록',    key: '', class: 'lgray'},
                {name: '등록',      key: 'REG', class: 'lgray'},
                {name: '검수요청',  key: 'REQ', class: 'lgray'},
                {name: '반려',      key: 'REJ', class: 'lred'},
                {name: '차단',      key: 'STP', class: 'lred'},
                {name: '발송전',    key: 'RDY', class: 'lgreen'},
                {name: '정상',      key: 'ACT', class: 'lgreen'},
                {name: '휴면',      key: 'DMT', class: 'lgray'},
                {name: '차단',      key: 'BLK', class: 'lgray'},
            ]
        },
        groupOrProfile: function() { return this.kakaoGroups.concat(this.kakaoProfiles) },
        senderKey: function() { return this.template.senderKey },
        profileName: function() {
            let p = this.groupOrProfile.find( (p) => p.groupKey == this.senderKey || p.senderKey == this.senderKey )
            return p.name
        },
        templateName: function() { return this.template?.templateName ?? '-' },
        templateCode: function() { return this.template?.templateCode ?? '-' },
        templateStatus: function() {
            let key = this.template?.serviceStatus
            return this.statusKind.find( (s) => s.key == key) ?? {}
        },
        templateStatusTitle: function() { return this.templateStatus?.name ?? '-' },
        templateStatusClass: function() { return this.templateStatus?.class },
    },
    methods: {},
}
</script>