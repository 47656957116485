<template>
    <div class="v2_content_wrap">
        <div class="page_title">
            <span>어드민 계정 관리</span>
        </div>
        <div class="h_scroll">
            <div class="table_title mgb_20">
                <p class="dgray_txt">
                    총 <span class="black_txt">N</span>개
                </p>
                <div class="table_title_btn">
                    <input type="text" class="input_s" placeholder="이름으로 검색" v-model="keyword" @keydown.enter="action_Search">
                    <button class="btn_s btn_blue_line" @click="action_Search">검색</button>
                    <button class="btn btn_s btn_blue" @click="action_Create" :disabled="!auth.adminAccount_w">등록</button>
                </div>
            </div>
            <div class="table_header_fix txt_left">
                <table class="table_default">
                    <colgroup>
                        <col width="">
                        <col width="15%">
                        <col width="15%">
                        <col width="15%">
                        <col width="15%">
                        <col width="10%">
                        <col width="150px">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>이름</th>
                            <th>고객이 보는 이름</th>
                            <th>_id</th>
                            <th>아이디</th>
                            <th>생성일시</th>
                            <th>타입</th>
                            <th>수정/삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AccountManagementItem
                            v-for="(user, index) in list" :key="index"
                            :index="index"
                            :adminUser="user"
                            @modify="userModify"
                            @remove="userRemove"
                        />
                    </tbody>
                </table>
            </div>
        </div>
        <!-- 여기에 페이지네이션 필요 -->
        <PagenationComponent
            v-bind:totalCount="totalCount"
            v-bind:currentPage="currentPage"
            v-bind:countPerPage="countPerPage"
            v-bind:countPerRow="countPerRow"
            v-on:changePage="changePage"
        />
        <AccountEditPopupVue
            :isShow="isShowEditPopup"
            :selectedUser="selectedUser"
            @close="closeEditPopup"
            @reload="reloadAdminUser"
        />
    </div>
</template>


<script>
import PageController from '../../../../../controller/PageController'
import PagenationComponent from '../../../ShareComponents/PagenationComponent.vue'
import AccountEditPopupVue from './AccountEditPopup.vue'
import AccountManagementItem from './AccountManagementItem.vue'

export default {
    components: { PagenationComponent, AccountEditPopupVue, AccountManagementItem },
    data() {
        return {
            pageController: new PageController(),

            isLoading: false,
            isShowEditPopup: false,

            totalCount: 0,
            currentPage: 0,
            countPerPage: 20,
            countPerRow: 5,

            keyword: '',
            list: [],
            selectedUser: undefined,
        }
    },
    watch: {},
    computed: {
        filter: function() {
            return {
                keyword: this.keyword
            }
        }
    },
    mounted() {
        this.loadAdminUser(0)
    },
    methods: {
        // MARK: - Actions
        action_Search() {
            this.loadAdminUser(0)
        },
        action_Create() {
            this.selectedUser = undefined,
            this.isShowEditPopup = true
        },

        // MARK: - Network
        async loadAdminUser(page) {
            this.pageController.filter = this.filter
            this.pageController.api = this.API_V3.AdminUser.find
            await this.pageController.loadPageIndex(page)
            this.list = this.pageController.data
            this.currentPage = page
        },
        async removeAdminUser(user) {
            let result = await this.API_V3.AdminUser.remove({_id: user._id})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadAdminUser(this.currentPage)
        },

        // MARK: - Emit
        reloadAdminUser() {
            this.loadAdminUser(this.currentPage)
        },
        closeEditPopup() {
            this.selectedUser = undefined
            this.isShowEditPopup = false
            this.loadAdminUser(this.currentPage)
        },
        changePage(page) {
            console.log(page)
        },

        userModify(index) {
            let target = this.list[index]
            this.selectedUser = target
            console.log(this.selectedUser)
            this.isShowEditPopup = true
        },
        userRemove(index) {
            let target = this.list[index]
            this.removeAdminUser(target)
        },
    }
}
</script>