<template>
    <div class="pdt_15" style="border-top:1px solid #b2b2b2">
        <div class="large_txt mgb_5" style="font-size: 18px;">알림톡/친구톡</div>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                카카오 알림톡/친구톡 연동
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="카카오 알림톡/친구톡 연동" :value="false" v-model="usingKakaoAlimAndFriendTalkLinkage">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="카카오 알림톡/친구톡 연동" :value="true" v-model="usingKakaoAlimAndFriendTalkLinkage">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                검색용 아이디<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="검색용 아이디(@UUID)입력" disabled v-model="pf_id">
                    <button class="btn_s btn_blue_line" @click="action_OpenFriendLoadPopup">불러오기</button>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                그룹 등록 여부<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <select name="그룹 등록 여부" id="" disabled v-model="groupTitle">
                    <option :value="groupTitle">{{ groupTitle }}</option>
                </select>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                관리자 휴대폰번호<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="발신 프로필 등록번호 입력( - 제외)" disabled v-model="phone">
                    <button class="btn_s btn_blue_line" disabled>토큰 발송</button>
                </div>
                <!-- <input type="text" class="w_full" name="" id="" placeholder="000000" disabled> -->
                <!-- <small class="red_txt">내용을 입력해 주세요.</small> -->
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                카테고리<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic">
                    <!-- <select name="대분류" id="대분류" v-model="selectedLCategory" disabled> -->
                    <select name="대분류" id="대분류" disabled>
                        <option selected>{{ lCategoryTitle }}</option>
                        <!-- <option :value="0">선택</option>
                        <option
                            v-for="(c, index) in lCategories" :key=index
                            :value="index+1"
                        >{{ c.broadCategoryName }}</option> -->
                    </select>
                    <!-- <select name="중분류" id="중분류" v-model="selectedMCategory" disabled> -->
                    <select name="중분류" id="중분류" disabled>
                        <option selected>{{ mCategoryTitle }}</option>
                        <!-- <option :value="0">선택</option>
                        <option
                            v-for="(c, index) in mCategories" :key="index"
                            :value="index+1">{{ c.mediumCategoryName }}</option> -->
                    </select>
                    <!-- <select name="소분류" id="소분류" v-model="selectedSCategory" disabled> -->
                    <select name="소분류" id="소분류" disabled>
                        <option selected>{{ sCategoryTitle }}</option>
                        <!-- <option :value="0">선택</option>
                        <option
                            v-for="(c, index) in sCategories" :key="index"
                            :value="index+1">{{ c.smallCategoryName }}</option> -->
                    </select>
                </div>
                <!-- <small class="red_txt" v-if="needCategory">카테고리를 선택해 주세요.</small> -->
            </div>
        </li>
        <li class="settings_list flex_aic_spb gap_10">
            <button class="btn_l btn_gray" :disabled="!auth.v2HospitalIntegration_w" @click="action_Clear">연동해제</button>
            <button class="btn_l btn_blue" :disabled="!auth.v2HospitalIntegration_w" @click="action_Save">저장</button>
        </li>
    </div>
</template>


<script>
export default {
    props: {
        serviceSetting: {
            type: Object,
            default: () => { return {} }
        },
        history: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            categories: [],

            usingKakaoAlimAndFriendTalkLinkage: false,
            selectedLCategory: 0,
            selectedMCategory: 0,
            selectedSCategory: 0,
            selectedCode: undefined
        }
    },
    watch: {
        serviceSetting: {
            immediate: true,
            handler: function(newValue) {
                if (newValue._id == undefined) { return }
                this.usingKakaoAlimAndFriendTalkLinkage = newValue.usingKakaoAlimAndFriendTalkLinkage
            }
        },
        history: {
            immediate: true,
            handler: function(newValue) {
                if (newValue._id == undefined) { return }
                console.log('here')
                let code = this.history?.categoryInformation?.categoryCode
                let lIndexString = code.substring(0, 3)
                let mIndexString = code.substring(3, 7)
                let sIndexString = code.substring(8, 13)
                this.selectedLCategory = Number(lIndexString)
                this.selectedMCategory = Number(mIndexString)
                this.selectedSCategory = Number(sIndexString)
                console.log(lIndexString, mIndexString, sIndexString)
                console.log(this.selectedLCategory, this.selectedMCategory, this.selectedSCategory)
            }
        },
        
        selectedSCategory: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                if (this.selectedSCategory - 1 < 0) { return }
                let code = this.sCategories[this.selectedSCategory - 1].code
                this.selectedCode = code
            }
        },
        selectedCode: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.updateCode()
            }
        },
        usingKakaoAlimAndFriendTalkLinkage: {
            handler: function() {
                this.updateServiceSetting()
            }
        }
    },
    computed:{
        pf_id: function() {
            return this.history.searchableChannelId ?? ''
        },
        groupTitle: function() {
            let info = this.history?.detailInfo ?? {}
            let groups = info.groups ?? []
            let groupTitles = groups.map( (g) => { return g.name })
            return groupTitles.length != 0 ? groupTitles.join(', ') : '등록안함'
        },
        phone: function() {
            return this.history.channelAdminPhone ?? '-'
        },

        lCategories: function() {
            return this.categories
        },
        mCategories: function() {
            if (this.selectedLCategory == undefined) { return [] }
            if (this.selectedLCategory - 1 < 0) { return [] }
            return this.lCategories[this.selectedLCategory - 1]?.subCategory ?? []
        },
        sCategories: function() {
            if (this.selectedMCategory == undefined) { return [] }
            if (this.selectedMCategory - 1 < 0) { return [] }
            return this.mCategories[this.selectedMCategory - 1]?.smallCategory ?? []
        },
        needCategory: function() {
            let code = this.history?.categoryInformation?.categoryCode
            return code == undefined || code == ''
        },

        canEditCategory: function() {
            if (this.history._id != undefined) { return true }
            return false
        },
        lCategoryTitle: function() { return this.history?.categoryInformation?.broadCategory ?? '-' },
        mCategoryTitle: function() { return this.history?.categoryInformation?.mediumCategory ?? '-' },
        sCategoryTitle: function() { return this.history?.categoryInformation?.smallCategory ?? '-' },
    },
    mounted() {
        this.loadCategories()
    },
    methods: {
        updateCode: function() {
            // this.$emit('updateCategoryCode', this.selectedCode)
        },
        updateServiceSetting: function() {
            this.$emit('updateServiceSetting', this.usingKakaoAlimAndFriendTalkLinkage)
        },

        // MARK: - Network
        async loadCategories() {
            let result = await this.API_V3.Public.ThirdParty.Kakao.categories()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.categories = result.data.data
        },

        // MARK: - Actions
        action_OpenFriendLoadPopup() {
            this.$emit('openFriendLoadPopup')
        },
        action_Save() {
            this.$emit('saveFriendHistory')
        },
        action_Clear() {
            this.$emit('clearFiendHistory')
        },
    }
}
</script>