import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.HEALTH_CONTENT_CATEGORY_FIND,
        params: query,
        headers: Util.getHeaders()
    })
}