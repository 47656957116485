const AWS = require('aws-sdk')

const BUCKET_NAME = 'afterdoc'
const ID = process.env.VUE_APP_AWS_ID
const SECRET = process.env.VUE_APP_AWS_KEY

const VIDEO_BUCKET_NAME = 'afterdoc-video'

const File = require('../api/File').default
const moment = require('./MPMoment')

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
    region: 'ap-northeast-2'
})

const uploadFile = async function (file, userID) {
    let params = {
        Bucket: BUCKET_NAME,
        Key: userID + '/' + moment().format('YYYYMMDDHHmmSS') + '/' + file.name,
        Body: file,
        ACL: 'public-read'
    }

    return new Promise(function (resolve, reject) {
        s3.upload(params, function (err, res) {
            if (err != undefined) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    })
}

const uploadFiles = async function (files, sizes, mimeType, type, userID, flags) {
    var model = {}
    model.userID = userID
    model.type = type
    model.paths = []

    var aws = []
    for (var i = 0; i < files.length; i++) {
        console.log(sizes, mimeType)
        let file = files[i]
        var awsResult = await uploadFile(file, userID)
        console.log('aws result', awsResult)
        awsResult.size = sizes[i]
        awsResult.fileSize = file.size
        awsResult.fileName = file.name
        model.paths.push(awsResult.Location)
        aws.push(awsResult)
        if (flags != undefined) {
            flags.push(true)
        }
    }
    model.aws = aws

    return await File.createFile(model)
}

const uploadToAWS = async function(files, sizes, mimeType, type, userID, flags) {
    var model = {}
    model.userID = userID
    model.type = type
    model.paths = []

    var aws = []
    for (var i = 0; i < files.length; i++) {
        console.log(sizes, mimeType)
        let file = files[i]
        var awsResult = await uploadFile(file, userID)
        console.log('aws result', awsResult)
        awsResult.size = sizes[i]
        awsResult.fileSize = file.size
        awsResult.fileName = file.name
        model.paths.push(awsResult.Location)
        aws.push(awsResult)
        if (flags != undefined) {
            flags.push(true)
        }
    }

    return aws
}

let prepareHLSVideo = function (newHLSVideoFiles) {
    let title = newHLSVideoFiles.title
    let folderName = 'HLSVideo/' + moment().format('YYYYMMDDHHmmSS') + title + '/'

    // prepare 1080p
    for (let i = 0; i < newHLSVideoFiles.p1080.length; i++) {
        let f = newHLSVideoFiles.p1080[i]
        let bucketParam = {
            Bucket: VIDEO_BUCKET_NAME,
            Key: folderName + '1080/' + f.name,
            Body: f,
            ACL: 'public-read'
        }
        f.bucketParam = bucketParam
        newHLSVideoFiles.p1080[i] = f
    }

    for (let i = 0; i < newHLSVideoFiles.p720.length; i++) {
        let f = newHLSVideoFiles.p720[i]
        let bucketParam = {
            Bucket: VIDEO_BUCKET_NAME,
            Key: folderName + '720/' + f.name,
            Body: f,
            ACL: 'public-read'
        }
        f.bucketParam = bucketParam
        newHLSVideoFiles.p720[i] = f
    }

    for (let i = 0; i < newHLSVideoFiles.p360.length; i++) {
        let f = newHLSVideoFiles.p360[i]
        let bucketParam = {
            Bucket: VIDEO_BUCKET_NAME,
            Key: folderName + '360/' + f.name,
            Body: f,
            ACL: 'public-read'
        }
        f.bucketParam = bucketParam
        newHLSVideoFiles.p360[i] = f
    }

    return newHLSVideoFiles
}

const uploadHLSVideo = async function (newHLSVideoFiles, progressFunction) {
    newHLSVideoFiles = prepareHLSVideo(newHLSVideoFiles)

    let Credential = new AWS.Credentials(ID, SECRET)
    let s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        credentials: Credential,
        region: 'ap-northeast-2',
        params: {
            Origin: '*'
        }
    })

    var uploadSingleFile = async function (f) {
        return new Promise(function (resolve, reject) {
            try {
                s3.putObject(f.bucketParam, function (err, res) {
                    if (err == undefined) {
                        f.res = res
                    } else {
                        f.err = err
                        console.log(err)
                    }

                    resolve(f)
                })
            } catch (err) {
                reject(err)
            }
        })
    }

    var uploadAll = async function (newHLSVideoFiles, progressFunction) {
        for (let i = 0; i < newHLSVideoFiles.p1080.length; i++) {
            newHLSVideoFiles.p1080[i] = await uploadSingleFile(newHLSVideoFiles.p1080[i])
            newHLSVideoFiles.uploadCount += 1
            progressFunction(newHLSVideoFiles.uploadCount)
        }
        for (let i = 0; i < newHLSVideoFiles.p720.length; i++) {
            newHLSVideoFiles.p720[i] = await uploadSingleFile(newHLSVideoFiles.p720[i])
            newHLSVideoFiles.uploadCount += 1
            progressFunction(newHLSVideoFiles.uploadCount)
        }
        for (let i = 0; i < newHLSVideoFiles.p360.length; i++) {
            newHLSVideoFiles.p360[i] = await uploadSingleFile(newHLSVideoFiles.p360[i])
            newHLSVideoFiles.uploadCount += 1
            progressFunction(newHLSVideoFiles.uploadCount)
        }
        return newHLSVideoFiles
    }

    return new Promise(function (resolve, reject) {
        try {
            uploadAll(newHLSVideoFiles, progressFunction).then((result) => {
                resolve(result)
                console.log(result)
            })
        } catch (err) {
            reject(err)
        }
    })
}

const uploadHLSm3u8 = async function (model) {
    let Credential = new AWS.Credentials(ID, SECRET)
    let s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        credentials: Credential,
        region: 'ap-northeast-2',
        params: {
            Origin: '*'
        }
    })

    try {
        return new Promise(function (resolve, reject) {
            let folderName = model.aws[0].Key.replaceAll(model.aws[0].fileName, '').replaceAll('1080/', '').replaceAll('720/', '').replaceAll('360/', '')
            var bucket_1080p = {
                Bucket: VIDEO_BUCKET_NAME,
                Key: folderName + '1080/' + '1080.m3u8',
                Body: model.p1080_m3u8_fileString,
                ACL: 'public-read'
            }
            var bucket_720p = {
                Bucket: VIDEO_BUCKET_NAME,
                Key: folderName + '720/' + '720.m3u8',
                Body: model.p720_m3u8_fileString,
                ACL: 'public-read'
            }
            var bucket_360p = {
                Bucket: VIDEO_BUCKET_NAME,
                Key: folderName + '360/' + '360.m3u8',
                Body: model.p360_m3u8_fileString,
                ACL: 'public-read'
            }
            var resolveHandle = function () {
                if (model.p1080_m3u8_path != undefined && model.p720_m3u8_path != undefined && model.p360_m3u8_path != undefined) {
                    resolve(model)
                }
            }
            s3.putObject(bucket_1080p, function (err, res) {
                if (err == undefined) {
                    console.log(res)
                    model.p1080_m3u8_path = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/' + bucket_1080p.Key
                    resolveHandle()
                } else {
                    reject(err)
                }
            })
            s3.putObject(bucket_720p, function (err, res) {
                if (err == undefined) {
                    console.log(res)
                    model.p720_m3u8_path = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/' + bucket_720p.Key
                    resolveHandle()
                } else {
                    reject(err)
                }
            })
            s3.putObject(bucket_360p, function (err, res) {
                if (err == undefined) {
                    console.log(res)
                    model.p360_m3u8_path = 'https://afterdoc-video.s3.ap-northeast-2.amazonaws.com/' + bucket_360p.Key
                    resolveHandle()
                } else {
                    reject(err)
                }
            })
        })
    } catch (err) {
        console.log(err)
    }
}

export default {
    uploadFile: uploadFile,
    uploadFiles: uploadFiles,
    uploadToAWS: uploadToAWS,
    uploadHLSVideo: uploadHLSVideo,
    uploadHLSm3u8: uploadHLSm3u8
}
