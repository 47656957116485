<template>
    <div class="manual_img flex_column gap_10">
        <div class="manual_img_preview">
            <button class="img_delete" v-if="hasImage" @click="action_Remove">
                <v-icon>mdi-close</v-icon>
            </button>
            <div class="preview_img_wrap">
                <img :src="urlString" alt="미리보기 이미지" v-if="hasImage">
            </div>
        </div>
        <div class="manual_img_upload flex_column gap_5">
            <label for="img_upload" class="img_add btn_blue_line txt_center" @click="action_Regist">등록</label>
            <small class="lgray_txt">
                <span class="ellipsis" style="max-width: 160px;">{{ fileName }}</span>
            </small>
        </div>
        <FilePicker v-model="selectFile" v-bind:only-image="true" v-bind:is-multiple="false" v-bind:is-open="isShowFilePicker" />
    </div>
</template>


<script>
import { FileUploadController } from '@/controller/FileUploadController'
import FilePicker from '@/views/main/ShareComponents/FilePicker.vue'

export default {
    components: { FilePicker },
    props: {
        index: Number,
        item: {
            type: Object,
            default: () => undefined
        }
    },
    data() {
        return {
            isShowFilePicker: false,
            selectFile: undefined,
        }
    },
    watch: {
        selectFile: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.createFile(newValue)
                this.isShowFilePicker = false
            }
        }
    },
    computed: {
        urlString: function() {
            return this.item?.Location ?? ''
        },
        fileName: function() {
            return this.item?.fileName ?? ''
        },
        hasImage: function() {
            return this.urlString != ''
        }
    },
    methods: {
        // MARK: - Network
        uploadFile(files) {
            return new Promise( (resolve, reject) => {
                FileUploadController.uploadFiles(files, [], async (results) => {
                    console.log(results)
                    let datas = results.data
                    if (datas.length == 0) {
                        reject()
                        return
                    }

                    resolve(datas[0])
                }, true)
            })
        },
        async createFile(files) {
            if (files == undefined || files?.length == 0) { return }
            let awsDatas = await this.uploadFile(files)
            let data = awsDatas[0]
            data.index = this.index
            this.$emit('updateItem', data)
        },

        // MARK: - Actions
        action_Remove() {
            this.$emit('updateItem', {index: this.index})
        },
        action_Regist() {
            this.isShowFilePicker = true
        },
    }
}
</script>