<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="hospital_phone_edit_popup_wrap">
            <div class="pop_title">
                <span class="title">병원 문자발송 전화번호 수정</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon large>mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="pop_scroll">
                    <div class="form_list">
                        <div class="title">문자발송 전화번호</div>
                        <div class="cont">
                            <input v-model="smsPhone" type="text" class="input_s" placeholder="07051804070" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">비즈뿌리오 모듈 사용</div>
                        <div class="cont">
                            <input type="checkbox" v-model="usingBizPpurioMessageService"/>
                        </div>
                    </div>
                    <div class="btn_wrap">
                        <button class="btn btn_l btn_blue" @click="action_Update()">저장</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        isShow: Boolean,
        hospitalID: String,
    },
    data() {
        return {
            isLoading: false,

            smsPhone: undefined,
            serviceSettingID: undefined,
            usingBizPpurioMessageService: false,
        }
    },
    watch: {
        hospitalID: function(newValue) {
            if (newValue != undefined) {
                this.loadHospital()
            }
        }
    },
    methods: {
        // MARK: - Network
        async loadHospital() {
            if (this.hospitalID == undefined) { return }
            this.isLoading = true
            let result = await this.API.Hospital.getHospitals({_id: this.hospitalID})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let datas = result.data.data
            this.smsPhone = datas[0].smsPhone
            this.loadServiceSetting()
            this.isLoading = false
        },
        async loadServiceSetting() {
            if (this.hospitalID == undefined) { return }
            this.isLoading = true
            let result = await this.API.ServiceSetting.getServiceSettingDetail({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.serviceSettingID = result.data.data._id
            this.usingBizPpurioMessageService = result.data.data.usingBizPpurioMessageService
        },
        async updateHospital() {
            if (this.hospitalID == undefined) { return }
            let updateModel = {
                _id: this.hospitalID,
                smsPhone: this.smsPhone
            }
            let result = await this.API.Hospital.updateHospital(updateModel)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            await this.updateServiceSetting()
            this.action_Close()
        },
        async updateServiceSetting() {
            if (this.hospitalID == undefined) { return }
            let updateModel = {
                _id: this.serviceSettingID,
                hospitalID: this.hospitalID,
                usingBizPpurioMessageService: this.usingBizPpurioMessageService
            }
            let result = await this.API.ServiceSetting.updateServiceSetting(updateModel)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
        },

        // MARK: - Actions
        action_Close() {
            this.smsPhone = undefined
            this.usingBizPpurioMessageService = false
            this.serviceSettingID = undefined
            this.$emit('action_Close')
        },
        action_Update() {
            this.updateHospital()
        }
    }
}
</script>


<style scoped>
.hospital_phone_edit_popup_wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    align-self: center;
    background: #fff;
    border-radius: 16px;
    animation: slide-in-big 0.3s ease-in-out forwards;
    z-index: 507;
    width: 500px;
    height: 270px;
}
</style>