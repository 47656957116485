<template>
    <li class="automation_list flex_column gap_20" @click="action_ShowTemplateDetail">
        <div class="automation_name flex_stt_spb gap_15">
            <b>{{ title }}</b>
            <button class="toggle_btn_wrap" @click.stop="action_ChangeActive">
                <input type="checkbox" class="toggle_input" :checked="isActive" readonly>
                <span class="toggle_label"></span>
            </button>
        </div>
        <div class="automation_tag_wrap">
            <span
                v-for="(tag, index) in tags" :key="index"
            >{{ tag.categoryName }}</span>
        </div>
        <div class="flex_aic_fle">
            <button class="flex_aic gap_5" @click.stop="action_Remove">
                <v-icon class="dgray_txt">mdi-delete</v-icon>
                <span class="dgray_txt">삭제</span>
            </button>
        </div>
    </li>
</template>


<script>
export default {
    props: {
        template: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {}
    },
    watch: {},
    computed: {
        title: function() { return this.template.name ?? '-' },
        tags: function() { return this.template.targetTreatmentTags ?? [] },
        isActive: function() { return this.template.isActive },
        statistics: function() { return this.template.statistics },
        targetCount: function() { return this.statistics.targetCount ?? 0 },
        readCount: function() { return this.statistics.readCount ?? 0 },
        responseCount: function() { return this.statistics.responseCount ?? 0 },
    },
    methods: {
        action_ChangeActive() {
            console.log('change here')
            this.$emit('update', this.template)
        },
        action_Remove() {
            this.$emit('remove', this.template)
        },
        action_ShowTemplateDetail() {
            this.$emit('detail', this.template)
        }
    },
}
</script>