class PageController {
    constructor(api, filter = {}, countPerPage = 10) {
        this.api = api
        this.filter = filter

        this.data = []
        this.skip = 0
        this.countPerPage = countPerPage
        this.isEnd = false
        this.isLoading = false
        this.totalCount = 0
    }

    async loadFirstPage() {
        if (this.isLoading == true) {
            return
        }
        this.isLoading = true
        this.data = []
        this.filter.skip = 0
        this.filter.limit = this.countPerPage

        let result = await this.api(this.filter)
        // console.log(result)
        if (result.data.code != 0) {
            console.log(result)
            this.isLoading = false
            return
        }
        let datas = result.data.data
        this.data = datas
        this.isLoading = false
    }

    async loadNextPage() {
        if (this.isLoading == true) {
            return
        }
        if (this.isEnd == true) {
            return
        }
        this.isLoading = true
        this.filter.skip = this.data.length
        this.filter.limit = this.countPerPage
        let result = await this.api(this.filter)
        console.log(result)
        if (result.data.code != 0) {
            console.log(result)
            this.isLoading = false
            return
        }
        let datas = result.data.data
        this.data = this.data.concat(datas)
        if (this.data.length < this.countPerPage) {
            this.isEnd = true
        }
        this.isLoading = false
    }

    async reloadPage() {
        if (this.isLoading == true) {
            return
        }
        this.isEnd = false

        this.filter.skip = 0
        this.filter.limit = this.data.length
        this.isLoading = true
        let result = await this.api(this.filter)
        if (result.data.code != 0) {
            console.log(result)
            this.isLoading = false
            return
        }
        let datas = result.data.data
        this.data = datas
        this.isLoading = false
    }

    async loadPageIndex(index) {
        if (this.isLoading == true) {
            return
        }
        this.isEnd = false

        this.filter.skip = index * this.countPerPage
        this.filter.limit = this.countPerPage
        this.isLoading = true
        let result = await this.api(this.filter)
        if (result.data.code != 0) {
            console.log(result)
            this.data = []
            this.isLoading = false
            return
        }
        this.data = result.data.data
        this.isLoading = false
    }

    async loadPageIntegrationSetting(index) {
        if (this.isLoading == true) {
            return
        }
        this.isEnd = false
        this.isLoading = true

        let result = await this.api(this.filter)
        if (result.data.code != 0) {
            console.log(result)
            this.data = []
            this.isLoading = false
            return
        }
        result = result.data.data
        result = result.filter(obj => Object.prototype.hasOwnProperty.call(obj, 'integrationSetting'))
        
        this.totalCount = result.length

        let r_index = function() {
            if(index == 0) { return 0 }
            return index * 10
        }
        let r_limit = function() {
            if(index == 0) { return 10 }
            return (index + 1) * 10
        }
        result = result.slice(r_index(), r_limit())

        this.data = result
        this.isLoading = false
    }

    // si message page load
    async loadSIPageIndex(index) {
        if (this.isLoading == true) {
            return
        }
        this.isEnd = false

        this.filter.skip = index * this.countPerPage
        this.filter.limit = this.countPerPage
        this.isLoading = true

        let result = await this.api(this.filter)

        // SI-API is using http response codes. checking status.
        if (result.status != 200) {
            console.log(result)
            this.data = []
            this.isLoading = false
            return
        }
        this.data = result.data
        this.isLoading = false
    }
}

export default PageController
