<template>
    <tr>
        <td>
            <b>D+{{ daysAfter }} {{ timeOfDay }}</b><br>
            <small class="lgray_txt txt_break">{{ messageType }}</small>
        </td>
        <td>
            <small class="txt_break">{{ messageTitle }}</small>
        </td>
        <td>
            <small class="txt_break">{{ profileTitle }}</small>
        </td>
        <td>
            <small class="txt_break">{{ templateTitle }}</small><br>
            <small class="lgray_txt txt_break" v-if="templateCode != undefined">{{ templateCode }}</small>
        </td>
        <td>
            <span class="label" :class="statusClass">{{ statusTitle }}</span>
        </td>
        <td v-if="contentType == 'TEXT'">
            <small class="txt_break" v-if="textContent != undefined">{{ textContent }}</small>
        </td>
        <td v-if="contentType == 'IMAGE'">
            <small class="txt_break" v-if="textContent != undefined">{{ textContent }}</small><br>
            <small class="img_preview_wrap lgray_txt" v-if="imageContent">
                {{ imageContent.name }}
                <span class="img_preview">
                    <img :src="imageContent.path"/>
                </span>
            </small>
        </td>
        <td v-if="contentType == 'VIDEO'">
            <small class="txt_break" v-if="textContent != undefined">{{ textContent }}</small><br>
            <small class="img_preview_wrap lgray_txt" v-if="videoContent">
                {{ videoContent.name }}
            </small>
        </td>
        <td v-if="contentType == 'WORKOUT_VIDEO'">
            <small class="txt_break" v-if="textContent">{{ textContent }}</small><br>
            <small class="img_preview_wrap lgray_txt">
                {{ workoutContent.title }}
            </small>
        </td>
        <td v-if="contentType == 'EVENT'">
            <small class="txt_break" v-if="textContent">{{ textContent }}</small><br>
            <small class="img_preview_wrap lgray_txt">
                {{ eventContent.title }}
            </small>
        </td>
        <td v-if="contentType == 'HOMECARE_CONTENT'">                                
            <small class="txt_break" v-if="textContent">{{ textContent }}</small><br>
            <small class="img_preview_wrap lgray_txt">
                {{ homecareContent.title }}
            </small>
        </td>
        <td v-if="contentType == 'SURVEY'">
            <small class="txt_break">{{ surveyContent.question }}</small><br>
            <div
                v-for="(answer, index) in surveyContent.answers"
                :key="index"><small class="txt_break">{{ answer }}</small></div>
        </td>
    </tr>
</template>


<script>

export default {
    props: {
        message: { type: Object, default: () => { return {} } },
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
    },
    data() {
        return {
            imageFile: undefined,
            videoFile: undefined,
            workoutVideo: undefined,
            homecare: undefined,
            event: undefined
        }
    },
    watch: {
        message: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue._id == undefined) { return }
                this.loadFiles()
            }
        }
    },
    computed: {
        daysAfter: function() { return this.message.daysAfter ?? '0'},
        timeOfDay: function() { return this.message.timeOfDay  ?? '-'},
        sendingType: function() {
            if (this.message.sendingType == 'BASIC') { return '기본' }
            else if (this.message.sendingType == 'REVISIT_INDUCEMENT') { return '필수' }
            return '설문'
        },
        contentTypeString: function() {
            if (this.sendingType == '설문') { return undefined }
            else if (this.contentType == 'TEXT') { return '텍스트' }
            else if (this.contentType == 'IMAGE') { return '이미지' }
            else if (this.contentType == 'VIDEO') { return '비디오' }
            else if (this.contentType == 'WORKOUT_VIDEO') { return '운동콘텐츠' }
            else if (this.contentType == 'HOMECARE_CONTENT') { return '홈케어콘텐츠' }
            else if (this.contentType == 'EVENT') { return '이벤트' }
            return undefined
        },
        messageType: function() {
            if (this.sendingType == '설문') { return '[' + this.sendingType + ']'}
            return '[' + this.sendingType + '/' + this.contentTypeString + ']'
        },
        messageTitle: function() { return this.message.name ?? '-' },
        senderKey: function() { return this.message?.kakaoTemplateHistoryId?.senderKey ?? '-' },
        profileTitle: function() {
            let group = this.kakaoGroups.find( (g) => g.groupKey == this.senderKey )
            if (group != undefined) { return '(그룹)' + group?.name }
            let profile = this.kakaoProfiles.find( (p) => p.senderKey == this.senderKey )
            return profile?.uuid ?? '-'
        },
        templateTitle: function() { return this.message.kakaoTemplateHistoryId?.templateName ?? '-' },
        templateCode: function() { return this.message.kakaoTemplateHistoryId?.templateCode },
        serviceStatus: function() { return this.message.kakaoTemplateHistoryId?.serviceStatus },
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG', class: 'lgray'},
                {name: '검수요청',  key: 'REQ', class: 'lgray'},
                {name: '반려',      key: 'REJ', class: 'lred'},
                {name: '차단',      key: 'STP', class: 'lred'},
                {name: '발송전',    key: 'RDY', class: 'lgreen'},
                {name: '정상',      key: 'ACT', class: 'lgreen'},
                {name: '휴면',      key: 'DMT', class: 'lgray'},
                {name: '차단',      key: 'BLK', class: 'lgray'},
            ]
        },
        statusTitle: function() {
            let s = this.statusKind.find( (s) => s.key == this.serviceStatus )
            return s?.name ?? undefined
        },
        statusClass: function() {
            let s = this.statusKind.find( (s) => s.key == this.serviceStatus )
            return s?.class ?? undefined
        },
        contentType: function() {
            let sendingType = this.message.sendingType
            let contentType = this.message.contentType
            if (sendingType == 'SURVEY') { return sendingType }
            else if (contentType == 'TEXT') { return contentType }
            else if (contentType == 'IMAGE') { return contentType }
            else if (contentType == 'VIDEO') { return contentType }
            else {
                if (this.message.content?.video != undefined) { return 'VIDEO' }
                else if (this.message.content?.image != undefined) { return 'IMAGE' }
                else if (this.message.content?.workoutVideo != undefined) { return 'WORKOUT_VIDEO' }
                else if (this.message.content?.homecareContent != undefined) { return 'HOMECARE_CONTENT' }
                else if (this.message.content?.event != undefined) { return 'EVENT' }
                else if (this.message.content?.text != undefined) { return 'TEXT' }
            }
            return undefined
        },
        textContent: function() {
            if (this.contentType == 'SURVEY') { return undefined }
            return this.message.content?.text
        },
        imageContent: function() {
            if (this.contentType != 'IMAGE') { return undefined }
            if (this.imageFile != undefined && this.imageFile?.aws != undefined && this.imageFile?.aws?.length != 0) {
                return {
                    name: this.imageFile.aws[0].fileName,
                    path: this.imageFile.aws[0].Location
                }
            }
            return undefined
        },
        videoContent: function() {
            if (this.contentType != 'VIDEO') { return undefined }
            if (this.videoFile != undefined && this.videoFile?.aws != undefined && this.videoFile?.aws?.length != 0) {
                return { name : this.videoFile.aws[0].fileName }
            }
            return undefined
        },
        homecareContent: function() {
            if (this.contentType != 'HOMECARE_CONTENT') { return undefined }
            if (this.homecare != undefined) {
                return { title: this.homecare.title }
            }
            return {}
        },
        workoutContent: function() {
            if (this.contentType != 'WORKOUT_VIDEO') { return undefined }
            if (this.workoutVideo != undefined) {
                return { title: this.workoutVideo?.title }
            }
            return {}
        },
        eventContent: function() {
            if (this.contentType != 'EVENT') { return undefined }
            if (this.event != undefined) {
                return { title: this.event?.title }
            }
            return {}
        },
        surveyContent: function() {
            if (this.contentType != 'SURVEY') { return undefined }
            return this.message.content?.survey
        }
    },
    methods: {
        async loadFiles() {
            let imageFileID = this.message.content?.image
            if (imageFileID != undefined) {
                let result = await this.API_V3.File.findOne({_id: imageFileID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                } else {
                    this.imageFile = result.data.data
                }
            }

            let videoFileID = this.message.content?.video
            if (videoFileID != undefined) {
                let result = await this.API_V3.File.findOne({_id: videoFileID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                } else {
                    this.videoFile = result.data.data
                }
            }

            let videoContentID = this.message.content?.workoutVideo
            if (videoContentID != undefined) {
                let result = await this.API_V3.AutomationHospital.findVideoContent({_id: videoContentID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                } else {
                    this.workoutVideo = result.data.data
                }
            }

            let homecareID = this.message.content?.homecareContent
            if (homecareID != undefined) {
                let result = await this.API_V3.AutomationHospital.findHospitalAftercare({_id: homecareID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                } else {
                    this.homecare = result.data.data
                }
            }

            let eventID = this.message.content?.event
            if (eventID != undefined) {
                let result = await this.API_V3.AutomationHospital.findEvent({_id: eventID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                } else {
                    this.event = result.data.data
                }
            }
        }
    }
}
</script>