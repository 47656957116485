<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>계정 설정</span>
        </div>
        <div class="cc">
            <div class="account_set_wrap">
                <div class="account_set left">
                    <div class="form_flex_wrap">
                        <div class="form_flex_title">
                            <span class="title">병원 선택</span>
                            <input
                                type="text"
                                class="input_xs mgl_auto w_150"
                                placeholder="병원명 검색"
                                v-model="searchKeyword"
                                @keyup.enter="loadHospitals()"
                            />
                        </div>
                        <div class="form_flex_cont">
                            <div class="h_scroll_fix">
                                <!-- default hospitals -->
                                <table class="table_default" v-if="hospitals.length == 0">
                                    <tr class="on">
                                        <td>테스트 병원</td>
                                        <td class="txt_right">계정 99개</td>
                                    </tr>
                                </table>
                                <!-- load hospitals -->
                                <table class="table_default" v-if="hospitals.length > 0">
                                    <tr
                                        v-for="h in hospitals"
                                        :key="h._id"
                                        :value="h._id"
                                        :class="{ on: h._id == selectedHospitalID }"
                                        @click="loadHWorkersByHospital(h)"
                                    >
                                        <td>
                                            <div>{{ h.shortName }}</div>
                                            <div>
                                                <small class="lgray_txt">{{ h.name }}</small>
                                            </div>
                                        </td>
                                        <td class="txt_right">계정 {{ h.doctorCount }}개</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account_set right">
                    <div class="form_flex_wrap">
                        <div class="form_flex_title">
                            <span class="title" v-if="selectedHospitalID == ''">계정 선택</span>
                            <span class="title" v-if="selectedHospitalID != ''">
                                {{ selectedHospital.shortName }}<small class="lgray_txt">({{ selectedHospital.name }})</small>
                            </span>
                            <button v-if="selectedHospitalID != ''" class="btn_xs btn_blue btn" @click="action_ShowCreateHWorkerPopup()">추가</button>
                        </div>
                        <div class="form_flex_cont">
                            <div class="h_scroll_fix">
                                <!-- default doctors -->
                                <table class="table_default" v-if="selectedHospitalID == ''">
                                    <tr>
                                        <td colspan="4">왼쪽 병원 선택 메뉴에서 병원을 선택해주세요.</td>
                                    </tr>
                                </table>
                                <!-- load doctors -->
                                <table class="table_default" v-if="selectedHospitalID != ''">
                                    <tr>
                                        <th>이름</th>
                                        <th>고객이 보는 이름</th>
                                        <th>직책 (ID)</th>
                                        <th>권한</th>
                                        <th>담당의 여부</th>
                                        <th>프로필 사진</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(hw, index) in hworkers" :key="hw._id" :value="hw._id">
                                        <td>
                                            <b>{{ hw ? (hw.realName ? hw.realName : '-') : '-' }}</b>
                                        </td>
                                        <td>{{ hw ? (hw.name ? hw.name : '-') : '-' }}</td>
                                        <td>
                                            {{ hw.jobType }} <small class="lgray_txt">({{ hw.id }})</small>
                                        </td>
                                        <td>{{ hw.authorizationTypeID ? hw.authorizationTypeID.name : '-' }}</td>
                                        <td>
                                            <label class="label" :class="{ lblue: hw.isDoctor == true, lgray: hw.isDoctor != true }">
                                                {{ hw.isDoctor ? 'O' : 'X' }}
                                            </label>
                                        </td>
                                        <td>
                                            <small
                                                :id="index"
                                                @mouseenter="profileImageHover('on')"
                                                @mouseleave="profileImageHover('off')"
                                                class="lgray_txt mgt_10 img_preview_wrap"
                                            >
                                                FileID : {{ hw.photo?._id }}
                                                <span class="img_preview">
                                                    <img v-if="profileImageHoverStatus == true" :src="hw.photo?.paths[0]" alt="" />
                                                </span>
                                            </small>
                                        </td>
                                        <td class="txt_right">
                                            <!-- <button class="btn_xs btn_gray_line btn" @click="action_ShowQRCodeHWorkerPopup(hw)">QR보기</button> -->
                                            <button class="btn_xs btn_blue_line btn" @click="action_ShowEditHWorkerPopup(hw)">수정</button>
                                            <button class="btn_xs btn_gray btn">삭제</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AccountSettingPopup v-on:loadHospitals="loadHospitals" v-on:loadHWorkersByHospital="loadHWorkersByHospital" />
        <AccountSettingQRCodePopup />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AccountSettingPopup from './Components/AccountSettingPopup'
import AccountSettingQRCodePopup from './Components/AccountQRCodePopup'

export default {
    components: { AccountSettingPopup, AccountSettingQRCodePopup },
    data() {
        return {
            selectedHospital: {},
            selectedHospitalID: '',
            searchKeyword: '',

            hospitals: [],
            hworkers: [],
            profileImageHoverStatus: false
        }
    },
    watch: {
        searchKeyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.loadHospitals()
                }
            }
        }
    },
    computed: {
        ...mapState(['accountsettingpopup', 'accountqrcodepopup'])
    },
    mounted() {
        this.loadHospitals()
    },
    methods: {
        ...mapActions(['setAccountSettingPopupState', 'setAccountSettingQRCodePopupState']),

        initData() {},
        async loadHospitals() {
            let model = {}
            if (this.searchKeyword) {
                model.keyword = this.searchKeyword,
                model.isEnabledSaaS = false
            }
            let result = await this.API.Hospital.getHospitals(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.hospitals = result.data.data
            this.hospitals.forEach(async (h) => {
                let hResult = await this.API.Hospital.countDoctors({ hospitalID: h._id })
                if (hResult.data.code != 0) {
                    console.log(hResult)
                    return
                }
                h.doctorCount = hResult.data.data
                this.$forceUpdate()
            })
        },

        async loadHWorkersByHospital(hospital) {
            if (hospital._id != undefined && hospital._id != '') {
                this.selectedHospital = hospital
                this.selectedHospitalID = hospital._id
            } else {
                this.selectedHospital = {}
                this.selectedHospitalID = ''
            }
            let model = {}
            if (!this.isEmptyObject(this.selectedHospital)) {
                model.hospitalID = this.selectedHospital._id
                let result = await this.API.Hospital.getDoctors(model)
                if (result.data.code != 0) {
                    console.log(result)
                    return
                }
                console.log(result.data.data)
                this.hworkers = result.data.data
                this.$forceUpdate()
            }
        },

        profileImageHover(status) {
            switch (status) {
                case 'on':
                    this.profileImageHoverStatus = true
                    break
                case 'off':
                    this.profileImageHoverStatus = false
            }
        },

        async action_ShowCreateHWorkerPopup() {
            this.setAccountSettingPopupState({
                selectedHospital: this.selectedHospital,
                selectedHospitalID: this.selectedHospitalID,
                isShow: true
            })
        },
        async action_ShowEditHWorkerPopup(hworker) {
            this.setAccountSettingPopupState({
                selectedHospital: this.selectedHospital,
                selectedHospitalID: this.selectedHospitalID,
                selectedHWorker: hworker,
                selectedHWorkerID: hworker._id,
                isShow: true
            })
        },

        // qrcode - deprecated 240111
        action_ShowQRCodeHWorkerPopup(hworker) {
            this.setAccountSettingQRCodePopupState({
                selectedHospital: this.selectedHospital,
                selectedHospitalID: this.selectedHospitalID,
                selectedHWorker: hworker,
                selectedHWorkerID: hworker._id,
                isShow: true
            })
        },

        isEmptyObject(obj) {
            for (var i in obj) return false
            return true
        }
    }
}
</script>
