<template>
    <tr>
        <td>
            <p style="max-width: 270px;">{{ title }}</p>
        </td>
        <td>
            <p style="max-width: 890px;">
                {{ description }}
            </p>
        </td>
        <td>
            <span>{{ date }}</span>
            <span class="mgl_5">{{ time }}</span>
        </td>
        <td>
            <div class="flex_aic_jcc">
                <button class="btn_gray btn_s w_full" @click="action_Remove" :disabled="!auth.treatmentCategory_w">삭제</button>
                <button class="btn_blue_line btn_s w_full" @click="action_Modify" :disabled="!auth.treatmentCategory_w">수정</button>
            </div>
        </td>
    </tr>
</template>


<script>
const moment = require('../../../../../../util/MPMoment')
export default {
    props: {
        item: Object,
    },
    computed: {
        title: function() {
            return this.item?.categoryName ?? '-'
        },
        description: function() {
            return this.item?.description ?? '-'
        },
        createdAt: function() {
            return this.item?.createdAt
        },
        date: function() {
            return moment(this.createdAt).format('YYYY-MM-DD')
        },
        time: function() {
            return moment(this.createdAt).format('HH:mm')
        },
    },
    methods: {
        // MARK: - Network
        async removeCategory() {
            let result = await this.API_V3.TreatmentCategory.remove({_id: this.item._id})
            if (result.data.code != 0) {
                alert(result)
                return
            }
            this.$emit('reloadData')
        },

        // MARK: - Actions
        action_Remove() {
            this.removeCategory()
        },
        action_Modify() {
            this.$emit('showEditPopup', this.item)
        },
    },
}
</script>