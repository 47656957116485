<template>
    <ul class="settings_tab flex_column mgt_20" v-if="isShow">
        <li
            :class="{'active': this.page == 'BasicOptions'}"
            @click="action_ChangePage('BasicOptions')"
        >기본설정</li>
        <li
            :class="{'active': this.page == 'NonBusinessHour'}"
            @click="action_ChangePage('NonBusinessHour')"
        >업무시간 외 팝업</li>
        <li
            :class="{'active': this.page == 'ResponseAutomation'}"
            @click="action_ChangePage('ResponseAutomation')"
        >자동 응대 메시지</li>
        <li
            :class="{'active': this.page == 'HomecareContents'}"
            @click="action_ChangePage('HomecareContents')"
        >홈케어 콘텐츠 설정</li>
        <li
            v-if="auth.v2HospitalIntegration_v"
            :class="{'active': this.page == 'SmartDoctorIntegration'}"
            @click="action_ChangePage('SmartDoctorIntegration')"
        >스마트닥터 연동</li>
        <li
            v-if="auth.v2HospitalIntegration_v"
            :class="{'active': this.page == 'KakaoIntegration'}"
            @click="action_ChangePage('KakaoIntegration')"
        >카카오톡 연동</li>
        <li
            :class="{'active': this.page == 'AccountSetting'}"
            @click="action_ChangePage('AccountSetting')"
        >계정 설정</li>
        <li
            :class="{'active': this.page == 'TreatmentTagSetting'}"
            @click="action_ChangePage('TreatmentTagSetting')"
        >치료태그 설정</li>
        <li
            :class="{'active': this.page == 'AutomationBasicTemplate'}"
            @click="action_ChangePage('AutomationBasicTemplate')"
        >상담자동화 기본 템플릿</li>
        <li
            v-if="auth.v2HospitalState_v"
            :class="{'active': this.page == 'ServiceStatus'}"
            @click="action_ChangePage('ServiceStatus')"
        >서비스 상태 변경</li>
    </ul>
</template>


<script>
export default {
    props: {
        hospital: Object,
        page: String,
    },
    computed: {
        isShow: function() {
            if (this.hospital?._id != undefined) { return true }
            return false
        },
    },
    methods: {
        action_ChangePage(page) {
            this.$emit('changePage', page)
        }
    }
}
</script>