<template>
    <tr>
        <td>
            <div class="flex_column">
                <p style=" max-width: 250px;">{{ shortName }}</p>
                <small class="lgray_txt">{{ hospitalID }}</small>
            </div>
        </td>
        <td class="txt_center">
            {{ createdAt }}
        </td>
        
        <!-- <td class="txt_center">
            <span>{{ smsPhone }}</span>
        </td> -->
        <td :title="smsBasicMessage">
            <span class="lgray_txt small_content">{{ smsBasicMessage }}</span>
        </td>
        <td :title="smsResendMessage">
            <span class="lgray_txt small_content">{{ smsResendMessage }}</span>
        </td>
        <td :title="smsReservation">
            <span class="lgray_txt small_content">{{ smsReservation }}</span>
        </td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        hospital: Object
    },
    computed: {
        createdAt: function() {
            return moment(this.hospital?.createdAt).format('YYYY-MM-DD') ?? '-'
        },
        shortName: function() {
            return this.hospital?.shortName ?? '-'
        },
        hospitalID: function() {
            return this.hospital?._id ?? '-'
        },
        // smsPhone: function() {
        //     return this.hospital?.smsPhone ?? '-'
        // },

        smsBasicMessage: function() {
            return this.hospital?.smsBasicMessage ?? '-'
        },
        smsResendMessage: function() {
            return this.hospital?.smsResendMessage ?? '-'
        },
        smsReservation: function() {
            return this.hospital?.smsReservation ?? '-'
        },
    },
}
</script>


<style>
.small_content {
    width: 300px;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>