<template>
    <div class="cc">
        <PhoneManagementSubNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>


<script>
import PhoneManagementSubNavigation from './PhoneManagementSubNavigation.vue'

export default {
    components: { PhoneManagementSubNavigation }
}
</script>