<template>
    <div class="snb">
        <div class="snb_title">관리 목록</div>
        <a
            @click="action_MessageManagement"
            class="snb_menu"
            :class="{ on: $router.currentRoute.path.includes('messagemanagementhospitallist') }"
        >메시지 관리</a>
        <a
            @click="action_PhoneManagement"
            class="snb_menu"
            :class="{ on: $router.currentRoute.path.includes('phonemanagementhospitallist') }"
        >번호 관리</a>
    </div>
</template>


<script>
export default {
    methods: {
        action_MessageManagement() {
            this.$router.push({ name: 'MessageManagementHospitalList' }).catch(() => {})
        },
        action_PhoneManagement() {
            this.$router.push({ name: 'PhoneManagementHospitalList' }).catch(() => {})
        }
    }
}
</script>