<template>
    <div style="flex-grow: 1;">
        <div class="flex_column gap_5 mgb_5">
            <span class="large_txt" style="font-size: 18px;">
                추가할 치료태그 템플릿
            </span>
            <small class="dgray_txt">
                어드민에 등록된 기본 템플릿을 선택하여 해당 병원에 업로드 할 수 있습니다.<br> 입력된 명칭으로 태그가 생성되며, 매뉴얼 내용이 입력되어 표시됩니다.
            </small>
        </div>
        <li class="settings_list">
            <div class="settings_list_option tag_template_cont flex_column gap_0">
                <div class="tag_template_search flex_aic gap_5">
                    <select name="검색 카테고리" id="" v-model="keywordType">
                        <option value="keyword">태그명</option>
                        <option value="descriptionKeyword">메모</option>
                    </select>
                    <input type="text" class="w_full" name="" id="" placeholder="검색어 입력" v-model="keyword" @keydown.enter="action_Search">
                    <button class="btn_s btn_blue_line" @click="action_Search">검색</button>
                </div>
                <ul class="h_scroll tag_template_wrap gap_10">
                    <TreatmentTagSettingTemplateListItem
                        v-for="(item, index) in searchedTags"
                        :key="index"
                        :index="index"
                        :item="item"
                        @updateSelected="updateSelected"
                    />
                </ul>
            </div>
        </li>
    </div>
</template>


<script>
import TreatmentTagSettingTemplateListItem from './TreatmentTagSettingTemplateListItem.vue'

export default {
    components: { TreatmentTagSettingTemplateListItem },
    props: {
        selectedTags: Array
    },
    data() {
        return {
            keywordType: 'keyword',
            keyword: '',
            descriptionKeyword: '',
            
            searchedTags: [],

        }
    },
    watch: {
        selectedTags: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                for (let i=0 ; i<this.searchedTags.length ; i++) {
                    this.searchedTags[i].isSelected = false
                    for (let j=0 ; j<this.selectedTags.length ; j++) {
                        if (this.searchedTags[i]._id == this.selectedTags[j]._id) {
                            this.searchedTags[i].isSelected = true
                        }
                    }
                }
            }
        }
    },
    computed: {
        filter: function() {
            let model = {
                isCommon: true,
                isRemoved: false,
            }
            if (this.keyword != undefined && this.keyword != '') {
                if (this.keywordType == 'keyword') { model.keyword = this.keyword }
                else { model.descriptionKeyword = this.keyword }
            }
            return model
        },
    },
    mounted() {
        this.loadTreatmentCategory()
    },
    methods: {
        // MARK: - Network
        async loadTreatmentCategory() {
            let result = await this.API_V3.TreatmentCategory.find(this.filter)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let data = result.data.data
            for (let i=0 ; i<data.length ; i++) {
                data[i].isSelected = false
                for (let j=0 ; j<this.selectedTags.length ; j++) {
                    if (data[i]._id == this.selectedTags[j]._id) {
                        data[i].isSelected = true
                    }
                }
            }
            this.searchedTags = data
        },

        // MARK: - Actions
        action_Search() {
            this.loadTreatmentCategory(0)
        },

        // MARK: - Emit
        updateSelected(data) {
            let tempArray = JSON.parse(JSON.stringify(this.selectedTags))
            if (data.isSelected == true) {
                let targetIndex = tempArray.findIndex( t => t._id == data._id)
                if (targetIndex == undefined || targetIndex < 0) {
                    tempArray.push(data)
                }
            }
            else {
                let index = tempArray.findIndex( t => t._id == data._id )
                tempArray.splice(index, 1)
            }
            this.$emit('updateSelectedTags', tempArray)
        },
    }
}
</script>