<template>
    <div class="snb">
        <div class="snb_title">기본 템플릿</div>
        <a @click="action_TreatmentTagManual" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('treatmenttagmanual') }">치료태그 매뉴얼</a>
        <a @click="action_ConsultAutomation" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('consultautomation') }">상담자동화</a>
        <a @click="action_AlrimtalkMessage" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('alrimtalkmessage') }">알림톡 메시지</a>
    </div>
</template>


<script>
export default {
    methods: {
        action_TreatmentTagManual: function() {
            this.$router.push({name: 'TreatmentTagManual'})
        },
        action_ConsultAutomation: function() {
            this.$router.push({name: 'ConsultAutomation'})
        },
        action_AlrimtalkMessage: function() {
            this.$router.push({name: 'AlrimtalkMessage'})
        }
    }    
}
</script>