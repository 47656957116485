<template>
    <div class="msg_list_wrap">
        <div class="scroll_wrap">
            <div class="scroll">
                <!-- 메시지 리스트 항목 -->
                <MessageListItem
                    v-for="(message, index) in messages" :key="index"
                    :index="index"
                    :message="message"
                    :selectedMessage="selectedMessage"
                    @setSelectMessage="setSelectMessage"
                    @removeMessage="removeMessage"
                />
            </div>
        </div>
        <!-- 메시지 추가 버튼 영역 -->
        <div class="btn_wrap">
            <button class="btn_s btn_blue_line" @click="action_CreateMessage">메시지 추가</button>
        </div>
    </div>
</template>


<script>
import MessageListItem from './MessageListItem.vue'

export default {
    components: {
        MessageListItem,
    },
    props: {
        messages: {
            type: Array,
            default: () => []
        },
        selectedMessage: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            
        }
    },
    watch: {

    },
    computed: {},
    methods: {
        // MARK: - Actions
        action_CreateMessage() {
            this.$emit('createMessage')
        },

        // MARK: - Emit
        setSelectMessage(message) {
            this.$emit('setSelectedMessage', message)
        },
        removeMessage(message) {
            this.$emit('removeMessage', message)
        }
    },
}
</script>