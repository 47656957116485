<template>
    <tr>
        <td>
            <p style="max-width: 240px;">
                {{ name }}
            </p>
        </td>
        <td>
            <p style="max-width:460px">
                {{ memo }}
            </p>
        </td>
        <td>{{ messageCount }}</td>
        <td>
            <span>{{ updatedDate }}</span>
            <span class="mgl_5">{{ updatedTime }}</span>
        </td>
        <td>
            <button class="btn_s btn_blue_line w_full" @click="action_SelectTemplate">선택</button>
        </td>
    </tr>
</template>

<script>
import moment from '@/util/MPMoment'

export default {
    name: 'BasicTemplateListItem',
    components: {},
    props: {
        algorithm: Object
    },
    data() {
        return {}
    },
    watch: {},
    computed: {
        name: function() {
            return this.algorithm.name ?? '-'
        },
        memo: function() {
            return this.algorithm.memo ?? '-'
        },
        messageCount: function() {
            return (this.algorithm?.messages ?? []).length
        },
        updatedDate: function() {
            if (this.algorithm.updatedAt == undefined && this.algorithm.createdAt == undefined) {
                return '-'
            }
            return moment(this.algorithm.updatedAt ?? this.algorithm.createdAt).format('YYYY-MM-DD')
        },
        updatedTime: function() {
            if (this.algorithm.updatedAt == undefined && this.algorithm.createdAt == undefined) {
                return '-'
            }
            return moment(this.algorithm.updatedAt ?? this.algorithm.createdAt).format('HH:mm')
        }
    },
    methods: {
        action_SelectTemplate() {
            this.$emit('setSelectedAlgorithm', this.algorithm)
        }
    }
}

</script>

