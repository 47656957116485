<template>
    <div class="alrimtalk_edit_page" v-if="isShow">
        <div class="page_title">
            <span>상담자동화 추가</span>
            <button class="close_btn large_txt lgray_txt mgl_auto" @click="action_Close">
                <v-icon>mdi-close</v-icon>
                닫기
            </button>
        </div>
        <div class="h_scroll">
            <div class="consultauto_add_wrap">
                <!-- 메시지 왼쪽 영역 -->
                <ConsultAutomationEditPopupLeft
                    :nameModel="name"
                    :memoModel="memo"
                    :messagesModel="messages"
                    :selectedMessage="selectedMessage"
                    @setSelectMessage="setSelectMessage"
                    @updateMessages="updateMessages"
                    @updateName="updateName"
                    @updateMemo="updateMemo"
                />
                <!-- 메시지 내용 입력 영역 -->
                <ConsultAutomationEditMessage
                    :kakaoGroups="kakaoGroups"
                    :kakaoProfiles="kakaoProfiles"
                    :kakaoCategories="kakaoCategories"
                    :selectedMessage="selectedMessage"
                    :selectedMessageIndex="selectedMessageIndex"
                    @updateMessage="updateMessage"
                />
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Save">최종 저장</button>
            </div>
        </div>
    </div>
</template>


<script>
import ConsultAutomationEditPopupLeft from './ConsultAutomationEditPopupLeft.vue'
import ConsultAutomationEditMessage from './ConsultAutomationEditMessage.vue'

export default {
    components: {
        ConsultAutomationEditPopupLeft,
        ConsultAutomationEditMessage
    },
    props: {
        isShow: Boolean,
        selectedAlgorithm: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            selectedMessage: {},
            selectedMessageIndex: 0,

            // algorithm properties
            algorithmID: undefined,
            name: '',
            type: 'CONSULTATION',           // default -> consultation. can cange common when regist in each hospital
            messages: [],                   // AlgorithmMessages
            memo: undefined,

            kakaoGroups: [],
            kakaoProfiles: [],
            kakaoCategories: []
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) {
                    this.algorithmID = undefined,
                    this.name = ''
                    this.messages = []
                    this.memo = ''
                    this.selectedMessage = undefined
                    this.selectedMessageIndex = 0
                }
            }
        },
        selectedAlgorithm: {
            immediate: true,
            handler: function(newValue) {
                this.algorithmID = newValue?._id ?? undefined,
                this.name = newValue?.name ?? ''
                this.messages = newValue.messages ?? []
                this.memo = newValue.memo ?? ''
                this.selectedMessage = undefined
                this.selectedMessageIndex = 0
                if (this.messages?.length > 0) {
                    this.selectedMessage = this.messages[0]
                }
            }
        }
    },
    computed: {},
    mounted() {
        this.loadKakaoInformation()
    },
    methods: {
        // MARK: - Network
        async loadKakaoInformation() {
            await this.loadKakaoGroups()
            await this.loadKakaoProfile()
            await this.loadKakaoCategories()
        },
        async loadKakaoGroups() {
            let result = await this.API_V3.KakaoTemplate.findGroup()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.kakaoGroups = result.data.data
        },
        async loadKakaoProfile() {
            let result = await this.API_V3.KakaoTemplate.findAllProfile()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.kakaoProfiles = result.data.data
        },
        async loadKakaoCategories() {
            let result = await this.API_V3.KakaoTemplate.findCategory()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.kakaoCategories = result.data.data
        },
        async createAlgorithm() {
            let model = {
                isTemplate: true,
                name: this.name,
                memo: this.memo,
                messages: this.messages,
                type: 'CONSULTATION',
                isActive: false
            }
            let result = await this.API_V3.Automation.create(model)
            if (result.data.code != 0) {
                alert(result.data.message)
            } else {
                this.$emit('setToastMessage', '저장되었습니다.')
                this.$emit('reloadCurrentPage')
                this.action_Close()
            }
        },
        async updateAlgorithm() {
            let model = {
                _id: this.algorithmID,
                name: this.name,
                memo: this.memo,
                messages: this.messages
            }
            let result = await this.API_V3.Automation.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
            } else {
                this.$emit('setToastMessage', '저장되었습니다.')
                this.$emit('reloadCurrentPage')
                this.action_Close()
            }
        },

        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        action_Save() {
            if (this.algorithmID != undefined) {
                this.updateAlgorithm()
            }
            else {
                this.createAlgorithm()
            }
        },


        // MARK: - Emit
        setSelectMessage(messageObject) {
            this.selectedMessageIndex = messageObject.index
            this.selectedMessage = messageObject.message
        },
        updateMessage(messageObject) {
            console.log('messageObject.index : ', messageObject.index, messageObject)
            let index = messageObject.index
            let message = messageObject.message

            this.messages.splice(index, 1, message)
            this.messages.sort((l, r) => {
                if (l.daysAfter < r.daysAfter) {
                    return -1
                } else if (l.daysAfter > r.daysAfter) {
                    return 1
                } else {
                    if (l.timeOfDay < r.timeOfDay) {
                        return -1
                    } else if (l.timeOfDay > r.timeOfDay) {
                        return 1
                    } else {
                        return 1
                    }
                }
            })
            this.selectedMessage = this.messages.find( (m) => m == message)
            this.selectedMessageIndex = this.messages.indexOf(this.selectedMessage)

            console.log('after sorting : ', this.messages)
        },
        updateMessages(messages) {
            this.messages = messages
        },
        updateName(name) {
            this.name = name
        },
        updateMemo(memo) {
            this.memo = memo
        }
    }
}
</script>