<template>
    <ul class="settings_wrap flex_column"  v-if="isShow">
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                팝업 사용 여부<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="사용여부" :value="false" v-model="usingAfterhoursPopup">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="사용여부" :value="true" v-model="usingAfterhoursPopup">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb gap_10">
            <div class="settings_list_title pop flex_column">
                <div class="dgray_txt">내용<small class="red_txt">*</small></div>
                <div class="text_editor mgt_10">
                    <HTMLEditor v-model="content" :message="defaultMessageString" ref="Editor"/>
                </div>
                <small class="red_txt" style="font-weight: 400;" v-if="isEmpty">내용을 입력해 주세요.</small>
            </div>
            <div class="settings_list_option preview flex_column gap_10">
                <div class="preview_info flex_column gap_10">
                    <div>
                        <b>미리보기</b>
                        <small class="dgray_txt mgt_5">
                            애프터닥 모바일 앱에 채팅 불가능한 시간에 유저가 채팅을 보내는 경우 생성되는 팝업을 미리 확인하실 수 있습니다. 미리보기는 참고용이며, 사용자의 디바이스에 따라 다소 차이가 있을 수 있습니다.(현재 화면 가로 350px 기준)
                        </small>
                    </div>
                    <div class="preview_box flex_aic_jcc">
                        <div class="preview_pop_wrap">
                            <div class="preview_content_wrap flex_column">
                                <div class="preview_header">
                                    <img src="https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3/20241008185271/ic_exclamation.png" alt="">
                                </div>
                                <div class="preview_content">
                                    <div class="content_string" v-html="contentString"></div>
                                </div>
                            </div>
                            <div class="preview_btn">
                                확인
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" @click="action_Save" :disabled="!auth.v2HospitalManagement_w">저장</button>
        </li>
    </ul>
</template>


<script>
import HTMLEditor from '../../../../../../ShareComponents/HTMLEditor.vue'
export default {
    components: {
        HTMLEditor
    },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            content: '',
            usingAfterhoursPopup: false,
            defaultMessageString: ''
        }
    },
    watch: {
        hospital: {
            immediate: true,
            handler: function(newValue) {
                this.content = newValue.afterhoursPopupContent ?? undefined
                this.usingAfterhoursPopup = newValue.usingAfterhoursPopup ?? false
            }
        },
        content: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.content = newValue.replace(/<p><\/p>/g, '<p><br/></p>')
                }
            }
        }
    },
    computed: {
        contentString() {
            if (this.content == undefined || this.content == '' || this.content == '<p></p>' || this.content == '<p><br/></p>') {
                return '<p>내용을 입력해주세요</p>'
            }
            return this.content
        },
        isEmpty() {
            if (this.content == undefined || this.content == '' || this.content == '<p></p>' || this.content == '<p><br/></p>') { return true }
            return false
        }
    },
    methods: {
        // MARK: - Network
        async updateHospital() {
            let model = {
                _id: this.hospital._id,
                usingAfterhoursPopup: this.usingAfterhoursPopup,
                afterhoursPopupContent: this.content
            }
            let result = await this.API_V3.Hospital.update(model)
            return result
        },

        // MARK: - Actions
        async action_Save() {
            if (this.isEmpty == true) { return }
            this.isLoading = true
            let result = await this.updateHospital()
            this.isLoading = false
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.$emit('reloadHospital')
        }
    },
}
</script>