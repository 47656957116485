<template>
    <div class="v2_content_wrap">
        <LoadingIndicate :isLoading="isLoading" />
        <EditHospitalPopup
            :isShow="isShowEditPopup"
            :hospitalID="hospitalID"
            @editHospital="editHospital"
            @close="action_CloseHospitalPopup"
        />
        <div class="page_title">
            <span>병원 설정</span>
        </div>
        
        <div class="h_scroll">
            <div class="table_title mgb_20">
                <p>
                    총 <span class="black_txt">{{ totalCount }}</span>개
                </p>
                <div class="table_title_btn">
                    <!-- Test Case Toggle Button -->
                    <div class="checkbox_wrap mgr_10">
                        <input type="checkbox" class="checkmark" id="exclude_test" :value="isExceptTest"><label for="exclude_test">테스트 제외</label>
                    </div>
                    <input type="text" class="input_s" v-model="keyword" placeholder="병원명 검색" @keyup.enter="loadHospital(0)" />
                    <button class="btn_s btn_blue_line mgl_5" @click="loadHospital(0)">검색</button>
                    <button class="btn btn_s btn_blue" @click="action_ShowCreateHospitalPopup" :disabled="auth.v2HospitalManagement_w == false">병원 추가</button>
                </div>
            </div>
            <div class="table_header_fix">
                <table class="h_setting_table" v-if="isLoading">
                    <thead>
                        <tr>
                            <th width="60">상태</th>
                            <th width="270" class="txt_left">병원명</th>
                            <th width="120" class="txt_left">담당자</th>
                            <th width="100">생성일</th>
                            <th width="80">계정수</th>
                            <th width="100">태그 매뉴얼</th>
                            <th width="100">상담 자동화</th>
                            <th width="100">자동번역</th>
                            <th width="100">상담톡 연동</th>
                            <th width="100">알림톡 연동</th>
                            <th width="100">전능 연동</th>
                            <th width="100">이벤트</th>
                            <th width="100">홈케어 콘텐츠</th>
                            <th width="">수정</th>
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="14">데이터 로드 중 ..</td>
                    </tr>
                </table>
                <!-- hospital data table -->
                <table class="table_default" v-if="!isLoading">
                    <thead>
                        <tr>
                            <th width="60">상태</th>
                            <th width="270" class="txt_left">병원명</th>
                            <th width="120" class="txt_left">담당자</th>
                            <th width="100">생성일</th>
                            <th width="80">계정수</th>
                            <th width="100">태그 매뉴얼</th>
                            <th width="100">상담 자동화</th>
                            <th width="100">자동번역</th>
                            <th width="100">상담톡 연동</th>
                            <th width="100">알림톡 연동</th>
                            <th width="100">전능 연동</th>
                            <th width="100">이벤트</th>
                            <th width="100">홈케어 콘텐츠</th>
                            <th width="">수정</th>
                        </tr>
                    </thead>
                    <Item
                        v-for="(hospital, index) in hospitals" :key="index"
                        :hospital="hospital"
                        @editHospital="editHospital"
                    />
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="totalCount"
                v-bind:currentPage="currentPage"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="changePage"
            />
        </div>
    </div>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'

import Item from './V2HospitalListItem.vue'
import EditHospitalPopup from './EditHospitalPopup/EditHospitalPopup.vue'

import PageController from '../../../../../../controller/PageController'

export default {
    components: { LoadingIndicate, Pagenation, Item, EditHospitalPopup },
    data() {
        return {
            isLoading: false,
            keyword: '',
            isExceptTest: false,
            
            hospitals: [],
            
            totalCount: 0,
            currentPage: 0,
            countPerPage: 11,

            isShowEditPopup: false,
            hospitalID: undefined,

            pageController: undefined,
        }
    },
    watch: {},
    computed: {
        filter: function() {
            let model = {
                keyword: this.keyword,
                isEnabledSaaS: true,
            }
            if (this.isExceptTest == true) {
                model.isTest = !this.isExceptTest
            }
            return model
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.isLoading = false
            this.loadHospital(this.currentPage)
        },

        // MARK: - Network
        async loadTotalCount() {
            let result = await this.API_V3.Hospital.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadHospital(page) {
            if (this.pageController == undefined) {
                this.pageController = new PageController(this.API_V3.Hospital.find)
            }
            try {
                this.isLoading = true
                await this.loadTotalCount()
                this.pageController.totalCount = this.totalCount
                this.pageController.countPerPage = this.countPerPage
                this.pageController.filter = this.filter
                await this.pageController.loadPageIndex(page)
                this.hospitals = this.pageController.data
                this.currentPage = page
            } catch(err) {
                console.log(err)
                alert(err)
            }
            this.isLoading = false
        },

        // MARK: - Actions
        action_ShowCreateHospitalPopup() {
            this.isShowEditPopup = true
            this.hospitalID = undefined
        },
        action_CloseHospitalPopup() {
            this.isShowEditPopup = false
            this.hospitalID = undefined
        },

        // MARK: - Pagenation
        changePage(page) {
            this.loadHospital(page)
        },
        async editHospital(hospitalID) {
            this.isShowEditPopup = true
            this.hospitalID = hospitalID
        },
    }
}
</script>