<!-- 공통재내원 여부 설정 후 다음 버튼 클릭 시 나타나는 팝업 -->
<template>
    <div class="basic_template_pop_wrap">
        <div class="pop_title">
            <span class="title">템플릿 등록</span>
            <a class="mgl_auto" @click="action_ClosePopup">
                <v-icon :size="24">mdi-close</v-icon>
            </a>
        </div>
        <div class="cc h_scroll pd_15">
            <div class="w_960 consultauto_add_wrap">
                <!-- 메시지 왼쪽 영역 -->
                <div class="consultauto_add_nav">
                    <!-- 메시지 리스트 -->
                    <MessageList
                        :messages="messages"
                        :selectedMessage="selectedMessage"
                        @createMessage="createMessage"
                        @setSelectedMessage="setSelectedMessage"
                        @removeMessage="removeMessage"
                    />
                </div>
                <!-- 메시지 내용 입력 영역 -->
                <MessageEdit
                    :serviceSetting="serviceSetting"
                    :selectedMessage="selectedMessage"
                    :selectedMessageIndex="selectedMessageIndex"
                    :kakaoGroups="kakaoGroups"
                    :kakaoProfiles="kakaoProfiles"
                    :kakaoCategories="kakaoCategories"
                    :onlyGroups="false"
                    @updateMessage="updateMessage"
                />
            </div>
            <div class="flex_aic_jcc mgt_15">
                <button class="btn_gray_line btn_s" @click="action_PreviousStep">이전</button>
                <button class="btn_blue btn_s" @click="action_Save">최종저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import MessageList from './Left/MessageList.vue'
import MessageEdit from './Right/MessageEdit.vue'

export default {
    name: 'FinalMessageConfiguration',
    components: {
        MessageList,
        MessageEdit,
    },
    props: {
        value: Array,
        serviceSetting: { type: Object, default: () => { return {} }},
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
        kakaoCategories: { type: Array, default: () => [] },
    },
    data() {
        return {
            messages: [],
            selectedMessage: undefined,
            selectedMessageIndex: -1,
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.messages = JSON.parse(JSON.stringify(newValue ?? []))
                if (this.selectedMessageIndex >= 0) {
                    this.selectedMessage = this.messages[this.selectedMessageIndex]
                }
            }
        }
    },
    computed: {},
    methods: {
        // MARK: - Actions
        action_ClosePopup() {
            this.$emit('closePopup')
        },
        action_PreviousStep() {
            this.$emit('goToPreviousDept')
        },
        action_Save() {
            this.$emit('createAlgorithm')
        },

        // MARK: - Emit
        createMessage() {
            this.messages.push({})
        },
        setSelectedMessage(message) {
            this.selectedMessage = message
            this.selectedMessageIndex = this.messages.indexOf(message)
        },
        removeMessage(message) {
            let index = this.messages.indexOf(message)
            this.messages.splice(index, 1)
            this.selectedMessage = undefined
            this.selectedMessageIndex = 0
        },
        updateMessage(messageObj) {
            let message = messageObj.message
            let messageIndex = messageObj.index

            let array = JSON.parse(JSON.stringify(this.messages))
            array.splice(messageIndex, 1, message)
            array.sort( (l, r) => {
                if (l.daysAfter < r.daysAfter) {
                    return -1
                } else if (l.daysAfter > r.daysAfter) {
                    return 1
                } else {
                    if (l.timeOfDay < r.timeOfDay) {
                        return -1
                    } else if (l.timeOfDay > r.timeOfDay) {
                        return 1
                    } else {
                        return 1
                    }
                }
            })
            this.messages = array
            this.selectedMessageIndex = this.messages.indexOf(message)
            this.$emit('input', this.messages)
            this.selectedMessage = this.messages[this.selectedMessageIndex]
        }
    }
}

</script>