<template>
    <tr>
        <td>
            <p style="max-width: 280px;">
                {{ hospitalName }}
            </p>
        </td>
        <td>
            <p style="max-width: 230px">
                {{ pf_id }}
            </p>
        </td>
        <td>{{ phone }}</td>
        <td>
            <p style="max-width: 198px">
                {{ category }}
            </p>
        </td>
        <td>{{ createdAt }}</td>
        <td class="txt_center">
            <small class="lblue service_status" v-if="isLinked">사용중</small>
        </td>
        <td class="txt_center" v-if="!hasDetail">
            <button class="table_btn btn_gray" @click="action_Detail">그룹 조회</button>
        </td>
        <td class="txt_center" v-if="hasDetail">
            <p>{{ groupTitle }}</p>
        </td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        index: Number,
        history: {
            type: Object,
            default: () => {return {}}
        }
    },
    data() {
        return {}
    },
    computed: {
        hospitalName: function() {
            if (this.history.hospitalName != undefined) { return this.history.hospitalName }
            if (this.history.tempHospitalName != undefined) { return this.history.tempHospitalName }
            return '-'
        },
        pf_id: function() {
            return this.history?.searchableChannelId ?? '-'
        },
        phone: function() {
            return this.history?.channelAdminPhone ?? '-'
        },
        category: function() {
            const info = this.history?.categoryInformation
            return (info?.broadCategory ?? '-') + '/' + (info?.mediumCategory ?? '-') + '/' + (info?.smallCategory ?? '-')
        },
        createdAt: function() {
            if (this.history?.createdAt == undefined) { return '-' }
            return moment(this.history?.createdAt).format('YYYY-MM-DD')
        },
        hasDetail: function() {
            return this.history.detailInfo != undefined
        },
        groups: function() {
            return this.history.detailInfo?.groups
        },
        groupTitle: function() {
            return this.groups?.map( (g) => g.name ).join(', ') ?? '-'
        },
        isLinked: function() { return this.history.isLinked },
    },
    methods: {
        action_Detail() {
            this.$emit('loadDetail', this.index)
        },
    },
}
</script>