<template>
    <div v-if="isShow" class="pop_overlay_bg">
        <div class="automation_detail_pop_wrap">
            <div class="pop_title">
                <span class="title">상담자동화 상세</span>
                <button class="mgl_auto" @click="action_Close"><v-icon large>mdi-close</v-icon></button>
            </div>
            <div class="pop_cont">
                <div class="h_scroll">
                    <div class="table_header_fix">
                        <table class="table_default kakao_automation_table">
                            <thead>
                                <tr>
                                    <th width="130">발송시점/타입(A)</th>
                                    <th width="120">메시지명(A)</th>
                                    <th width="150">발신프로필/그룹(D)</th>
                                    <th width="150">템플릿명/템플릿코드(D)</th>
                                    <th width="20">검수상태(D)</th>
                                    <th width="">내용</th>
                                </tr>
                            </thead>
                            <DetailTemplateMessageItem
                                v-for="(message, index) in messages" :key="index"
                                :message="message"
                                :kakaoGroups="kakaoGroups"
                                :kakaoProfiles="kakaoProfiles"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import DetailTemplateMessageItem from './DetailTemplateMessageItem.vue';
export default {
    components: {
        DetailTemplateMessageItem,
    },
    props: {
        isShow: Boolean,
        template: { type: Object, default: () => { return {} } },
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
    },
    data() {
        return {

        }
    },
    watch: {},
    computed: {
        messages: function() { return this.template?.messages ?? [] }
    },
    methods: {
        action_Close() {
            this.$emit('close')
        }
    }
}
</script>