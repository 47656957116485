import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const create = (data) => {
    return axios({
        method: 'post',
        url: URL.ANSWERINGCHAT_SETTING_CREATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const update = (data) => {
    return axios({
        method: 'patch',
        url: URL.ANSWERINGCHAT_SETTING_UPDATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const findOne = (query) => {
    return axios({
        method: 'get',
        url: URL.ANSWERINGCHAT_SETTING_FIND_ONE,
        params: query,
        headers: Util.getHeaders()
    })
}