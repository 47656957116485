<template>
    <!-- 선택 버튼 클릭 시 나타나는 팝업 -->
    <div class="pop_overlay_bg" v-show="isShow">
        <div class="alrimtalk_group_pop_wrap">
            <div class="pop_title">
                <span class="title">알림톡 발신프로필/그룹 선택</span>
                <a class="mgl_auto">
                    <v-icon :size="24" @click="action_Close">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <AlrimtalkProfileList
                    v-if="isShowSelectProfilePopup"
                    :selectableKakaoProfiles="selectableKakaoProfiles"
                    @updateSelectedProfile="updateSelectedProfile"
                />
                <AlrimtalkGroupList
                    v-if="isShowSelectGroupPopup"
                    :groups=groups
                    @updateSelectedGroup="updateSelectedGroup"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AlrimtalkGroupList from './AlrimtalkGroupList.vue'
import AlrimtalkProfileList from './AlrmtalkProfileList.vue'
export default {
    components: {
        AlrimtalkGroupList,
        AlrimtalkProfileList,
    },
    props: {
        isShowSelectProfilePopup: Boolean,
        isShowSelectGroupPopup: Boolean,
        selectableKakaoProfiles: {type: Array, default: () => [] }
    },
    data() {
        return {
            groups: [],

            selectedGroup: undefined,
            selectedProfile: undefined,
        }
    },
    watch: {},
    computed: {
        isShow: function() { return this.isShowSelectGroupPopup || this.isShowSelectProfilePopup }
    },
    mounted() {
        this.loadGroups()
    },
    methods: {
        // Network
        async loadGroups() {
            let result = await this.API_V3.KakaoTemplate.findGroup()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.groups = result.data.data
        },

        updateSelectedProfile: function(profile) {
            this.selectedGroup = undefined
            this.selectedProfile = profile
            this.updateSenderKey()
            this.action_Close()
        },
        updateSelectedGroup: function(group) {
            this.selectedProfile = undefined
            this.selectedGroup = group
            this.updateSenderKey()
            this.action_Close()
        },
        updateSenderKey() {
            this.$emit('updateSelectedSenderKey', {
                group: this.selectedGroup,
                profile: this.selectedProfile
            })
        },


        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        }
    }
}
</script>