import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const findGroup = () => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE_GROUPS,
        headers: Util.getHeaders()
    })
}

export const findProfile = (query) => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE_PROFILE,
        params: query,
        headers: Util.getHeaders()
    })
}

export const findAllProfile = (params) => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE_ALL_PROFILES,
        params: params,
        headers: Util.getHeaders()
    })
}

export const findCategory = () => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE_CATEGORIES,
        headers: Util.getHeaders()
    })
}

export const findTemplate = (params) => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE,
        params: params,
        headers: Util.getHeaders()
    })
}

export const findDetail = (params) => {
    return axios({
        method: 'GET',
        url: URL.KAKAO_TEMPLATE_DETAIL,
        params: params,
        headers: Util.getHeaders()
    })
}


export const createTemplate = (data) => {
    return axios({
        method: 'POST',
        url: URL.KAKAO_TEMPLATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const updateTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const removeTemplate = (params) => {
    return axios({
        method: 'DELETE',
        url: URL.KAKAO_TEMPLATE,
        params: params,
        headers: Util.getHeaders()
    })
}

export const requestTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST,
        data: data,
        headers: Util.getHeaders()
    })
}

export const requestCancelTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST_CANCEL,
        data: data,
        headers: Util.getHeaders()
    })
}

export const stopTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST_STOP,
        data: data,
        headers: Util.getHeaders()
    })
}

export const reuseTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST_REUSE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const cancelApprovalTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST_CANCEL_APPROVAL,
        data: data,
        headers: Util.getHeaders()
    })
}

export const releaseTemplate = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAO_TEMPLATE_REQUEST_RELEASE,
        data: data,
        headers: Util.getHeaders()
    })
}