<template>
    <div class="v2_content_wrap">
        <LoadingIndicate :isLoading="isLoading" />
        <!-- TODO: Edit -->
        <div class="page_title">
            <span>메시지 관리</span>
        </div>
        <div class="h_scroll">
            <div class="table_title mgb_20">
                <p>
                    총 <span class="black_txt">{{ totalCount }}</span>개
                </p>
                <div class="table_title_btn">
                    <!-- Test Case Toggle Button -->
                    <div class="checkbox_wrap mgr_10">
                        <input type="checkbox" class="checkmark" id="exclude_test" v-model="isExceptTest"><label for="exclude_test">테스트 제외</label>
                    </div>
                    <input type="text" class="input_s" v-model="keyword" placeholder="병원명 검색" @keyup.enter="loadHospital(0)" />
                    <button class="btn_s btn_blue_line mgl_5" @click="loadHospital(0)">검색</button>
                </div>
            </div>
            <div class="table_header_fix">
                <table class="h_setting_table" v-if="isLoading">
                    <thead>
                        <tr>
                            <th width="270" class="txt_left">병원명</th>
                            <th width="270" class="txt_left">생성일</th>
                            <th width="270" class="txt_left">신규등록</th>
                            <th width="270" class="txt_left">재발송</th>
                            <th width="270" class="txt_left">예약</th>
                            <!-- <th width="">수정</th> -->
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="14">데이터 로드 중 ..</td>
                    </tr>
                </table>
                <!-- hospital data table -->
                <table class="table_default" v-if="!isLoading">
                    <thead>
                        <tr>
                            <th width="270" class="txt_left">병원명</th>
                            <th width="100" class="txt_left">생성일</th>
                            <th width="300" class="txt_left">신규등록</th>
                            <th width="300" class="txt_left">재발송</th>
                            <th width="300" class="txt_left">예약</th>
                            <!-- <th width="">수정</th> -->
                        </tr>
                    </thead>
                    <Item
                        v-for="(hospital, index) in hospitals" :key="index"
                        :hospital="hospital"
                    />
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="totalCount"
                v-bind:currentPage="currentPage"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="loadHospital"
            />
        </div>
    </div>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'
import Item from './MessageManagementHospitalListItem.vue'
import PageController from '@/controller/PageController'
export default {
    components: { LoadingIndicate, Pagenation, Item },
    data() {
        return {
            isLoading: false,
            isExceptTest: true,
            keyword: '',

            pageController: undefined,

            hospitals: [],
            
            totalCount: 0,
            currentPage: 1,
            countPerPage: 20,
        }
    },
    watch: {},
    computed: {
        filter: function() {
            let model = {
                isEnabledSaaS: true,
            }
            if (this.keyword != undefined && this.keyword != '') {
                model.keyword = this.keyword
            }
            if (this.isExceptTest == true) {
                model.isTest = !this.isExceptTest
            }
            return model
        }
    },
    mounted() {
        this.loadHospital(0)
    },
    methods: {
        // MARK: - Network
        async loadTotalCount() {
            let result = await this.API.Hospital.countHospitals(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadHospital(page) {
            if (this.pageController == undefined) {
                this.pageController = new PageController(this.API.Hospital.getHospitals)
            }
            try {
                this.isLoading = true
                await this.loadTotalCount()
                this.pageController.totalCount = this.totalCount
                this.pageController.countPerPage = this.countPerPage
                this.pageController.filter = this.filter
                await this.pageController.loadPageIndex(page)
                this.hospitals = this.pageController.data
                this.currentPage = page
            } catch(err) {
                console.log(err)
                alert(err)
            }
            this.isLoading = false
        }
    },
}
</script>
