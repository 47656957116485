<template>
    <li class="custom_tag_list">
        <label class="flex_aic checkbox_wrap">
            <input
                type="checkbox"
                :checked="isSelected"
                @change="action_SelectCountry"
            />
            <span>{{ name }}</span>
        </label>
    </li>
</template>

<script>
export default {
    name: 'NationalitySettingItem',
    components: {},
    props: {
        country: Object,
        selectedCountryIDs: Array,
    },
    data() {
        return {}
    },
    watch: {},
    computed: {
        _id: function() { return this.country._id },
        name: function() { return this.country?.koreanCountryName ?? '-' },
        isSelected() { return this.selectedCountryIDs.includes(this.country._id); },
    },
    methods: {
        action_SelectCountry() {
            const isCurrentlySelected = this.isSelected;
            const updatedIDs = isCurrentlySelected
                ? this.selectedCountryIDs.filter(id => id !== this.country._id)
                : [...this.selectedCountryIDs, this.country._id];

            this.$emit('setSelectedCountryIDs', updatedIDs);
        },
    }
}

</script>

