<template>
    <div class="pop_overlay_bg" v-if="chattingalertpopup.isShow">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="chatEdit_pop_wrap">
            <PopupTitleSection title="업무 외 시간 채팅시 팝업 알림" @action_Close="action_Close" />
            <div class="inner_wrap">
                <div class="content">
                    <HTMLEditor v-model="content" :message="defaultMessageString" ref="Editor" />
                    <div style="display: flex; padding: 40px 10px 0px 10px">
                        <div>
                            업무 외 시간 채팅방 노출여부:
                            <input type="radio" v-model="radioValues" :value="true" />
                            <span class="checkmark"></span> 사용

                            <input type="radio" v-model="radioValues" :value="false" />
                            <span class="checkmark"></span> 미사용
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="text_alert">
                        미리보기<br />
                        <h5>
                            애프터닥 모바일 앱에 채팅 불가능한 시간에 유저가 채팅을 보내는 경우 생성되는 팝업을 미리 확인하실 수 있습니다. <br />미리보기는
                            참고용이며, 사용자의 디바이스에 따라 다소 차이가 있을 수 있습니다.(현재 화면 가로 350px 기준)
                        </h5>
                    </div>
                    <div class="content_wrapper">
                            <div class="img_wrap"><img :src="require(`@/assets/img/ic_exclamation.png`)" alt="ic_exclamation" /></div>
                            <div class="content_string" v-html="contentString"></div>
                            <div class="btn"><button>확인</button></div>
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_blue" @click="action_Save" :disabled="auth.hospitalManagement_w==false">저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PopupTitleSection from '../../../../ShareComponents/PopupTitleSection.vue'
import HTMLEditor from '../../../../ShareComponents/HTMLEditor.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    props: { hospital: Object },
    components: { PopupTitleSection, HTMLEditor, LoadingIndicate },
    data() {
        return {
            isLoading: false,
            radioValues: false,
            content: '',
            defaultMessageString: ''
        }
    },
    computed: {
        ...mapState(['chattingalertpopup']),
        contentString() {
            if (this.content == undefined || this.content == '' || this.content == '<p></p>') {
                return '<p>내용을 입력해주세요</p>'
            }
            return this.content
        }
    },
    mounted() {
        this.getHospitalSetting()
        this.getDefaultMessage()
    },
    created() {
        this.getHospitalSetting()
        this.getDefaultMessage()
    },
    watch: {
        'chattingalertpopup.isShow': {
            immediate: true,
            deep: true,
            handler: function (newValue, oldValue) {
                if (newValue == true || newValue != oldValue) {
                    this.getDefaultMessage()
                    this.getHospitalSetting()
                }
                this.addWindowEvent()
            }
        },
        content: {
            immediate: true,
            handler: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.content = newValue.replace(/<p><\/p>/g, '<p><br/></p>')
                }
            }
        }
    },
    methods: {
        ...mapActions(['setHospitalChatPopupState']),

        // MARK: - Network
        async getHospitalSetting() {
            if (this.hospital == undefined) {
                return
            }

            let hospital_id = this.hospital._id
            let model = { _id: hospital_id }
            let result = await this.API.Hospital.getHospitals(model)

            if (result.data.code != 0) {
                return
            }
            let defaultValue = result.data.data[0].usingAfterhoursPopup

            this.defaultMessageString = result.data.data[0].afterhoursPopupContent
            this.content = result.data.data[0].afterhoursPopupContent

            this.radioValues = defaultValue
        },
        async API_ServiceSettingUpdate(m) {
            let result = await this.API.Hospital.updateHospital(m)
            if (result.data.code == 0) {
                this.setHospitalChatPopupState({ isShow: false })
            }
            if (result.data.code != 0) {
                throw result
            }
        },

        // MARK: - Action
        async action_Save() {
            if (this.hospital == undefined) {
                return
            }

            let hospitalid = this.hospital._id
            let model = {
                _id: hospitalid,
                usingAfterhoursPopup: this.radioValues,
                afterhoursPopupContent: this.content
            }
            try {
                this.isLoading = true
                this.API_ServiceSettingUpdate(model)
                this.isLoading = false

                alert('저장되었습니다.')
                this.action_Close()
            } catch (error) {
                console.log('network error : ', error)
                return
            }
        },
        async action_Close() {
            this.content = ''
            this.$emit('clearChatpopup')
        },
        keyDownHandler(event) {
            if (event.keyCode == 27) {
                this.action_Close()
            }
        },
        addWindowEvent() {
            window.addEventListener('keyup', this.keyDownHandler)
        },
        getDefaultMessage() {
            if (this.hospital == undefined) {
                return
            }
            if (this.hospital.afterhoursPopupContent == undefined) {
                return
            }
            this.content = this.hospital.afterhoursPopupContent
            this.radioValues = this.hospital.usingAfterhoursPopup
        }
    }
}
</script>

<style> 
</style>
