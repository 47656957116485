var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gnb"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require(`@/assets/img/BaseLogo.png`),"alt":"afterdoc_logo"}})]),(_vm.auth.hospitalManagement_v)?_c('a',{staticClass:"gnb_menu",class:{
            on: _vm.$router.currentRoute.name == 'HospitalSetting' || _vm.$router.currentRoute.name == 'AccountSetting'
        },on:{"click":_vm.action_HospitalManagement}},[_vm._v("V1 병원 관리")]):_vm._e(),(_vm.auth.v2HospitalManagement_v)?_c('a',{staticClass:"gnb_menu",class:{
            on: _vm.$router.currentRoute.path.includes('v2hospitalsetting')
        },on:{"click":_vm.action_V2HospitalManagement}},[_vm._v("V2 병원 관리")]):_vm._e(),(_vm.auth)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.path.includes('messagemanagement') },on:{"click":_vm.action_MessageManagement}},[_vm._v("번호/메시지 관리")]):_vm._e(),(_vm.auth.serviceManager_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.name == 'ANurseAccountSetting' },on:{"click":_vm.action_ANurseManagement}},[_vm._v("서비스 매니저 관리")]):_vm._e(),(_vm.auth.videoContent_v)?_c('a',{staticClass:"gnb_menu",class:{
            on:
                _vm.$router.currentRoute.name == 'VideoUpload' ||
                _vm.$router.currentRoute.name == 'VideoContentSetting' ||
                _vm.$router.currentRoute.name == 'CategorySetting' ||
                _vm.$router.currentRoute.name == 'ApplicationSetting' ||
                _vm.$router.currentRoute.name == 'DefaultSetting'
        },on:{"click":_vm.action_VideoContentManagement}},[_vm._v("홈케어콘텐츠 관리")]):_vm._e(),(_vm.auth.onboarding_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.path.includes('/hospitaltype') },on:{"click":_vm.action_OnboardingSetting}},[_vm._v("온보딩 설정")]):_vm._e(),(_vm.auth.app_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.name == 'AppVersionSetting' },on:{"click":_vm.action_AfterdocAppSetting}},[_vm._v("앱 관리")]):_vm._e(),(_vm.auth.electron_v)?_c('a',{staticClass:"gnb_menu",class:{
            on: 
                _vm.$router.currentRoute.name.includes('NotificationSoundSetting') ||
                _vm.$router.currentRoute.name.includes('NationalSMSSetting') ||
                _vm.$router.currentRoute.name.includes('ServiceAlrimTalkSetting')
        },on:{"click":_vm.action_AfterdocElectronSetting}},[_vm._v("일렉트론 설정")]):_vm._e(),(_vm.auth.integration_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.path.includes('syncmanagement') },on:{"click":_vm.action_ALManagement}},[_vm._v("연동 관리")]):_vm._e(),(_vm.auth.monitoring_v)?_c('a',{staticClass:"gnb_menu",class:{ 
            on: 
                _vm.$router.currentRoute.name == 'ALManagementMonitoring'  ||
                _vm.$router.currentRoute.name == 'AutoReservedSMS' 
        },on:{"click":_vm.action_Monitoring}},[_vm._v("모니터링")]):_vm._e(),(_vm.auth.eventManagement_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.name == 'EventState' ||  _vm.$router.currentRoute.name == 'EventPushAlarmState'},on:{"click":_vm.action_EventManagement}},[_vm._v("이벤트 관리")]):_vm._e(),(_vm.auth.reservedChat_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.name == 'CAReservedChat' },on:{"click":_vm.action_CAReservedChatManagement}},[_vm._v("예약채팅 관리")]):_vm._e(),(_vm.auth.treatmentCategory_v || _vm.auth.algorithm_v || _vm.auth.alrimtalk_v)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.path.includes('basictemplate') },on:{"click":_vm.action_BasicTemplate}},[_vm._v("기본 템플릿")]):_vm._e(),(_vm.auth.adminAccount_v != false)?_c('a',{staticClass:"gnb_menu",class:{ on: _vm.$router.currentRoute.path.includes('admin') },on:{"click":_vm.action_AdminAccount}},[_vm._v("어드민 계정")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }