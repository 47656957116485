<template>
    <ul v-if="isShow" class="settings_wrap">
        <LoadingIndicate :isLoading="isLoading"/>
        
        <RemoveConfirm
            :isShow="confirmPopupOptions.isShow"
            :title="confirmPopupOptions.title"
            :body="confirmPopupOptions.body"
            :alertCallback="confirmPopupOptions.alertCallback"
        />  
        <RegisterAutomationBasicPopup
            v-if="isShowTemplatePopup"
            :templates="templates"
            :isShowPopup.sync="isShowTemplatePopup"
            :hospital="hospital"
            :serviceSetting="serviceSetting"
            :kakaoGroups="kakaoGroups"
            :kakaoProfiles="kakaoProfiles"
            :kakaoCategories="kakaoCategories"
            @loadTemplates="loadTemplates"
        />

        <div class="flex_aic_spb mgb_20">
            <div class="flex_column gap_5">
                <span class="large_txt" style="font-size: 18px;">
                    상담자동화 등록
                </span>
                <small class="dgray_txt">
                    이미 해당 병원에 설정되어 있는 매뉴얼은 삭제할 수 없습니다.<br>
                    삭제, 순서변경 등의 설정은 일렉트론에 로그인하여 진행해 주세요.
                </small>
            </div>
            <div>
                <button class="btn_blue_line btn_s" @click="action_ShowTemplatePopup">기본 템플릿 불러오기</button>
            </div>
        </div>
        <ul class="automation_wrap ">
            <AutomationBasicTemplateCard
                v-for="(template, index) in templates" :key="index"
                :template="template"
                @update="emit_ActiveTemplate"
                @remove="emit_RemoveTemplate"
                @detail="emit_ShowDetail"
            />
        </ul>
        <DetailTemplatePopup
            :isShow="isShowDetailPopup"
            :template="detailTemplate"
            :kakaoGroups="kakaoGroups"
            :kakaoProfiles="kakaoProfiles"
            @close="closeDetailPopup"
        />
    </ul>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import RemoveConfirm from '@/views/main/ShareComponents/RemoveConfirm.vue'
import AutomationBasicTemplateCard from './AutomationBasicTemplateCard.vue'
import RegisterAutomationBasicPopup
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/RegisterAutomationBasicPopup.vue'
import DetailTemplatePopup from './Popup/DetailTemplatePopup/DetailTemplatePopup.vue'


export default {
    components: {
        LoadingIndicate,
        RemoveConfirm,
        AutomationBasicTemplateCard,
        RegisterAutomationBasicPopup,
        DetailTemplatePopup,
    },
    props: {
        isShow: Boolean,
        hospital: Object
    },
    data() {
        return {
            isLoading: false,
            isShowTemplatePopup: false,
            isShowDetailPopup: false,

            templates: [],
            detailTemplate: undefined,

            serviceSetting: undefined,
            kakaoGroups: [],
            kakaoProfiles: [],
            kakaoCategories: [],

            confirmPopupOptions: {
                title: undefined,
                body: undefined,
                isShow: false,
                alertCallback: undefined
            },
        }
    },
    watch: {
        isShow: function(newValue) {
            if (newValue != true) { return }
            this.reloadData()
        }
    },
    computed: {
        hospitalID: function() { return this.hospital?._id },
        senderKey: function() { return this.serviceSetting?.kakaoProfileSenderKeyAtReady }
    },
    methods: {
        // MARK: - Privates
        async reloadData() {
            this.templates = []
            this.loadTemplates()
            this.isLoading = true
            await this.loadServiceSetting()
            await this.loadKakaoGroups()
            await this.loadKakaoProfiles()
            await this.loadKakaoCategories()
            this.isLoading = false
        },

        // MARK: - Network
        async loadServiceSetting() {
            let model = {
                hospitalID: this.hospitalID
            }
            let result = await this.API_V3.ServiceSetting.findOne(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.serviceSetting = result.data.data
        },
        async loadKakaoGroups() {
            let result = await this.API_V3.KakaoTemplate.findGroup()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.kakaoGroups = result.data.data
        },
        async loadKakaoProfiles() {
            let result = await this.API_V3.KakaoTemplate.findAllProfile()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let profiles = result.data.data
            if (this.senderKey != undefined) {
                profiles = profiles.filter( (p) => p.senderKey == this.senderKey )
            }
            else {
                profiles = []
            }
            this.kakaoProfiles = profiles
        },
        async loadKakaoCategories() {
            let result = await this.API_V3.KakaoTemplate.findCategory()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.kakaoCategories = result.data.data
        },
        async loadTemplates() {
            let model = {
                hospitalID: this.hospitalID,
                type: 'CONSULTATION'
            }
            this.isLoading = true
            let result = await this.API_V3.AutomationHospital.find(model)
            this.isLoading = false
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let templates = result.data.data
            templates.sort( (l, r) => {
                if (l.isActive == true && r.isActive == true) {
                    if (l.updatedAt < r.updatedAt) { return 1 }
                    else { return -1 }
                }
                if (l.isActive == true) { return -1 }
                else { return 1 }
            })

            this.templates = templates
        },
        async updateTemplate(template) {
            let model = {
                _id: template._id,
                isActive: !template.isActive
            }
            this.isLoading = true
            let result = await this.API_V3.AutomationHospital.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadTemplates()
        },
        async removeTemplate(template) {
            let model = {
                _id: template._id
            }
            this.isLoading = true
            let result = await this.API_V3.AutomationHospital.remove(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadTemplates()
        },

        // MARK: - Actions
        action_ShowTemplatePopup() {
            this.isShowTemplatePopup = true
        },

        // MARK: - Emit
        closePopup() {
            this.confirmPopupOptions = {
                title: undefined,
                body: undefined,
                isShow: false,
                alertCallback: undefined
            }
            this.reloadData()
        },
        closeDetailPopup() {
            this.isShowDetailPopup = false
            this.detailTemplate = undefined
        },
        emit_ActiveTemplate(template) {
            console.log('here')
            let body = '템플릿을 활성화 하시겠습니까?'
            if (template.isActive == true) { body = '템플릿을 비활성화 하시겠습니까?'}
            this.confirmPopupOptions = {
                title: '주의',
                body: body,
                isShow: true,
                alertCallback: async (isConfirm) => {
                    if (isConfirm == true) {
                        await this.updateTemplate(template)
                    }
                    this.closePopup()
                }
            }
        },
        emit_RemoveTemplate(template) {
            console.log('here')
            this.confirmPopupOptions = {
                title: '주의',
                body: '템플릿을 삭제하시겠습니까?',
                isShow: true,
                alertCallback: (isConfirm) => {
                    if (isConfirm == true) {
                        this.removeTemplate(template)
                    }
                    this.closePopup()
                }
            }
        },
        emit_ShowDetail(template) {
            this.detailTemplate = template
            this.isShowDetailPopup = true
        }
    }
}
</script>