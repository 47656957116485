<template>
    <tr>
        <td>
            <div class="flex_column">
                <p style=" max-width: 250px;">{{ shortName }}</p>
                <small class="lgray_txt">{{ hospitalID }}</small>
            </div>
        </td>
        <td class="txt_center">
            {{ createdAt }}
        </td>
        <td>
            <span class="lgray_txt small_content">{{ smsPhone }}</span>
        </td>
        <td>
            <button class="table_btn btn_blue_line" @click="action_EditHospital">수정</button>
        </td>
    </tr>
</template>

<script>
import moment from '@/util/MPMoment'
export default {
    props: {
        hospital: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    watch: {},
    computed: {
        shortName() {
            return this.hospital?.shortName ?? '-'
        },
        hospitalID() {
            return this.hospital?._id ?? '-'
        },
        createdAt() {
            return moment(this.hospital?.createdAt).format('YYYY-MM-DD') ?? '-'
        },
        smsPhone() {
            let smsPhone = this.hospital?.smsPhone ?? '-'
            return smsPhone == '' ? '-' : smsPhone
        },
        
    },
    methods: {
        action_EditHospital() {
            this.$emit('editHospital', this.hospitalID)
        }
    },
}
</script>