<template>
    <ul v-if="isShow" class="settings_wrap flex_column gap_20">
        <!-- 변경내역 버튼 클릭 시 나타나는 팝업 -->
        <AccountSettingHistoryPopup
            :isShow="isShowCountChangeHistoryPopup"
            :hospitalID="hospitalID"
            @close="closeChangeCountHistoryPopup"
        />

        <!-- 최대 계정 수 변경하기 버튼 클릭 시 나타나는 팝업 -->
        <AccountSettingChangeAccountcountPopup
            :isShow="isShowChangeAccountCountPopup"
            :hospitalID="hospitalID"
            @close="action_CloseChangeAccountCountPopup"
            @updateAccountCount="updateAccountCount"
        />

        <!-- 계정 수정 버튼 클릭 시 나타나는 팝업 -->
        <AccountSettingModifyPopup
            :isShow="isShowModifyPopup"
            :hospitalID="hospitalID"
            :doctor="modifyDoctor"
            :authorizationTypes="authorizationTypes"
            @close="closeModifyPopup"
            @loadDatas="loadDatas"
        />

        <!-- 계정 수 제한 -->
        <div>
            <div class="flex_aic gap_10 large_txt mgb_5" style="font-size: 18px;">
                <span>계정 수 제한</span>
                <button class="change_history" @click="action_ShowChangeCountHistoryPopup">변경내역</button>
            </div>
            
            <li class="settings_list flex_spb">
                <div class="settings_list_title">
                    최대 계정 수<small class="red_txt">*</small>
                </div>
                <div class="settings_list_option flex_column">
                    <div class="flex_aic gap_5">
                        <input type="text" class="w_full" name="" id="" placeholder="1" disabled :value="count">
                        <button class="btn_s btn_blue_line" @click="action_ShowChangeAccountCountPopup" :disabled="!auth.v2HospitalManagement_w">변경하기</button>
                    </div>
                </div>
            </li>
        </div>
        <!-- 계정 -->
        <div class="pdt_15" style="border-top:1px solid #b2b2b2">
            <div class="large_txt mgb_15" style="font-size: 18px;">계정</div>
            <AccountSettingNewAccount
                :hospitalID="hospitalID"
                :authorizationTypes="authorizationTypes"
                @loadDatas="loadDatas"
            />
            <AccountSettingAccountList
                :hospitalID="hospitalID"
                :serviceSetting="serviceSetting"
                :doctors="doctors"
                :authorizationTypes="authorizationTypes"
                @loadDatas="loadDatas"
                @showModifyPopup="showModifyPopup"
            />
        </div>
    </ul>
</template>


<script>
import AccountSettingChangeAccountcountPopup from './AccountSettingChangeAccountcountPopup.vue'
import AccountSettingNewAccount from './AccountSettingNewAccount.vue'
import AccountSettingAccountList from './AccountSettingAccountList.vue'
import AccountSettingModifyPopup from './AccountSettingModifyPopup.vue'
import AccountSettingHistoryPopup from './AccountSettingHistoryPopup.vue'

export default {
    components: {
        AccountSettingChangeAccountcountPopup,
        AccountSettingNewAccount,
        AccountSettingAccountList,
        AccountSettingModifyPopup,
        AccountSettingHistoryPopup
    },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            serviceSetting: undefined,

            isShowCountChangeHistoryPopup: false,
            isShowChangeAccountCountPopup: false,
            isShowModifyPopup: false,

            count: 0,
            doctors: [],
            authorizationTypes: [],

            modifyDoctor: undefined
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                this.loadDatas()
            }
        }
    },
    computed: {
        hospitalID: function() {
            return this.hospital?._id ?? undefined
        }
    },
    methods: {
        // MARK: - Network
        async loadDatas() {
            this.loadServiceSetting()
            this.loadHospitalAccount()
            this.loadAuthorizationTypes()
        },
        async loadServiceSetting() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.ServiceSetting.findOne({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.serviceSetting = result.data.data
            this.count = this.serviceSetting.maximumCreatableAccountCount
        },
        async loadHospitalAccount() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.Hospital.findAccount({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.doctors = result.data.data ?? []
        },
        async loadAuthorizationTypes() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API.HospitalAuthorizationType.find({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.authorizationTypes = result.data.data
        },

        // MARK: - Actions
        action_ShowChangeCountHistoryPopup() {
            this.isShowCountChangeHistoryPopup = true
        },
        action_ShowChangeAccountCountPopup() {
            this.isShowChangeAccountCountPopup = true
        },
        action_CloseChangeAccountCountPopup() {
            this.isShowChangeAccountCountPopup = false
        },


        // MARK: - Emit
        updateAccountCount(count) {
            this.count = count
        },
        closeChangeCountHistoryPopup() {
            this.isShowCountChangeHistoryPopup = false
        },
        showModifyPopup(doctor) {
            this.modifyDoctor = doctor
            this.isShowModifyPopup = true
        },
        closeModifyPopup() {
            this.modifyDoctor = undefined
            this.isShowModifyPopup = false
        },
    }
}
</script>