<template>
    <div class="msg_item" @click="action_SelectMessage">
        <span class="selected" v-if="isSelected"> </span>
        <b class="date">{{ daysAfter }}일차</b>
        <span class="ellipsis">{{ title }}</span>
        <img src="@/assets/img/badge_k.png" alt="" v-if="isKakaoTemplate">
        <button class="btn_delete mgl_auto" @click.prevent="action_RemoveMessage">
            <v-icon>mdi-trash-can</v-icon>
            삭제
        </button>
    </div>
</template>


<script>
export default {
    props: {
        message: Object,
        selectedMessage: Object,
    },
    watch: {},
    computed: {
        title: function() { return this.message.name ?? '-' },
        daysAfter: function() { return this.message.daysAfter ?? '-' },
        isKakaoTemplate: function() { return this.message?.kakaoTemplateHistoryId != undefined },
        isSelected: function() { return this.message == this.selectedMessage }
    },
    methods: {
        action_SelectMessage() {
            this.$emit('setSelectMessage', this.message)
        },
        action_RemoveMessage() {
            this.$emit('removeMessage', this.message)
        }
    },
}
</script>