import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const findOne = (m) => {
    return axios({
        method: 'get',
        url: URL.ADMINAUTHORIZATION_FIND_ONE,
        params: m,
        headers: Util.getHeaders()
    })
}

export const create = (m) => {
    return axios({
        method: 'post',
        url: URL.ADMINAUTHORIZATION,
        data: m,
        headers: Util.getHeaders()
    })
}

export const update = (m) => {
    return axios({
        method: 'patch',
        url: URL.ADMINAUTHORIZATION,
        data: m,
        headers: Util.getHeaders()
    })
}