<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="kakao_consult_talk_pop_wrap">
            <div class="pop_title">
                <span class="title">상담톡 연동 신청목록</span>
                <a class="mgl_auto">
                    <v-icon :size="24" @click="action_Close">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="h_scroll" >
                    <div class="table_title_btn mgb_10">
                            <input type="text" class="input_s w_full" placeholder="병원명 검색" @keydown.enter="action_Enter" v-model="keyword"/>
                            <button class="btn_s btn_blue_line mgl_5" @click="action_Enter">검색</button>
                    </div>
                    <div class="table_header_fix">
                        <!-- 검색된 데이터 없을 떄 -->
                        <table class="table_default consult_talk" v-if="isEmpty">
                            <thead>
                                <tr>
                                    <th width="250" class="txt_left">병원명</th>
                                    <th width="190" class="txt_left">상담톡 검색용 아이디</th>
                                    <th width="" class="txt_left">상담 가능 일시</th>
                                    <th width="60">상태</th>
                                    <th width="80">선택</th>
                                </tr>
                            </thead>
                            <tr>
                                <td colspan="5">검색된 내용이 없습니다.</td>
                            </tr>
                        </table>
                        <!-- hospital data table -->
                        <table class="table_default consult_talk" v-if="!isEmpty">
                            <thead>
                                <tr>
                                    <th width="250" class="txt_left">병원명</th>
                                    <th width="190" class="txt_left">상담톡 검색용 아이디</th>
                                    <th width="" class="txt_left">상담 가능 일시</th>
                                    <th width="65">상태</th>
                                    <th width="80">선택</th>
                                </tr>
                            </thead>
                            <Cell
                                v-for="(history, index) in linkages" :key="index"
                                :index="index"
                                :history="history"
                                @selectConsultHistory="selectConsultHistory"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import PageController from '@/controller/PageController'
import Cell from './KakaoIntegrationConsultLoadCell.vue'
export default {
    components: { Cell },
    props: {
        hospital: {
            type: Object,
            default: () => {return {}}
        },
        isShow: Boolean
    },
    data() {
        return {
            isLoading: false,
            linkages: [],
            
            keyword: '',

            pageController: new PageController(),

            totalCount: 0,
            countPerPage: 10,
            currentPage: 0,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == true) { this.reload() }
            }
        }
    },
    computed: {
        isEmpty: function() {
            return this.linkages.length == 0
        },
        filter: function() {
            return {
                isRequestForAlimAndFriendTalkLinkage: false,
                isRequestForConsultationTalkLinkage: true,
                keyword: this.keyword,
                key: 'createdAt',
                way: -1
            }
        }
    },
    methods: {
        // MARK: - Network
        async reload() {
            this.loadPageIndex(0)
        },
        async loadCount() {
            let result = await this.API_V3.KakaoLinkageHistory.count(this.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadPageIndex(index) {
            this.isLoading = true
            await this.loadCount()
            this.pageController.api = this.API_V3.KakaoLinkageHistory.find
            this.pageController.filter = this.filter
            this.pageController.countPerPage = this.countPerPage
            this.currentPage = index
            await this.pageController.loadPageIndex(index)
            this.isLoading = false
            this.linkages = this.pageController.data
        },

        changePage(index) {
            this.loadPageIndex(index)
        },
        selectConsultHistory(history) {
            this.$emit('selectConsultHistory', history)
            this.$emit('close')
        },

        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        action_Enter() {
            this.changePage(0)
        }
    }
}
</script>