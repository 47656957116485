<template>
    <div>
        <div class="large_txt mgb_5" style="font-size: 18px;">상담톡</div>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                검색용 아이디<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="검색용 아이디(@UUID)입력" disabled v-model=consult_pf_id>
                    <button class="btn_s btn_blue_line" @click="action_OpenConsultLoadPopup">불러오기</button>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                상담 가능 일시
            </div>
            <div class="settings_list_option flex_column">
                <div class="settings_list_option flex_column gap_10">
                    <div class="flex_aic gap_15">
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="월" :disabled="!hasKakaoConsultationTalkLinkage" v-model="mondayAvailable">
                            <span>월</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="화" :disabled="!hasKakaoConsultationTalkLinkage" v-model="tuesdayAvailable">
                            <span>화</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="수" :disabled="!hasKakaoConsultationTalkLinkage" v-model="wednesdayAvailable">
                            <span>수</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="목" :disabled="!hasKakaoConsultationTalkLinkage" v-model="thursdayAvailable">
                            <span>목</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="금" :disabled="!hasKakaoConsultationTalkLinkage" v-model="fridayAvailable">
                            <span>금</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="토" :disabled="!hasKakaoConsultationTalkLinkage" v-model="saturdayAvailable">
                            <span>토</span>
                        </label>
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox" name="요일" id="일" :disabled="!hasKakaoConsultationTalkLinkage" v-model="sundayAvailable">
                            <span>일</span>
                        </label>
                    </div>
                    <div class="flex_aic gap_10">
                        <div class="flex_aic">
                            <select name="상담 가능 시작 시" id="" :disabled="!hasKakaoConsultationTalkLinkage" style="width: 70px;" v-model="startHour">
                                <option 
                                    v-for="(hour, index) in Array.from({length: 24}, (_, i) => {return i})"
                                    :key="index"
                                    :value="hour"
                                >
                                    {{String(hour).padStart(2, '0')}}시
                                </option>
                            </select>
                            <select name="상담 가능 시작 분" id="" :disabled="!hasKakaoConsultationTalkLinkage" style="width: 70px;" v-model="startMin">
                                <option 
                                    v-for="(min, index) in Array.from({length: 60}, (_, i) => {return i})"
                                    :key="index"
                                    :value="min"
                                >
                                    {{String(min).padStart(2, '0')}}분
                                </option>
                            </select>
                        </div>
                        ~
                        <div class="flex_aic">
                            <select name="종료 시" id="" :disabled="!hasKakaoConsultationTalkLinkage" style="width: 70px;" v-model="endHour">
                                <option 
                                    v-for="(hour, index) in Array.from({length: 24}, (_, i) => {return i})"
                                    :key="index"
                                    :value="hour"
                                >
                                    {{String(hour).padStart(2, '0')}}시
                                </option>
                            </select>
                            <select name="종료 분" id="" :disabled="!hasKakaoConsultationTalkLinkage" style="width: 70px;" v-model="endMin">
                                <option 
                                    v-for="(min, index) in Array.from({length: 60}, (_, i) => {return i})"
                                    :key="index"
                                    :value="min"
                                >
                                    {{String(min).padStart(2, '0')}}분
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                AccessKey
            </div>
            <div class="settings_list_option">
                <input type="text" class="w_full" name="" id="" placeholder="Access Key 입력" v-model="accessKey">
            </div>
        </li>
        <li class="settings_list flex_aic_spb gap_10">
            <button class="btn_l btn_gray" :disabled="!auth.v2HospitalIntegration_w" @click="action_Clear">연동해제</button>
            <button class="btn_l btn_blue" :disabled="!auth.v2HospitalIntegration_w" @click="action_Save">저장</button>
        </li>
    </div>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        history: {
            type: Object,
            default: () => {return undefined}
        },
    },
    data() {
        return {
            consultationStartTime: undefined,
            consultationEndTime: undefined,
            sundayAvailable: false,
            mondayAvailable: false,
            tuesdayAvailable: false,
            wednesdayAvailable: false,
            thursdayAvailable: false,
            fridayAvailable: false,
            saturdayAvailable: false,
            accessKey: undefined,

            startHour: 0,
            startMin: 0,
            endHour: 0,
            endMin: 0
        }
    },
    watch: {
        history: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.consultationStartTime = newValue?.consultationInformation?.consultationStartTime
                this.consultationEndTime = newValue?.consultationInformation?.consultationEndTime
                this.sundayAvailable = newValue?.consultationInformation?.sundayAvailable
                this.mondayAvailable = newValue?.consultationInformation?.mondayAvailable
                this.tuesdayAvailable = newValue?.consultationInformation?.tuesdayAvailable
                this.wednesdayAvailable = newValue?.consultationInformation?.wednesdayAvailable
                this.thursdayAvailable = newValue?.consultationInformation?.thursdayAvailable
                this.fridayAvailable = newValue?.consultationInformation?.fridayAvailable
                this.saturdayAvailable = newValue?.consultationInformation?.saturdayAvailable
                this.accessKey = newValue?.accessKey

                if (this.consultationStartTime != undefined) {
                    let s = moment(this.consultationStartTime)
                    this.startHour = s.format('H')
                    this.startMin = s.format('m')
                }
                if (this.consultationEndTime != undefined) {
                    let e = moment(this.consultationEndTime)
                    this.endHour = e.format('H')
                    this.endMin = e.format('m')
                }
            }
        },
        startHour: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleStartedAt = moment(0).startOf('days').add(newValue, 'hour').add(this.startMin, 'minute').format()
            }
        },
        startMin: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleStartedAt = moment(0).startOf('days').add(this.startHour, 'hour').add(newValue, 'minute').format()
            }
        },
        endHour: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleEndedAt = moment(0).startOf('days').add(newValue, 'hour').add(this.endMin, 'minute').format()
            }
        },
        endMin: {
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.possibleEndedAt = moment(0).startOf('days').add(this.endHour, 'hour').add(newValue, 'minute').format()
            }
        },
        consultationStartTime: { handler: function() { this.update() } },
        consultationEndTime: { handler: function() { this.update() } },
        sundayAvailable: { handler: function() { this.update() } },
        mondayAvailable: { handler: function() { this.update() } },
        tuesdayAvailable: { handler: function() { this.update() } },
        wednesdayAvailable: { handler: function() { this.update() } },
        thursdayAvailable: { handler: function() { this.update() } },
        fridayAvailable: { handler: function() { this.update() } },
        saturdayAvailable: { handler: function() { this.update() } },
        accessKey: { handler: function() { this.update() } }
    },
    computed: {
        hasKakaoConsultationTalkLinkage: function() {
            return this.consult_pf_id != undefined
        },
        consult_pf_id: function() {
            return this.history?.searchableChannelId
        }
    },
    methods: {
        action_OpenConsultLoadPopup() {
            this.$emit('openConsultLoadPopup')
        },
        action_Save() {
            this.$emit('saveConsultHistory')
        },
        action_Clear() {
            this.$emit('clearConsultHistory')
        },

        update() {
            if (this.history == undefined) { return }
            this.$emit('updateConsultHistory', {
                ...this.history,
                consultationStartTime: this.consultationStartTime,
                consultationEndTime: this.consultationEndTime,
                sundayAvailable: this.sundayAvailable,
                mondayAvailable: this.mondayAvailable,
                tuesdayAvailable: this.tuesdayAvailable,
                wednesdayAvailable: this.wednesdayAvailable,
                thursdayAvailable: this.thursdayAvailable,
                fridayAvailable: this.fridayAvailable,
                saturdayAvailable: this.saturdayAvailable,
                accessKey: this.accessKey
            })
        }
    }
}
</script>