<template>
    <div class="pop_overlay_bg" v-if="hospitaltreatmentcategorypopup.isShow">
        <LoadingIndicate :isLoading="isLoading" />
        <ToastComponent :isShow="showErrorMessage" :noticeMessage="errorMessage" />
        <div class="treatment_pop_wrap" v-click-outside="action_Close">
            <PopupTitleSection title="치료유형" @action_Close="action_Close" />
            <div class="h_scroll">
                <div class="treatment_wrap">
                    <TreatmentCategoryEditCell
                        v-for="(t, index) in treatmentCategories"
                        :key="index"
                        :ref="'itemcell'"
                        :setting="serviceSetting"
                        :category="t"
                        :index="index"
                        v-on:updateItem="updateItem"
                        v-on:removeCategory="removeCategory"
                        @removeError="removeError"
                    />
                    <span class="treatment_add_category" @click="action_AddCategory">
                        <p>추가하기</p>
                        <a><v-icon>mdi-plus-circle</v-icon></a>
                    </span>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_blue" @click="action_Save" :disabled="auth.hospitalManagement_w==false">저장</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TreatmentCategoryEditCell from './TreatmentCategoryEditCell.vue'
import ToastComponent from '@/views/main/ShareComponents/ToastComponent.vue'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    components: {
        ToastComponent,
        PopupTitleSection,
        TreatmentCategoryEditCell,
        LoadingIndicate
    },
    data() {
        return {
            removeCategories: [],
            errorMessage: '',
            showErrorMessage: false,
            serviceSetting: undefined,
            treatmentCategories: undefined,
            isLoading: false
        }
    },
    watch: {
        'hospitaltreatmentcategorypopup.isShow': {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    this.loadServiceSetting()
                }
            }
        }
    },
    computed: {
        ...mapState(['hospitaltreatmentcategorypopup']),
        hospital: function () {
            return this.hospitaltreatmentcategorypopup.selectedHospital
        },
        hospitalID: function () {
            if (this.hospital == undefined) {
                return undefined
            }
            return this.hospital._id
        },
        defaultModel: function () {
            let newModel = {
                categoryName: '',
                hospitalID: this.serviceSetting.hospitalID,
                paletteIndex: 0,
                sortNum: 0,
                treatmentCautionID: undefined
            }
            return newModel
        },
        canUpdate: function () {
            return true
        }
    },
    methods: {
        ...mapActions(['setHospitalTreatmentCategoryPopupState']),

        async loadServiceSetting() {
            if (this.hospitalID == undefined) {
                return
            }
            this.isLoading = true

            let result = await this.API.ServiceSetting.getServiceSettingDetail({
                hospitalID: this.hospitalID
            })
            if (result.data.code != 0) {
                return
            }
            this.isLoading = false
            this.serviceSetting = result.data.data
            this.treatmentCategories = this.serviceSetting.treatmentCategories
        },
        async API_CreateCategory(c) {
            let result = await this.API.TreatmentCategory.create(c)
            if (result.data.code != 0) {
                throw result
            }
            return result.data.data
        },
        async API_UpdateCategory(c) {
            let result = await this.API.TreatmentCategory.update(c)
            if (result.data.code != 0) {
                throw result
            }
            return result.data.data
        },
        async API_RemoveCategory(c) {
            c.tcID = c._id
            if (c.tcID == undefined) {
                return
            }
            let result = await this.API.TreatmentCategory.remove(c)
            if (result.data.code != 0) {
                throw result
            }
            return result.data.data
        },
        async API_ServiceSettingUpdate(s) {
            let result = await this.API.ServiceSetting.updateServiceSetting(s)
            if (result.data.code != 0) {
                throw result
            }
        },

        action_AddCategory() {
            this.treatmentCategories.push(this.defaultModel)
        },

        async action_Close() {
            this.treatmentCategories = []
            await this.setHospitalTreatmentCategoryPopupState({
                isShow: false,
                selectedHospital: undefined
            })
        },

        async action_Save() {
            let { createArray, updateArray } = this.parseArray()
            if (this.isPossibleToUpdate() == false) {
                return
            }
            try {
                if (createArray.length > 0) {
                    for (let i = 0; i < createArray.length; i++) {
                        await this.API_CreateCategory(createArray[i])
                    }
                }
                if (updateArray.length > 0) {
                    for (let i = 0; i < updateArray.length; i++) {
                        await this.API_UpdateCategory(updateArray[i])
                    }
                }
                if (this.removeCategories.length > 0) {
                    for (let i = 0; i < this.removeCategories.length; i++) {
                        await this.API_RemoveCategory(this.removeCategories[i])
                    }
                }
                this.treatmentCategories.sort((l, r) => {
                    return l.orderNum < r.orderNum
                })
                this.API_ServiceSettingUpdate({
                    _id: this.serviceSetting._id,
                    hospitalID: this.hospitalID,
                    treatmentCategories: this.treatmentCategories
                })
                this.show_ToastMessage()

                setTimeout(() => {
                    this.action_Close()
                }, 1000)
            } catch (err) {
                alert(err)
            }
        },

        modifiedArray() {
            let array = []
            let el = this.$refs['itemcell']
            for (let i = 0; i < el.length; i++) {
                el[i].modifyCategory.sortNum = i
                el[i].modifyCategory.tcID = el[i].modifyCategory._id
                array.push(el[i].modifyCategory)
            }
            return array
        },

        parseArray() {
            this.treatmentCategories = this.modifiedArray()
            let createArray = []
            let updateArray = []
            for (let i = 0; i < this.treatmentCategories.length; i++) {
                let t = this.treatmentCategories[i]
                if (t._id == undefined) {
                    createArray.push(t)
                    continue
                } else if (t.id != undefined) {
                    updateArray.push(t)
                    continue
                }
            }
            return { createArray, updateArray }
        },

        updateItem(category, index) {
            this.treatmentCategories.splice(index, 1, category)
            this.setErrorMessage('')
            this.$forceUpdate()
        },
        removeCategory(_, index) {
            let removedCategory = this.treatmentCategories.splice(index, 1)
            this.removeCategories.push(removedCategory[0])
            this.$forceUpdate()
        },

        show_ToastMessage() {
            this.errorMessage = '저장되었습니다.'
            this.showErrorMessage = true

            setTimeout(() => {
                this.showErrorMessage = false
            }, 1000)
        },
        isPossibleToUpdate: function () {
            for (let i = 0; i < this.treatmentCategories.length; i++) {
                let origin = this.treatmentCategories[i]
                if (origin.categoryName == undefined || origin.categoryName == '') {
                    this.setErrorMessage('이름이 입력되지 않았습니다.')
                    return false
                }
                for (let j = 0; j < this.treatmentCategories.length; j++) {
                    if (i == j) {
                        continue
                    }
                    let c = this.treatmentCategories[j]
                    if (origin.categoryName == c.categoryName) {
                        this.setErrorMessage('이미 등록된 치료 유형입니다.')
                        return false
                    }
                }
            }
            return true
        },
        setErrorMessage(notice_message) {
            this.showErrorMessage = true
            this.errorMessage = notice_message
        },
        removeError() {
            this.setErrorMessage('')
        }
    }
}
</script>
