<template>
    <ul v-if="isShow" class="settings_wrap flex_column">
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                홈케어 콘텐츠 설정
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="홈케어 콘텐츠 설정" :value="false" v-model="autoSendVideo">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="홈케어 콘텐츠 설정" :value="true" v-model="autoSendVideo">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                사용할 콘텐츠
            </div>
            <div class="settings_list_option flex_column">
                <HomecareContentsItem
                    :isEnable="autoSendVideo"
                    v-for="(category, index) in usingAutoSendVideo"
                    :key="index"
                    :index="index"
                    :category="category"
                    @updateCategory="updateCategory"
                />
            </div>
        </li>
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" @click="action_Save"  :disabled="!auth.v2HospitalManagement_w">저장</button>
        </li>
    </ul>
</template>


<script>
import HomecareContentsItem from './HomecareContentsItem.vue'

export default {
    components: { HomecareContentsItem },
    props: {
        isShow: Boolean,
        hospital: Object
    },
    data() {
        return {
            setting: undefined,

            autoSendVideo: false,
            usingAutoSendVideo: []
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function() {
                this.loadServiceSetting()
            }
        }
    },
    computed: {
        hospitalID: function() {
            return this.hospital?._id
        }
    },
    methods: {
        // MARK: - Network
        async loadServiceSetting() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.ServiceSetting.findOne({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.setting = result.data.data

            this.autoSendVideo = this.setting.autoSendVideo

            let topVCCategories = this.setting.topVCCategories.filter( (c) => c.isSelected == true)

            let temp = []
            for (let i=0 ; i<topVCCategories.length ; i++) {
                let c = topVCCategories[i]
                let categoryID = c.categoryID
                let result = await this.API_V3.VideoContentCategory.findOne({_id: categoryID})
                if (result.data.code != 0) {
                    alert(result.data.message)
                    return
                }
                let category = result.data.data
                category.isSelected = false
                category.sortNum = c.sortNum
                if (this.setting.usingAutoSendVideo.find(c => c._id == category._id)) {
                    category.isSelected = true
                }
                console.log('here')

                let subCategoriesResult = await this.API_V3.VideoContentCategory.find({
                    parentCategoryIDs: {$in: [categoryID]},
                    isTop: false,
                    isParent: true,
                })
                if (subCategoriesResult.data.code != 0) {
                    alert(subCategoriesResult.data.message)
                    return
                }
                let subCategoryNames = subCategoriesResult.data.data.map(c => c.categoryName)
                category.subCategoryNames = subCategoryNames.join(', ')

                temp.push(category)
            }
            temp.sort( (a, b) => { return a.sortNum - b.sortNum })
            this.usingAutoSendVideo = temp
        },

        // MARK: - Actions
        async action_Save() {
            let usingAutoSendVideo = this.usingAutoSendVideo.filter((category) => category.isSelected == true).map(c => {return c._id})
            if (this.autoSendVideo == false) { usingAutoSendVideo = [] }
            let updateModel = {
                _id: this.setting._id,
                autoSendVideo: this.autoSendVideo,
                usingAutoSendVideo: JSON.stringify(usingAutoSendVideo)
            }
            
            // console.log(updateModel)
            let result = await this.API_V3.ServiceSetting.update(updateModel)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadServiceSetting()
        },

        // MARK: - Emit
        updateCategory(category){
            let index = category.index
            delete category.index
            this.usingAutoSendVideo.splice(index, 1, category)
        }
    }
}
</script>