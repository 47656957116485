<template>
    <li class="tag_template_list flex_aic_spb">
        <b class="tag_name ellipsis">{{ categoryName }}</b>
        <div class="flex_aic gap_5">
            <small class="lgray_txt">{{ status }}</small>
            <button @click="action_Remove">
                <v-icon class="lgray_txt">mdi-close</v-icon>
            </button>
        </div>
    </li>
</template>


<script>
export default {
    props: {
        item: Object,
        index: Number,
    },
    computed: {
        categoryName: function() {
            return this.item?.categoryName ?? '-'
        },
        status: function() {
            return this.item?.isSelected == true ? '' : '설정됨'
        },
    },
    methods: {
        action_Remove() {
            this.$emit('removeTag', this.index)
        }
    }
}
</script>