<template>
    <div class="snb">
        <div class="snb_title">일렉트론 설정</div>
        <a @click="action_NotificationSound" class="snb_menu" :class="{ on: $router.currentRoute.name == 'NotificationSoundSetting' }">알림 소리 설정</a>
        <a @click="action_NationalSMSSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'NationalSMSSetting' }">국가별 문자 메시지 설정</a>
        <a @click="action_ServiceAlrimTalkSetting" class="snb_menu" :class="{ on: $router.currentRoute.name == 'ServiceAlrimTalkSetting' }">서비스 알림톡 설정</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_NotificationSound() {
            this.$router.push({ name: 'NotificationSoundSetting' }).catch(() => {})
        },
        action_NationalSMSSetting() {
            this.$router.push({ name: 'NationalSMSSetting' }).catch(() => {})
        },
        action_ServiceAlrimTalkSetting() {
            this.$router.push({ name: 'ServiceAlrimTalkSetting' }).catch(() => {})
        }
    }
}
</script>
